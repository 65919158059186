// 层级构建 id-path
export function idPathBuilder(context, ...nowPath) {
  return [context.idPath, ...nowPath].join('.');
}
// 从 id-path 中解析 option 数据中的 路径
export function idPathGetPath(idPath) {
  const index = String(idPath).indexOf('-.');
  return index === -1 ? idPath : idPath.substr(index + 2);
}
// 从 id-path 中解析 页码
export function idPathGetPageId(idPath) {
  const index = String(idPath).indexOf('-.');
  return index === -1 ? 0 : idPath.substr(0, index);
}
// 依据 id-path 构建页面内唯一的 CSS ID
export function idPathToCssId(context) {
  return `${!!context.isOnEditor}_${context.idPath.replace(/\./g, '-_-')}`;
}
// 上面方法的反推
export function idPathFromCssId(cssId) {
  const idPath = cssId.replace(/-_-/g, '.');
  return idPath.replace(/(true|false)_/, '');
}

