export const createListItem = (children) => ({
    type: 'list-item',
    children: Array.isArray(children) ? children : [{ text: children }],
})

export const createParagraph = (text, bold = false) => ({
    type: 'paragraph',
    children: [{ text, bold }],
})

export const createTitleDescChildren = (title, desc) => {
    const children = []
    if (title) children.push({ text: `${title}：`, bold: true })
    if (desc) children.push({ text: desc })
    return children
}
