export default {
  USER_1: 'Upgrade',
  USER_2: 'Download History',
  USER_3: 'AI History',
  USER_4: 'My Favorites',
  USER_5: 'Log Out',
  USER_6: '',//英文语序中不需要这个字段，放在次数后面了
  USER_7: 'Dls Left/Mo',
  USER_8: 'Pgs Left/Mo',
  USER_9: 'MobilePhoneNumber Binding',
};
