export default {
  PPTXINDEX_1: 'Client Download',
  PPTXINDEX_2: 'Plugin Download',
  PPTXINDEX_3: 'Import Content',
  PPTXINDEX_4: 'Register / Login',
  PPTXINDEX_5: 'Please Enter Search Content',
  PPTXINDEX_6: '10M+',
  PPTXINDEX_7: '10M+ Premium Assets',
  PPTXINDEX_8: 'Daily Updates',
  PPTXINDEX_9: '30M+ Users',
  PPTXINDEX_10: 'Join{res} for Exclusive Benefits',
  PPTXINDEX_11: 'WeChat Scan to LogIn/Register',
  PPTXINDEX_12: 'Scan for Quick Download',
  PPTXINDEX_13: 'Scan to Follow “{res}” for Login/Signup',
  PPTXINDEX_14: 'First Scan Auto-Registers',
  PPTXINDEX_15: 'Signup/Login implies agreement to the User Agreement',
  PPTXINDEX_16: 'Join Us',
  PPTXINDEX_17: 'Privacy Policy',
  PPTXINDEX_18: 'About Us',
  PPTXINDEX_19: 'Contact Us',
  PPTXINDEX_20: 'Terms of Use',
};
