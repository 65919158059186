export default {
  "TEMPLATE_CHANGE_1":"TEMPLATE_CHANGE_1",
  "TEMPLATE_CHANGE_2":"TEMPLATE_CHANGE_2",
  "TEMPLATE_CHANGE_3":"TEMPLATE_CHANGE_3",
  "TEMPLATE_CHANGE_4":"TEMPLATE_CHANGE_4",
  "TEMPLATE_CHANGE_5":"TEMPLATE_CHANGE_5",
  "TEMPLATE_CHANGE_6":"TEMPLATE_CHANGE_6",
  "TEMPLATE_CHANGE_7":"TEMPLATE_CHANGE_7",
  "TEMPLATE_CHANGE_8":"TEMPLATE_CHANGE_8",
  "TEMPLATE_CHANGE_9":"TEMPLATE_CHANGE_9",
  "TEMPLATE_CHANGE_10":"TEMPLATE_CHANGE_10",
  "TEMPLATE_CHANGE_11":"TEMPLATE_CHANGE_11",
  "TEMPLATE_CHANGE_12":"TEMPLATE_CHANGE_12",
  "TEMPLATE_CHANGE_13":"TEMPLATE_CHANGE_13",
  "TEMPLATE_CHANGE_14":"TEMPLATE_CHANGE_14",
  "TEMPLATE_CHANGE_15":"TEMPLATE_CHANGE_15",
  "TEMPLATE_CHANGE_16":"TEMPLATE_CHANGE_16",
  "TEMPLATE_CHANGE_17":"TEMPLATE_CHANGE_17",
  "TEMPLATE_CHANGE_18":"TEMPLATE_CHANGE_18",
  "TEMPLATE_CHANGE_19":"TEMPLATE_CHANGE_19",
  "TEMPLATE_CHANGE_20":"TEMPLATE_CHANGE_20",
  "TEMPLATE_CHANGE_21":"TEMPLATE_CHANGE_21",
  "TEMPLATE_CHANGE_22":"TEMPLATE_CHANGE_22",
  "TEMPLATE_CHANGE_23":"TEMPLATE_CHANGE_23",
  "TEMPLATE_CHANGE_24":"TEMPLATE_CHANGE_24",
  "TEMPLATE_CHANGE_25":"TEMPLATE_CHANGE_25",
  "TEMPLATE_CHANGE_26":"TEMPLATE_CHANGE_26",
  "TEMPLATE_CHANGE_27":"TEMPLATE_CHANGE_27",
  "TEMPLATE_CHANGE_28":"TEMPLATE_CHANGE_28",
  "TEMPLATE_CHANGE_29":"TEMPLATE_CHANGE_29",
  "TEMPLATE_CHANGE_30":"TEMPLATE_CHANGE_30",
  "TEMPLATE_CHANGE_31":"TEMPLATE_CHANGE_31",
  "TEMPLATE_CHANGE_32":"TEMPLATE_CHANGE_32",
  "TEMPLATE_CHANGE_33":"TEMPLATE_CHANGE_33",
  "TEMPLATE_CHANGE_34":"TEMPLATE_CHANGE_34",
  "TEMPLATE_CHANGE_35":"TEMPLATE_CHANGE_35",
  "TEMPLATE_CHANGE_36":"TEMPLATE_CHANGE_36",
  "TEMPLATE_CHANGE_37":"TEMPLATE_CHANGE_37",
  "TEMPLATE_CHANGE_38":"TEMPLATE_CHANGE_38",
  "TEMPLATE_CHANGE_39":"TEMPLATE_CHANGE_39",
  "TEMPLATE_CHANGE_40":"TEMPLATE_CHANGE_40",
  "TEMPLATE_CHANGE_41":"TEMPLATE_CHANGE_41",
  "TEMPLATE_CHANGE_42":"TEMPLATE_CHANGE_42",
  "TEMPLATE_CHANGE_43":"TEMPLATE_CHANGE_43",
  "TEMPLATE_CHANGE_44":"TEMPLATE_CHANGE_44",
  "TEMPLATE_CHANGE_45":"TEMPLATE_CHANGE_45",
  "TEMPLATE_CHANGE_46":"TEMPLATE_CHANGE_46",
  "TEMPLATE_CHANGE_47":"TEMPLATE_CHANGE_47",
  "TEMPLATE_CHANGE_48":"TEMPLATE_CHANGE_48",
  "TEMPLATE_CHANGE_49":"TEMPLATE_CHANGE_49",
  "TEMPLATE_CHANGE_50":"TEMPLATE_CHANGE_50",
  "TEMPLATE_CHANGE_51":"TEMPLATE_CHANGE_51",
  "TEMPLATE_CHANGE_52":"TEMPLATE_CHANGE_52",
  "TEMPLATE_CHANGE_53":"TEMPLATE_CHANGE_53",
  "TEMPLATE_CHANGE_54":"TEMPLATE_CHANGE_54",
  "TEMPLATE_CHANGE_55":"TEMPLATE_CHANGE_55",
  "TEMPLATE_CHANGE_56":"TEMPLATE_CHANGE_56",
  "TEMPLATE_CHANGE_57":"TEMPLATE_CHANGE_57",
  "TEMPLATE_CHANGE_58":"TEMPLATE_CHANGE_58",
  "TEMPLATE_CHANGE_59":"TEMPLATE_CHANGE_59",
  "TEMPLATE_CHANGE_60":"TEMPLATE_CHANGE_60",
  "TEMPLATE_CHANGE_61":"TEMPLATE_CHANGE_61",
  "TEMPLATE_CHANGE_62":"TEMPLATE_CHANGE_62",
}
