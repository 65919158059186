export default {
  EDIT_01: 'EDIT_01',
  EDIT_02: 'EDIT_02',
  EDIT_03: 'EDIT_03',
  EDIT_04: 'EDIT_04',
  EDIT_05: 'EDIT_05',
  EDIT_06: 'EDIT_06',
  EDIT_07: 'EDIT_07',
  EDIT_08: 'EDIT_08',
  EDIT_09: 'EDIT_09',
  EDIT_10: 'EDIT_10',
  EDIT_11: 'EDIT_11',
  EDIT_12: 'EDIT_12',
  EDIT_13: 'EDIT_13',
  EDIT_14: 'EDIT_14',

}
