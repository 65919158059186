export default {
  "PPTXINDEX_1":"PPTXINDEX_1",
  "PPTXINDEX_2":"PPTXINDEX_2",
  "PPTXINDEX_3":"PPTXINDEX_3",
  "PPTXINDEX_4":"PPTXINDEX_4",
  "PPTXINDEX_5":"PPTXINDEX_5",
  "PPTXINDEX_6":"PPTXINDEX_6",
  "PPTXINDEX_7":"PPTXINDEX_7",
  "PPTXINDEX_8":"PPTXINDEX_8",
  "PPTXINDEX_9":"PPTXINDEX_9",
  "PPTXINDEX_10":"PPTXINDEX_10",
  "PPTXINDEX_11":"PPTXINDEX_11",
  "PPTXINDEX_12":"PPTXINDEX_12",
  "PPTXINDEX_13":"PPTXINDEX_13",
  "PPTXINDEX_14":"PPTXINDEX_14",
  "PPTXINDEX_15":"PPTXINDEX_15",
  "PPTXINDEX_16":"PPTXINDEX_16",
  "PPTXINDEX_17":"PPTXINDEX_17",
  "PPTXINDEX_18":"PPTXINDEX_18",
  "PPTXINDEX_19":"PPTXINDEX_19",
  "PPTXINDEX_20":"PPTXINDEX_20",
}
