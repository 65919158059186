// 引入html2canvas
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import { FastAverageColor } from 'fast-average-color';

function getMostFrequentColor(canvas, x, y, width, height) {
    // 获取canvas的上下文
    const context = canvas.getContext('2d');

    // 提取指定区域的图像数据
    const imageData = context.getImageData(x, y, width, height);
    const data = imageData.data;

    // 用于存储颜色频率的对象
    let colorCounts = {};

    // 用于跟踪最常见颜色
    let mostFrequentColor = { color: null, count: 0 };

    for (let i = 0; i < data.length; i += 4) {
      // 提取rgba值
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      const a = data[i + 3];

      // 将rgba值转换为hex颜色
      const color = rgbaToHex(r, g, b, a);

      // 更新颜色频率
      if (colorCounts[color]) {
        colorCounts[color]++;
      } else {
        colorCounts[color] = 1;
      }

      // 更新最常见颜色
      if (colorCounts[color] > mostFrequentColor.count) {
        mostFrequentColor.color = color;
        mostFrequentColor.count = colorCounts[color];
      }
    }

    // 返回最常见的颜色
    return mostFrequentColor.color;
  }

function rgbaToHex(r, g, b, a) {
    // 转换为hex并添加前导零
    const hex = ((r << 24) | (g << 16) | (b << 8) | a).toString(16).padStart(8, '0');

    // 返回hex颜色值
    return '#' + hex.slice(0, 6);
}

function renderRect (canvas, x, y, width, height) {
  const context = canvas.getContext('2d');
  context.strokeStyle = 'red'; // 矩形颜色
  context.lineWidth = 2; // 线宽
  // 绘制矩形 (x, y, width, height)
  console.log('x, y', x, y, width, height)
  context.strokeRect(x, y, width + 1, height + 1);
  context.strokeRect(0, 0, width + 1, height + 1);
}

const isDrakColor = (rgb) => {
  const [r, g, b] = rgb
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance < 128;
}

const testImage = (canvas, cropX, cropY, cropWidth, cropHeight) => {
    let img = new Image();

    // 设置图像源为canvas转换后的DataURL
    img.src = canvas.toDataURL("image/png");

    // 设置图像的其他属性，如宽度和高度
    img.width = canvas.width;
    img.height = canvas.height;

    img.onload = () => {
        cropImage(img, cropX, cropY, cropWidth, cropHeight)
    }
}

function cropImage(imgElement, cropX, cropY, cropWidth, cropHeight) {
    // 创建一个临时Canvas
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');

    // 设置Canvas的大小为想要裁剪的大小
    canvas.width = cropWidth;
    canvas.height = cropHeight;

    // 在Canvas上绘制裁剪的图像部分
    ctx.drawImage(
      imgElement,
      cropX, cropY,             // 开始裁剪的x坐标和y坐标
      cropWidth, cropHeight,    // 被裁剪图像的宽度和高度
      0, 0,                     // 在Canvas上绘制图像的x坐标和y坐标
      cropWidth, cropHeight     // 在Canvas上绘制图像的宽度和高度
    );

    // 创建新的Image元素用于显示裁剪后的图像
    var croppedImage = new Image();
    croppedImage.src = canvas.toDataURL("image/png");

    // 插入裁剪后的图像到body中
    document.body.appendChild(croppedImage);
}


// 返回dark | light
export const getElementPrimaryColor = (element, rect) => {
  let now = Date.now()
  return new Promise((resolve, reject) => {
    html2canvas(element, {
        // allowTaint: true,
        useCORS: true,
        // scale: 1,
        width: 800,
        height: 450
    }).then(canvas => {
        const top = rect.y * window.devicePixelRatio
        const left = rect.x * window.devicePixelRatio
        const width = rect.width * window.devicePixelRatio
        const height = rect.height * window.devicePixelRatio

        const fac = new FastAverageColor();
        fac.getColorAsync(canvas, {top, left, width, height})
        .then(color => {
            console.log('color', color)
            resolve(color)
        })
        .catch(e => {
            console.log(e);
        });


        const newRect = {
            x: left,
            y: top,
            width,
            height,
        }

        // console.log('newRect', newRect)
        testImage(canvas, newRect.x, newRect.y, newRect.width, newRect.height)

        // renderRect(canvas, newRect.x, newRect.y, newRect.width, newRect.height)
        document.body.append(canvas)
        // const color = getMostFrequentColor(canvas, newRect.x, newRect.y, newRect.width, newRect.height)
        // console.log('color', color, Date.now() - now)

    }).catch(err => {
        reject(err)
        console.log('生成图片出错: ', err);
    });
  })
}

window.getElementPrimaryColor = getElementPrimaryColor




export function exportElementAsImage(element, outputWidth, outputHeight) {
    // 获取元素
    // if (!element) {
    //     console.log(`没有找到元素id: ${element}`);
    //     return;
    // }

    // 使用html2canvas生成canvas
    html2canvas(element, {
        allowTaint: true,
        useCORS: true,
        scale: 3
        // useCORS: true, // 如果涉及到跨域图片问题，可以开启这个选项
    }).then(canvas => {
        const color = getMostFrequentColor(canvas)
        console.log('color', color)

        // 导出新的canvas为图片
        let link = document.createElement('a');
        link.download = 'image.png';  // 设置导出的图片文件名
        link.href = canvas.toDataURL("image/png");
        link.click();
    }).catch(err => {
        console.log('生成图片出错: ', err);
    });
}



window.exportElementAsImage = exportElementAsImage


const exportDomToImg = (element) => {
  domtoimage.toPng(element)
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
          console.error('oops, something went wrong!', error);
      });
}
window.exportDomToImg = exportDomToImg


export function exportPdf(pageList) {
    const id = pageList[3].id
    const el = document.querySelector(`[id='${id}']`)
    console.log('el', el)
    window.el = el
    exportElementAsImage(el, 1920, 1080)
}

