import { createSlice } from '@reduxjs/toolkit';
import initState from '../initState';


export default createSlice({
  name: 'checkedIdList',
  initialState: initState.checkedIdList,
  reducers: {
    set(state, { payload }) {
      return payload;
    },
  },
});
