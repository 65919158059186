import modelExtend from 'dva-model-extend';
import { model } from '@/utils/model';

export default modelExtend(model, {
  namespace: 'wordProperties',
  state: {
    pageId: -1,
    prevId: 0,
    type: '',
    index: -1,
    operation: '', // 记录最后一次操作 addpage or addele 现在每个组件初始化时 都需要获得焦点 但不同情况下的初始化获取逻辑不同 以此字段区分
    newDoc: {
      isDoCreating: false,
      docId: 0,
      correspondingPPtId: 0,
    },
    ImportPPtx: {},
    toolsData: {
      Index: [
        {
          title: '副标题',
          icon: 'xiaobiaoti1',
          type: 'description',
        },
        {
          title: '附加信息',
          icon: 'duanluo',
          type: 'author',
        },
      ],
      Transition: [
        {
          title: '标题',
          icon: 'biaoti1',
          type: 'header',
        },
        {
          title: '段落',
          icon: 'duanluo',
          type: 'subheader',
        },
      ],
      List: [
        {
          title: '列表',
          icon: 'tubiao',
          type: 'List',
        },
        {
          title: '文本',
          icon: 'duanluo',
          type: 'describe',
        },
        {
          title: '图片',
          icon: 'ic-pic',
          type: 'plate',
        },
      ],
    },
    toolsStyle: {
      top: 0,
      right: 0,
    },
  },
  subscriptions: {},
  effects: {},
  reducers: {
    stateAny(state, { payload }) {
      return { ...state, ...payload };
    },
  },
});
