import modelExtend from 'dva-model-extend';
import { model } from '@/utils/model';
import {  jsonClone, jsonTo } from '../../src/web-utils/index';
import {
  getBestNewTemplate,
  getBestTemplate,
  getReplaceTemplate,
  getTemplateById,
} from '@/service/services';
import initialState from '@@/plugin-initial-state/models/initialState';
import * as pptx from '@/utils/pptx';
import { getPlaceholder } from '@/const';

export default modelExtend(model, {
  namespace: 'wordEditor',
  state: {
    themeVisible: false,
    allPreviewVisible: false,
    designContentListVisible: true,
    designContentMode: 'all',
    //*-------------------------------------
    docId: null,
    // 当前文档对应的ppt id
    pptId: null,
    byPageId: {},
    allPageIds: [],
    saveStatus: {},
    existEle: [],
    currentEditingPage: null,
    currentEditingType: '',
    loading: true,
    value: '',
    indexPageObj: '',
    firstPageBreak: {},
    Catalogues: new Map(),
    limit: {
      header: 1,
      describe: 1,
      plate: 1,
      List: 1,
    },
    allPagesObject: '',
    example: {
      Index: {
        header: {
          type: ['Text', 'Header', '1'],
          value: '',
        },
        description: {
          type: ['Text', 'Header', '2'],
          value: '',
        },
        author: {
          type: ['Text'],
          value: '',
        },
      },
      Transition: {
        header: {
          type: ['Text', 'Header', '1'],
          value: '',
        },
        subheader: {
          type: ['Text', 'Header', '2'],
          value: '',
        },
      },
      List: [
        {
          type: ['Header', 'Text'],
          value: '',
          show: true,
        },
        {
          type: ['Text'],
          value: '',
          show: false,
        },
        {
          type: ['Image'],
          value: 'https://pub.jianzeppt.cn/image/islide/125111.jpg',
          show: false,
        },
      ],
      describe: {
        value: '',
        saveKeys: ['describe'],
      },
      plate: {
        value: '',
        saveKeys: ['plate'],
      },
      header: {
        value: '',
        saveKeys: ['header'],
      },
      page: {
        templateId: 66,
        type: 'List',
        List: [],
        describe: [],
        plate: [],
        header: {
          value: '',
          saveKeys: ['header'],
        },
      },
    },
    allPageTips: new Map(),
    focusKey: undefined,
  },
  subscriptions: {},
  effects: {
    *getTemplate({ payload }, { call, put, select }) {
      // searchPage 为查询模板添加页码
      const {
        pageId,
        pptId,
        templateId,
        type,
        searchPage,
        maxTemplateSize,
        resolve,
      } = payload;
      yield put({
        type: 'templateThum/setLoading',
        payload: { pageId, searchPage },
      });
      let selectedState = yield select((_) => _);
      let pageData = _.cloneDeep(
        _.get(selectedState.wordEditor, `byPageId[${pageId}]`),
      );
      if (!pageData) {
        function asyncSleep(delay = 1000) {
          return new Promise((resolve) => setTimeout(resolve, delay));
        }
        yield asyncSleep(2000);
        pageData = _.cloneDeep(
          _.get(selectedState.wordEditor, `byPageId[${pageId}]`),
        );
      }
      _.mapKeys(pageData, (value, key) => {
        if (!_.isString(value)) {
          pageData[key] = JSON.stringify(value);
        }
        pageData.pageId = pageId;
        pageData.pptId = pptId || payload.pptId;
        if (templateId) {
          pageData.templateId = templateId || 0;
        }
      });

      // 增删元素时 需要获取最佳模板和推荐模板 删除list里一个item除外
      if (_.indexOf(['ListItem'], type) === -1) {
        if (templateId) {
          const needReplaceTemplate = yield call(getReplaceTemplate, {
            ...pageData,
          });
          yield put({
            type: 'pptxEditor/changeTemplate',
            payload: {
              pageId,
              needReplaceTemplate,
            },
          });
          // 将模板改变后触发保存   updatePageById
          yield put({
            type: 'pptxEditor/updatePageById',
            payload: { templateId: needReplaceTemplate.templateId, id: pageId },
          });
        } else {
          const bestTemplate = yield call(getBestTemplate, {
            pageData,
            searchPage,
            maxTemplateSize,
          });
          console.log('getTemplate');
          yield put({
            type: 'templateThum/generateThumb',
            payload: {
              bestTemplate,
              pageId,
              searchPage,
            },
          });
          if (resolve) {
            resolve(searchPage);
          }
        }
      }
    },
    *getNewTemplate({ payload }, { call, put, select }) {
      const { pageId, level, resolve } = payload;
      let selectedState = yield select((_) => _);
      let pageData = _.get(selectedState.pptxEditor, `byPageId[${pageId}]`);
      let dataPPT = _.get(selectedState.pptxEditor, `data`);
      let pptId = dataPPT.id;
      let theme = _.get(selectedState.pptxEditor, `data.theme`);
      let templateId = pageData.templateId || 0;
      let pptContent = _.get(selectedState.wordEditor, `byPageId[${pageId}]`);
      if (!pptContent) {
        let template = yield select((state) =>
          _.get(state, `pptxTemplate.byId.${templateId}`),
        );
        if (!template) {
          const d = yield call(getTemplateById, { id: templateId });
          const noBase = false;
          if (!d) {
            return null;
          }
          const item = jsonTo(d.data);
          if (!item) {
            return null;
          }
          item.id = d.id;
          item.name = d.name;
          item.type = d.type;
          item.info = d.description;
          item.component = d.component;
          item.createTime = d.createTime;

          if (noBase) {
            return item;
          }
          const templateBase = _.get(
            pptx.dpUtils,
            `${item.component}.templateBase`,
          );
          template = templateBase
            ? pptx.buildOptions(templateBase(), item)
            : item;
        }

        let wordByPageId = yield select((state) =>
          _.get(state, `wordEditor.byPageId`),
        );
        let wordAllPageIds = yield select((state) =>
          _.get(state, `wordEditor.allPageIds`),
        );
        let newPage = pptx.metadataToDocdata(
          theme,
          template,
          dataPPT,
          pageData,
        );
        wordByPageId[pageId] = newPage;
        wordAllPageIds.push(pageId);
        pptContent = newPage;
        yield put({
          type: 'stateAny',
          payload: {
            byPageId: wordByPageId,
            allPageIds: wordAllPageIds,
          },
        });
      }
      _.mapKeys(pptContent, (value, key) => {
        if (!_.isString(value)) {
          pptContent[key] = JSON.stringify(value);
        }
        pptContent.pageId = pageId;
        pptContent.pptId = pptId || payload.pptId;
        if (templateId) {
          pptContent.templateId = templateId || 0;
        }
      });
      yield put({
        type: 'templateThum/setLoading',
        payload: { pageId, searchPage: level },
      });
      return;
      let bestTemplate = yield call(getBestNewTemplate, {
        ...pptContent,
        level,
      });
      yield put({
        type: 'templateThum/generateThumb',
        payload: {
          bestTemplate,
          pageId: pageId,
          searchPage: level,
        },
      });
      if (resolve) {
        resolve(level);
      }
    },
    *getAddEle({ payload }, { put }) {
      yield put({
        type: 'addEle',
        payload: payload,
      });
      yield put({
        type: 'getTemplate',
        payload: payload,
      });
    },
    *getDeleteEle({ payload }, { put }) {
      yield put({
        type: 'deleteEle',
        payload: payload,
      });
      yield put({
        type: 'getTemplate',
        payload: payload,
      });
    },
    *setUpdateTips({ payload }, { put }) {
      yield put({
        type: 'updateTips',
        payload: payload,
      });
    },
  },
  reducers: {
    stateAny(state, { payload }) {
      if (payload?.value) {
        const result = filterIndexPageBreak(payload.value) || {};
        return { ...state, ...payload, ...result };
      }
      return { ...state, ...payload };
    },
    updateCatalogue(state, { payload }) {
      const Catalogues = payload.Catalogues;
      return { ...state, Catalogues };
    },
    updateFirstPageBreak(state, { payload }) {
      const firstPageBreak = payload.firstPageBreak;
      return { ...state, firstPageBreak };
    },
    addPage(state, { payload }) {
      const { error, pageId, resolve } = payload;
      const cpState = _.cloneDeep(state);
      if (!error) {
        const { example, allPageIds, byPageId } = cpState;
        const pageExample = _.cloneDeep(example.page);
        const newId = _.sum(allPageIds);
        byPageId[newId] = pageExample;
        if (pageId) {
          allPageIds.splice(_.indexOf(allPageIds, pageId) + 1, 0, newId);
        }
        resolve({ pageList: [pageExample], newId });
      }
      return _.assign({}, cpState);
    },
    updataDeep(state, { payload }) {
      const cpState = _.cloneDeep(state);
      const { resolve } = payload;
      if (resolve) resolve();
      return _.assign({}, cpState);
    },
    changeData(state, { payload }) {
      const { error, elType, fromPageId, toPageId, elId, resolve } = payload;
      const cpState = _.cloneDeep(state);
      const { byPageId } = cpState;
      if (!error) {
        if (fromPageId === toPageId) {
          return _.assign({}, state);
        }
        const fromBlock = _.result(byPageId, `${fromPageId}[${elType}]`);
        const needMoveBlock = _.find(
          fromBlock,
          (block) => block.cssId === elId,
        );
        byPageId[toPageId][elType].push(needMoveBlock);
        _.remove(fromBlock, (block) => block.cssId === elId);
        resolve(needMoveBlock);
      }
      return _.assign({}, cpState);
    },
    changeListData(state, { payload }) {
      const { error, elType, fromPageId, toPageId, resolve } = payload;
      const cpState = _.cloneDeep(state);
      const { byPageId } = cpState;
      if (!error) {
        if (
          fromPageId === toPageId ||
          (byPageId[toPageId].List && byPageId[toPageId].List.length !== 0)
        ) {
          return _.assign({}, state);
        }

        const needMoveList = _.result(byPageId, `${fromPageId}[${elType}]`);
        byPageId[toPageId][elType] = needMoveList;
        byPageId[fromPageId][elType] = [];
        resolve(needMoveList);
      }

      return _.assign({}, cpState);
    },
    deleteEle(state, { payload }) {
      const { error, pageId, type, index, pageType, resolve } = payload;
      const cpState = _.cloneDeep(state);
      const currentPage = cpState.byPageId[pageId];
      let removeItem = null;
      if (!error) {
        if (pageType === 'List') {
          if (type === 'List') {
            removeItem = currentPage[type];
            currentPage[type] = [];
          }

          if (type === 'ListItem') {
            removeItem = _.find(currentPage.List, (n, i) => i === index);
            _.remove(currentPage.List, (n, i) => i === index);
          }

          if (type === 'describe' || type === 'plate') {
            removeItem = _.find(currentPage[type], (n, i) => i === index);
            _.remove(currentPage[type], (n, i) => i === index);
          }

          if (type === 'header') {
            removeItem = currentPage[type];
            currentPage[type] = null;
          }
        }

        if (['Index', 'Transition'].indexOf(pageType) > -1) {
          removeItem = currentPage[pageType][type];
          currentPage[pageType][type] = null;
        }

        resolve(removeItem);
        // payload.state = cpState;
      }

      return _.assign({}, cpState);
    },
    addEle(state, { payload }) {
      const { error, pageId, type, pageType, resolve, paragraph } = payload;
      const cpState = _.cloneDeep(state);
      const currentPage = cpState.byPageId[pageId];
      if (!error) {
        if (pageType === 'List') {
          if (type === 'header') {
            currentPage[type] = cpState.example[type];
            resolve(cpState.example[type]);
          }

          if (type === 'describe' || type === 'plate') {
            currentPage[type].push(_.cloneDeep(cpState.example[type]));
            resolve([_.cloneDeep(cpState.example[type])]);
          }
          if (type === 'List') {
            let listNum = 3;
            if (paragraph) {
              listNum = paragraph.length;
            }
            currentPage[type] = [];
            for (let i = 0; i < listNum; i++) {
              currentPage[type].push(_.cloneDeep(cpState.example[type]));
            }
            // paragraph时说明有分段 此时需要把分段内容直接复制到初始化的List对象上
            if (paragraph) {
              _.map(currentPage[type], (item, index) => {
                _.map(item, (itemBlock) => {
                  if (_.indexOf(itemBlock.type, 'Header') > -1) {
                    itemBlock.text = paragraph[index].keyPoint;
                    itemBlock.value = paragraph[index].keyPoint;
                  } else {
                    itemBlock.text = paragraph[index].description;
                    itemBlock.value = paragraph[index].description;
                  }
                });
              });
            }
            resolve(currentPage[type]);
          }
        }

        if (['Index', 'Transition'].indexOf(pageType) > -1) {
          currentPage[pageType][type] = _.cloneDeep(
            cpState.example[pageType][type],
          );
          resolve(_.cloneDeep(cpState.example[pageType][type]));
        }

        state = cpState;
      }
      return _.assign({}, cpState);
    },
    getReducerTemplate(state, { payload }) {
      const cpState = _.cloneDeep(state);
      payload.state = cpState;
      return _.assign({}, cpState);
    },
    stateClear(state, { payload }) {
      const newState = funStateClear(state);
      return payload ? _.assign(newState, payload) : newState;
    },
    updateTips(state, { payload }) {
      const { allPageTips } = state;
      const { pageId, tipsArray } = payload;
      allPageTips.set(pageId, tipsArray);
      return state;
    },
    setAllPreviewVisible(state, { payload }) {
      state.allPreviewVisible = payload;
      return { ...state };
    },
    setThemeVisible(state, { payload }) {
      state.themeVisible = payload;
      return { ...state };
    },
    setIsFocused(state, { payload }) {
      state.isFocused = payload;
      return { ...state };
    },
    setDesignContentMode(state, { payload }) {
      state.designContentMode = payload;
      return { ...state };
    },
    setDesignContentListVisible(state, { payload }) {
      state.designContentListVisible = payload;
      return { ...state };
    },
  },
});

function filterIndexPageBreak(value) {
  const dom = new DOMParser().parseFromString(value, 'text/html');
  const body = dom.body;
  const children = body.childNodes;
  const firstPage = children[0];
  if (firstPage.tagName === 'H1') {
    body.removeChild(firstPage);
  }
  children.forEach((node) => {
    if (node.tagName === 'svg') {
      body.removeChild(node);
    }
  });

  const usePlaceholder = localStorage.getItem('usePlaceholder');
  localStorage.removeItem('usePlaceholder');
  if (usePlaceholder) {
    children.forEach((node) => {
      const key = node.getAttribute('key');
      if (key) {
        const placeholder = getPlaceholder(key);
        if (placeholder) {
          node.setAttribute('data-placeholder', placeholder);
        }
      }
    });
  }
  return { value: body.innerHTML };
}

function funStateClear(state) {
  _.assign(state, jsonClone(initialState));
  return _.assign({}, state);
}
