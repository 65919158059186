import { createSlice } from '@reduxjs/toolkit'
import produce from 'immer'

const initialState = {
    // 调整菜单里的
    adjustMenu: {
        brightness: 0, // 亮度
        contrast: 0, // 对比度
        saturation: 0, // 饱和度
        blendColor: {
            color: '', // 叠加色
            alpha: 30, // 叠加强度
        },
    },

    // 滤镜
    filter: {
        key: '',
        value: '',
    },

    // 裁剪类型
    clipType: '', // 'none' | 'circle'
}

export const appSlice = createSlice({
    name: 'canvasImage',
    initialState,
    reducers: {
        setAdjustMenu(state, action) {
            const { key, value } = action.payload
            state.adjustMenu[key] = value
        },
        setFilter(state, action) {
            const { key, value = '' } = action.payload
            state.filter = {
                key,
                value,
            }
        },
        clearAdjust(state, action) {
            state.adjustMenu = {
                brightness: 0, // 亮度
                contrast: 0, // 对比度
                saturation: 0, // 饱和度
                blendColor: {
                    color: '', // 叠加色
                    alpha: 0, // 叠加强度
                },
            }

            state.filter = {
                key: '',
                value: '',
            }

            state.clipType = ''
        },
        setClipType(state, action) {
            state.clipType = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setAdjustMenu, setFilter, clearAdjust, setClipType,
} = appSlice.actions
export default appSlice.reducer
