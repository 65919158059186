import { createSlice } from '@reduxjs/toolkit';
import initState from '@/pages/Edit/redux/initState';


export default createSlice({
  name: 'chartsColor',
  initialState: initState.chartsColor,
  reducers: {
    set(state, { payload }) {
      return payload
    },
  },
});
