export default {
  TEMPLATE_CHANGE_MULTI_1: '切换PPT模板',
  TEMPLATE_CHANGE_MULTI_2: '公司规定了模板？',
  TEMPLATE_CHANGE_MULTI_3: '解析主题风格，搭配新页面不用愁',
  TEMPLATE_CHANGE_MULTI_4: '上传模板',
  TEMPLATE_CHANGE_MULTI_5: '返回',
  TEMPLATE_CHANGE_MULTI_6: '年终工作总结计划',
  TEMPLATE_CHANGE_MULTI_7: '页数',
  TEMPLATE_CHANGE_MULTI_8: '应用模板主题',
  TEMPLATE_CHANGE_MULTI_9: '上传本地模板',

};
