// 判断文字排版
import { ETextAdjustment } from "@/pages/Edit/type/ITextNode"
import { TEXT_LAYOUT_AUTO_HEIGHT, TEXT_LAYOUT_AUTO_WIDTH, TEXT_LAYOUT_FIXED_SIZE, TEXT_LAYOUT_ZOOM } from "./const"

export const getTextLayoutByNode = (node) => {
    const align = node.textAdjustment
    // 判断是否自动调整尺寸
    const isAuto = !align || align === ETextAdjustment.autoRect
    const isZoom = align === ETextAdjustment.zoom
    const isFixed = align === ETextAdjustment.none
    // 判断是否换行
    const isWrap = node.wordWrap === true || !node.wordWrap


    if (isAuto && !isWrap) {
        return TEXT_LAYOUT_AUTO_WIDTH
    } else if (isAuto && isWrap) {
        return TEXT_LAYOUT_AUTO_HEIGHT
    } else if (isZoom && isWrap) {
        return TEXT_LAYOUT_ZOOM
    } else if (isFixed) {
        return TEXT_LAYOUT_FIXED_SIZE
    }
}
