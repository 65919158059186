export default {
  premSel: 'Premium Selection',
  enhEff: 'Enhanced Efficiency',
  higQuaAff: 'High Quality & Affordable',
  compSat: '100% Satisfaction',
  seaTem: 'Search',
  genOne: 'Generate',
  pptTem: 'Template',
  imgMat: 'Image',
  choIco: 'Icon',
  goWor: 'Workspace',
};
