export default {
  TEMPLATE_CHANGE_1: 'Switch Template',
  TEMPLATE_CHANGE_2: 'Confirm',
  TEMPLATE_CHANGE_3: 'Cancel',
  TEMPLATE_CHANGE_4: 'Move to Trash',
  TEMPLATE_CHANGE_5: 'Document moved to Trash, will be permanently deleted after {res} days',
  TEMPLATE_CHANGE_6: 'Exit Collaboration',
  TEMPLATE_CHANGE_7: 'After exiting collaboration, you won\'t have access to this document\'s permissions.',
  TEMPLATE_CHANGE_8: 'Presentation',
  TEMPLATE_CHANGE_9: 'Edit',
  TEMPLATE_CHANGE_10: 'Copy',
  TEMPLATE_CHANGE_11: 'Move to Trash',
  TEMPLATE_CHANGE_12: 'Exit Collaboration',
  TEMPLATE_CHANGE_13: 'Optional Layout',
  TEMPLATE_CHANGE_14: 'No suitable layout, consider less content.',
  TEMPLATE_CHANGE_15: 'More',
  TEMPLATE_CHANGE_16: 'Save',
  TEMPLATE_CHANGE_17: 'Start from Scratch',
  TEMPLATE_CHANGE_18: 'Start from Current',
  TEMPLATE_CHANGE_19: 'Share this Design',
  TEMPLATE_CHANGE_20: 'Owner',
  TEMPLATE_CHANGE_21: 'Editor',
  TEMPLATE_CHANGE_22: 'Viewer',
  TEMPLATE_CHANGE_23: 'Share Link',
  TEMPLATE_CHANGE_24: 'Link Permission',
  TEMPLATE_CHANGE_25: 'Copy Successful',
  TEMPLATE_CHANGE_26: 'Can Edit, Comment, Share File',
  TEMPLATE_CHANGE_27: 'Can View, Comment, but Not Edit',
  TEMPLATE_CHANGE_28: 'One Hour',
  TEMPLATE_CHANGE_29: 'One Day',
  TEMPLATE_CHANGE_30: 'Seven Days',
  TEMPLATE_CHANGE_31: 'Expiration',
  TEMPLATE_CHANGE_32: ' Copy Link',
  TEMPLATE_CHANGE_33: 'PDF-Print',
  TEMPLATE_CHANGE_34: 'Word-Content Only',
  TEMPLATE_CHANGE_35: 'Markdown-Content Only',
  TEMPLATE_CHANGE_36: 'Freemind-Content Only',
  TEMPLATE_CHANGE_37: 'Page{res}',
  TEMPLATE_CHANGE_38: 'Word-Content Only',
  TEMPLATE_CHANGE_39: 'Group',
  TEMPLATE_CHANGE_40: 'Ungroup',
  TEMPLATE_CHANGE_41: 'Set as Background',
  TEMPLATE_CHANGE_42: 'Detach Background Image',
  TEMPLATE_CHANGE_43: 'Remove Background Image',
  TEMPLATE_CHANGE_44: 'Cut',
  TEMPLATE_CHANGE_45: 'Paste',
  TEMPLATE_CHANGE_46: 'Unlock Layer',
  TEMPLATE_CHANGE_47: 'Lock Layer',
  TEMPLATE_CHANGE_48: 'Layer Order',
  TEMPLATE_CHANGE_49: 'Move to Top',
  TEMPLATE_CHANGE_50: 'Move Up One Layer',
  TEMPLATE_CHANGE_51: 'Move Down One Layer',
  TEMPLATE_CHANGE_52: 'Move to Bottom',
  TEMPLATE_CHANGE_53: 'Delete Layer',
  TEMPLATE_CHANGE_54: 'Comment',
  TEMPLATE_CHANGE_55: 'Delete Layer',
  TEMPLATE_CHANGE_56: 'Delete Layer',
  TEMPLATE_CHANGE_57: 'Delete Layer',
  TEMPLATE_CHANGE_58: 'Delete Layer',
  TEMPLATE_CHANGE_62: 'Vertical row'

};
