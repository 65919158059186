export default{
  "TEMPLATES_1":"TEMPLATES_1",
  "TEMPLATES_2":"TEMPLATES_2",
  "TEMPLATES_3":"TEMPLATES_3",
  "TEMPLATES_4":"TEMPLATES_4",
  "TEMPLATES_5":"TEMPLATES_5",
  "TEMPLATES_6":"TEMPLATES_6",
  "TEMPLATES_7":"TEMPLATES_7",
  "TEMPLATES_8":"TEMPLATES_8",
  "TEMPLATES_9":"TEMPLATES_9",
  "TEMPLATES_10":"TEMPLATES_10",
  "TEMPLATES_11":"TEMPLATES_11",
  "TEMPLATES_12":"TEMPLATES_12",
  "TEMPLATES_13":"TEMPLATES_13",
  "TEMPLATES_14":"TEMPLATES_14",
  "TEMPLATES_15":"TEMPLATES_15",
  "TEMPLATES_16":"TEMPLATES_16",
  "TEMPLATES_17":"TEMPLATES_17",
  "TEMPLATES_18":"TEMPLATES_18",
  "TEMPLATES_19":"TEMPLATES_19",
  "TEMPLATES_20":"TEMPLATES_20",
  "TEMPLATES_21":"TEMPLATES_21",
  "TEMPLATES_22":"TEMPLATES_22",
  "TEMPLATES_23":"TEMPLATES_23",
  "TEMPLATES_24":"TEMPLATES_24",
  "TEMPLATES_25":"TEMPLATES_25",
  "TEMPLATES_26":"TEMPLATES_26",
  "TEMPLATES_27":"TEMPLATES_27",
  "TEMPLATES_28":"TEMPLATES_28",
  "TEMPLATES_29":"TEMPLATES_29",
  "TEMPLATES_30":"TEMPLATES_30",
  "TEMPLATES_31":"TEMPLATES_31",
  "TEMPLATES_32":"TEMPLATES_32",
  "TEMPLATES_33":"TEMPLATES_33",
  "TEMPLATES_34":"TEMPLATES_34",
  "TEMPLATES_35":"TEMPLATES_35",
  "TEMPLATES_36":"TEMPLATES_36",
  "TEMPLATES_37":"TEMPLATES_37",
  "TEMPLATES_38":"TEMPLATES_38",
  "TEMPLATES_39":"TEMPLATES_39",
  "TEMPLATES_40":"TEMPLATES_40",
  "TEMPLATES_41":"TEMPLATES_41",
  "TEMPLATES_42":"TEMPLATES_42",
  "TEMPLATES_43":"TEMPLATES_43",
  "TEMPLATES_44":"TEMPLATES_44",
  "TEMPLATES_45":"TEMPLATES_45",
  "TEMPLATES_46":"TEMPLATES_46",
}
