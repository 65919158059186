export default {
  "TEMPLATE_CHANGE_MULTI_1": "TEMPLATE_CHANGE_MULTI_1",
  "TEMPLATE_CHANGE_MULTI_2": "TEMPLATE_CHANGE_MULTI_2",
  "TEMPLATE_CHANGE_MULTI_3": "TEMPLATE_CHANGE_MULTI_3",
  "TEMPLATE_CHANGE_MULTI_4": "TEMPLATE_CHANGE_MULTI_4",
  "TEMPLATE_CHANGE_MULTI_5": "TEMPLATE_CHANGE_MULTI_5",
  "TEMPLATE_CHANGE_MULTI_6": "TEMPLATE_CHANGE_MULTI_6",
  "TEMPLATE_CHANGE_MULTI_7": "TEMPLATE_CHANGE_MULTI_7",
  "TEMPLATE_CHANGE_MULTI_8": "TEMPLATE_CHANGE_MULTI_8",
  "TEMPLATE_CHANGE_MULTI_9": "TEMPLATE_CHANGE_MULTI_9",

}
