import { Spin } from 'antd';
import styled from 'styled-components';

const BoxStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default () => {
  return (
    <BoxStyled>
      <Spin />
    </BoxStyled>
  );
};
