export default {
  WORKSPACE_1: '快速创建',
  WORKSPACE_2: 'AI帮你做PPT',
  WORKSPACE_3: '自动生成',
  WORKSPACE_4: '输入你要写的PPT主题内容',
  WORKSPACE_5: '选择PPT模板',
  WORKSPACE_6: 'word转PPT',
  WORKSPACE_7: '本地PPT模板',
  WORKSPACE_8: '我的设计',
  WORKSPACE_9: '回收站',
  WORKSPACE_10: '回收站',
  WORKSPACE_11: '插件下载',
  WORKSPACE_12: '客户端下载',
  WORKSPACE_13: '语言切换',
  WORKSPACE_16: '自动生成',
  WORKSPACE_17: '浏览模板',
  WORKSPACE_18: '工作总结',
  WORKSPACE_19: '商业计划书',
  WORKSPACE_20: '营销策划',
  WORKSPACE_21: '毕业答辩',
  WORKSPACE_22: '企业培训',
  WORKSPACE_23: '企业宣传',
  WORKSPACE_24: '支持自由输入、Word、Markdown、Freemind 等格式',
  WORKSPACE_25: '多格式内容转PPT',
  WORKSPACE_26: '即将完成...',
  WORKSPACE_27: 'PPT生成中',
  WORKSPACE_28: '上传文件类型错误',
  WORKSPACE_29: '拖拽文件到这里',
  WORKSPACE_30: '支持doc、docx等格式文件',
  WORKSPACE_31: '上传word文件',
  WORKSPACE_32: '运营年终工作总结',
  WORKSPACE_33: '电商行业研究报告',
  WORKSPACE_34: '医疗数字化：开创未来',
  WORKSPACE_35: '科技驱动企业文化',
  WORKSPACE_36: '项目实施与提升',
  WORKSPACE_37: '电商行业研究报告',
};
