import createNodeId from '@/pages/Edit/common/createNodeId'
import { INodeType } from '@/pages/Edit/type/INode'
import { EAlignment } from '@/pages/Edit/type/ITextNode'

export default (textContent = '', colorTheme) => {
    const theme = colorTheme || window?.mgTheme?.backGroundImageColorInfo?.color_theme
    let value = `<p>${textContent || '双击编辑文字'}</p>`
    if (theme === 'dark') {
        value = `<p><span style='color:#ffffff;'>${textContent || '双击编辑文字'}</span></p>`
    }
    return {
        id: createNodeId(),
        uuid: createNodeId(),
        type: INodeType.text,
        value,
        textScale: 1,
        transform: {
            translateX: 0,
            translateY: 0,
        },
        style: {
            width: 200,
            height: 22,
            fontSize: 14,
            position: 'absolute',
            lineHeight: 1.2,
            top: 214,
            left: 300,
            textScale: 1,
            zIndex: 200,
        },
        wordWrap: false,
    }
}
