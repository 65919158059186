import { buildMetadata } from '../utils';

export const getMetadata = (props) => {
  const { options, page } = props;
  if (!_.get(options, 'header.value') || !_.get(page, 'header')) {
    return null;
  }
  // console.log('page.header',page.header);
  const metadata = buildMetadata(options.header, page.header)(options);
  metadata.valueKeys = metadata.valueKeys.filter(
    (d) => !!_.get(metadata, `value.${d}.norm`),
  );
  // console.log("metadata",metadata);
  return metadata;
};

const valueTextKey = 'header.value.header.value';

export const getDocdata = (metadata) => {
  const item = _.get(metadata, 'value.header');
  return item
    ? {
        key: valueTextKey,
        value: item.value,
      }
    : {};
};
/**
 * 更新来自「内容页」的 value 到 page
 * @method d2mUpdate
 * @param  {[type]}  header [参考 getDocdata 的返回值]
 * @param  {[type]}  page   [description]
 * @return {[type]}         [空数组表名无更新（因此不需要触发api）]
 */
export const d2mUpdate = (header, page) => {
  if (!header) {
    return [];
  }
  const key = header.key || valueTextKey;
  if (_.get(page, key) === header.value) {
    return [];
  }
  _.set(page, key, header.value);
  return ['header'];
};
/**
 * 删除 header
 * @method d2mDelete
 * @param  {[type]}  header [参考 getDocdata 的返回值]
 * @param  {[type]}  page   [description]
 * @return {[type]}         [空数组表名无更新（因此不需要触发api）]
 */
export const d2mDelete = (header, page) => {
  if (!header) {
    return [];
  }
  page.header = null;
  return ['header'];
};
