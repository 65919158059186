import getBaseUrl from '@/utils/base_url';

export const importFCUrl_ppt_pro = 'https://fc-longocket-vg-presentxport-fc-arvgzxqouk.cn-shanghai.fcapp.run/';
export const importFCUrl_ppt_dev = getBaseUrl();


export const importFCUrl_word_pro = 'https://fc-longe-socket-presentxport-fc-oyvhhwirfv.cn-shanghai.fcapp.run';
export const importFCUrl_word_dev = getBaseUrl();




export const importFCUrl_ppt = getBaseUrl()
export const importFCUrl_word = getBaseUrl()
