export default {
    FileUpload_01: 'FileUpload_01',
    FileUpload_02: 'FileUpload_02',
    FileUpload_03: 'FileUpload_03',
    FileUpload_04: 'FileUpload_04',
    FileUpload_05: 'FileUpload_05',
    FileUpload_06: 'FileUpload_06',
    FileUpload_07: 'FileUpload_07',
    FileUpload_08: 'FileUpload_08',
    FileUpload_09: 'FileUpload_09',
    FileUpload_10: 'FileUpload_10',
    FileUpload_11: 'FileUpload_11',
    FileUpload_12: 'FileUpload_12',
    FileUpload_13: 'FileUpload_13',
    FileUpload_14: 'FileUpload_14',
    FileUpload_15: 'FileUpload_15',
    FileUpload_16: 'FileUpload_16',
    FileUpload_17: 'FileUpload_17',
    FileUpload_18: 'FileUpload_18',
    FileUpload_19: 'FileUpload_19',
    FileUpload_20: 'FileUpload_20',
    FileUpload_21: 'FileUpload_21',
    FileUpload_22: 'FileUpload_22',
    FileUpload_23: 'FileUpload_23',
    FileUpload_24: 'FileUpload_24',
    FileUpload_25: 'FileUpload_25',
    FileUpload_26: 'FileUpload_26',
    FileUpload_27: 'FileUpload_27',
    FileUpload_28: 'FileUpload_28',
    FileUpload_29: 'FileUpload_29',
    FileUpload_30: 'FileUpload_30',
    FileUpload_31: 'FileUpload_31',
    FileUpload_32: 'FileUpload_32',
    FileUpload_33: 'FileUpload_33',
    FileUpload_34: 'FileUpload_34',
    FileUpload_35: 'FileUpload_35',
    FileUpload_36: 'FileUpload_36',
    FileUpload_37: 'FileUpload_37',
    FileUpload_38: 'FileUpload_38',
    FileUpload_39: 'FileUpload_39',
    FileUpload_40: 'FileUpload_40',
}
