export default {
  PERSONAL_01: 'PERSONAL_01',
  PERSONAL_02: 'PERSONAL_02',
  PERSONAL_03: 'PERSONAL_03',
  PERSONAL_04: 'PERSONAL_04',
  PERSONAL_05: 'PERSONAL_05',
  PERSONAL_06: 'PERSONAL_06',
  PERSONAL_07: 'PERSONAL_07',
  PERSONAL_08: 'PERSONAL_08',
  PERSONAL_09: 'PERSONAL_09',
  PERSONAL_10: 'PERSONAL_10',
  PERSONAL_11: 'PERSONAL_11',
  PERSONAL_12: 'PERSONAL_12',
  PERSONAL_13: 'PERSONAL_13',
  PERSONAL_14: 'PERSONAL_14',
}
