import React from "react";

export enum INodeType {
  text = 'text',
  image = 'image',
  svg = 'svg',
  line = 'line',
  charts = 'charts',
  layout = 'layout',
  table = 'table',
  video = 'video',
  mermaid = 'mermaid',
  animaker = 'animaker',
  svgEasy = 'svgEasy'
}

export enum IMermaidType {
  flowchart = 'flowchart',


}
export interface INode {
  id: string;
  type: INodeType;
  value: any;
  style: any;
}

export const getNodeNameByType = (type: INodeType) => {
  switch (type) {
    case INodeType.text: {
      return "文本"
    }
    case INodeType.image: {
      return "图片"
    }
    case INodeType.svg: {
      return "形状"
    }
    case INodeType.line: {
      return "线"
    }
    case INodeType.charts: {
      return "图表"
    }
    case INodeType.table: {
      return "表格"
    }
  }
}

export default INode
