import * as echarts from 'echarts'

export const checkChartOption = (option) => {
    if (!option.series) return false

    try {
        const div = document.createElement('div')
        const chart = echarts.init(div)

        chart.setOption(option)
        const chartOption = chart.getOption()
        // 检查是否有数据系列被加载
        if (chartOption.series.length === 0 || !chartOption.series[0].data) {
            return false
        }
        return true
    } catch (error) {
        return false
    }
}
