import { buildMetadata } from '../utils';

export const getMetadata = (props) => {
  const { options, page } = props;
  if (!_.get(options, 'footer.value') || !_.get(page, 'footer')) {
    return null;
  }
  const metadata = buildMetadata(options.footer, page.footer)(options);
  metadata.valueKeys = metadata.valueKeys.filter(
    (d) => !!_.get(metadata, `value.${d}.norm`),
  );
  return metadata;
};

export const getDocdata = () => ({});

export const d2mUpdate = () => [];
export const d2mDelete = () => [];
