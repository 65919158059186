export default {
  PERSONAL_01: 'Binding Successful',
  PERSONAL_02: 'Binding successful, after binding you can use this phone number to log in to all Instant PPT products, including mini-programs, etc.',
  PERSONAL_03: 'Phone Number Binding',
  PERSONAL_04: 'Please Enter Phone Number',
  PERSONAL_05: 'SMS Verification Code',
  PERSONAL_06: 'Send Verification Code',
  PERSONAL_07: 'Bind Now',
  PERSONAL_08: 'Keywords',
  PERSONAL_09: 'Generation Type',
  PERSONAL_10: 'Consumption/Page',
  PERSONAL_11: 'Time',
  PERSONAL_12: 'PPT Name',
  PERSONAL_13: 'Action',
  PERSONAL_14: 'Download'
};
