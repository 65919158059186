import { createSlice } from '@reduxjs/toolkit';
import initState from '@/pages/Edit/redux/initState';

// showFullScreenAnimation: false,
// showSyllabusAnimation: false,
// showContentAnimation: false,
export default createSlice({
  name: 'animation',
  initialState: initState.animation,
  reducers: {
    setFullScreenAnimation(state, { payload }) {
      state.showFullScreenAnimation = true
    },
    closeFullScreenAnimation(state, { payload }) {
      state.showFullScreenAnimation = false
      state.showProgressAnimation = false
    },
    setSyllabusAnimation(state) {
      state.showFullScreenAnimation = false
      state.showSyllabusAnimation = true
    },
    setProgressAnimation(state) {
      state.showProgressAnimation = true
    },
    setNonContentAnimation(state) {
      state.showNonContentAnimation = true
    },
    setContentAnimation(state) {
      state.showContentAnimation = true
      state.showSyllabusAnimation = false
    },
    setAnimationData(state, { payload }) {
      state.animationData = payload
    },
    updateAnimationTemplateData(state, { payload}) {
      state.animationData.templateId = payload
    },
    updateAnimationSyllabusData(state, { payload}) {
      const { syllabus, contentSyllabus } = payload
      state.animationData.syllabus = syllabus
      state.animationData.contentSyllabus = contentSyllabus
    },
    setTemplateAnimation(state) {
      state.showTemplateAnimation = true
    },
    setIsTypingEnded(state) {
      state.isTypingEnded = true
    },
    setErrorCode(state, { payload }) {
      state.errorCode = payload
    },
    reset(state) {
      state.showProgressAnimation = false
      state.showFullScreenAnimation = false
      state.showSyllabusAnimation = false
      state.showNonContentAnimation = false
      state.showContentAnimation = false
      state.showTemplateAnimation = false
      state.isTypingEnded = false
      state.errorCode = null
      state.animationData = {}
    }
  },
});
