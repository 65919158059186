import { createSlice } from '@reduxjs/toolkit'
import initState from '../initState'

export default createSlice({
    name: 'currentFocusNodeUUID',
    initialState: initState.currentFocusNodeId,
    reducers: {
        set(state, { payload }) {
            return payload
        },
    },
})
