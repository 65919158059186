import { getParam } from './log'

const getBaseUrl = () => {
    let BASE_URL
    if (process.env.NODE_ENV === 'development') {
        BASE_URL = 'https://api-test.jianzeppt.cn'
    } else {
        BASE_URL = 'https://api.jianzeppt.com'
        if (process.env.TEST === 'test') {
            BASE_URL = 'https://api-test.jianzeppt.cn'
        }
    }
    if (__DEV__) {
        const env = getParam('env')
        if (env === 'prod') {
            BASE_URL = 'https://api.jianzeppt.com'
        }
    }
    if (window.location.hostname === 'ppt.test.data.jianzeppt.cn') {
        BASE_URL = 'https://api-test-data.jianzeppt.cn'
    }
    // if (__LOC__) {
    //     BASE_URL = 'http://10.32.7.230:16669'
    // }
    return BASE_URL
}
export default getBaseUrl
