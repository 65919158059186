import { buildOptions } from '../utils';
import { getKeyBase } from './key.base';
import * as headerMore from './header';
import * as footerMore from './footer';
import * as plateMore from './plate';
import * as chartsMore from './charts';
import * as describeMore from './describe';
import * as bodyListMore from './body.list';
import * as bodyIndexMore from './body.index';
import * as bodyTransitionMore from './body.Transition';

import { jsonFrom, jsonTo } from '../../../web-utils/index';

const fromto = { from: (d) => jsonFrom(d) || '', to: (d) => jsonTo(d) || null };

export const dpUtils = {
  illustration: { ...fromto, ...getKeyBase('illustration', true) },
  header: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || null,
    ...headerMore,
  },
  Transition: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || {},
    ...bodyTransitionMore,
  },
  describe: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || {},
    ...describeMore,
  },
  plate: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || {},
    ...plateMore,
  },
  charts: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || {},
    ...chartsMore,
  },
  // 待废弃
  body: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || {},
  },
  List: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || {},
    ...bodyListMore,
  },
  Catalogue: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || {},
    ...bodyListMore,
  },
  Index: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || {},
    ...bodyIndexMore,
  },
  customTemlate: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || {},
  },
  footer: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || null,
    ...footerMore,
  },
  templateId: { from: (d) => d, to: (d) => d },
  sort: { from: (d) => d, to: (d) => d },
  extend: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || null,
  },
  style: {
    from: (d) => jsonFrom(d) || '',
    to: (d) => jsonTo(d) || {},
  },
};

function getDocdata(name, page) {
  if (!page) {
    return {};
  }
  const baseValue = _.get(page, `${name}.value`);
  const body = {};
  const beforeKey = `${name}.value`;
  _.keys(baseValue).forEach((key) => {
    const itemdata = baseValue[key];
    body[key] = {
      value: itemdata.value,
      key: `${beforeKey}.${key}.value`,
      attrs: itemdata.attrs,
      itemkey: key,
    };
  });
  return body;
}

/**
 * 转换本地的数据用于 api 保存
 * @method dataPageToSave
 * @param  {[type]}       dataPage [description]
 * @param  {[type]}       keys     [description]
 * @return {[type]}                [description]
 */
//todo 替换数据来源
export function dataPageToSave(dataPage, keys) {
  const query = {};

  const body = {};
  (keys || Object.keys(dpUtils)).forEach((name) => {
    if (name === 'body') {
      return;
    }
    if (['List', 'Index', 'Transition'].indexOf(name) !== -1) {
      body[name] = dataPage[name];
    } else {
      query[name] = dpUtils[name].from(dataPage[name]);
    }
  });
  if (_.keys(body).length) {
    body.customStore = dataPage.customStore;
    body.canMove = dataPage.canMove;
    query.body = dpUtils.body.from(body);
  }
  return query;
}

/**
 * 转换 api 返回的数据未本地需要的数据结构
 * @method dataPageFromApi
 * @param  {[type]}        dataPage [description]
 * @return {[type]}                 [description]
 */
export function dataPageFromApi(dataPage) {
  Object.keys(dpUtils).forEach((name) => {
    dataPage[name] = dpUtils[name].to(dataPage[name]);
  });
  _.keys(dataPage.body).forEach((d) => {
    dataPage[d] = dataPage.body[d];
    delete dataPage.body[d];
  });
  delete dataPage.body;
  return dataPage;
}

/**
 * 按照「内容页」的需要更新数据
 * @method docdataToUpdate
 * @param  {[type]}        docdata [参照 dpUtils.x.getDocdata]
 * @param  {[type]}        page    [description]
 * @return {[type]}                [需要调用 api 保存的数据 key]
 */
export function docdataToUpdate(docdata, page) {
  const saveKeys = _.keys(docdata)
    .map((name) => {
      if (_.get(dpUtils, `${name}.d2mUpdate`)) {
        return dpUtils[name].d2mUpdate(docdata[name], page);
      }
      return [];
    })
    .reduce((a, b) => a.concat(b), []);
  return _.uniq(saveKeys);
}

/**
 * 按照「内容页」的需求删除数据
 * @method docdataToDelete
 * @param  {[type]}        docdata [参照 dpUtils.x.getDocdata]
 * @param  {[type]}        page    [description]
 * @return {[type]}                [需要调用 api 保存的数据 key]
 */
export function docdataToDelete(docdata, page) {
  const saveKeys = _.keys(docdata)
    .map((name) => {
      if (_.get(dpUtils, `${name}.d2mDelete`)) {
        return dpUtils[name].d2mDelete(docdata[name], page);
      }
      return [];
    })
    .reduce((a, b) => a.concat(b), []);
  return _.uniq(saveKeys);
}

/**
 * 将「设计页」的数据转换成「内容页」的数据
 * @method metadataToDocdata
 * @param  {[type]}          theme    [description]
 * @param  {[type]}          template [description]
 * @param  {[type]}          dataPPT  [description]
 * @param  {[type]}          page     [description]
 * @return {[type]}                   [description]
 */
export function metadataToDocdata(theme, template, dataPPT, page) {
  const options = buildOptions(
    theme,
    template,
    dataPPT.customTheme,
    page.customTemlate,
  );
  const data = {
    type: page.type,
  };

  _.keys(dpUtils).forEach((name) => {
    if (['describe'].indexOf(name) !== -1) {
      data[name] = dpUtils[name]
        .positionKeys()
        .map((position) => {
          const metadata = dpUtils[name].getMetadata({
            options,
            page,
            dataPPT,
            position,
          });
          return metadata ? dpUtils[name].getDocdata(metadata) : null;
        })
        .filter((d) => !!d);
      return;
    }
    if (dpUtils[name].getDocdata) {
      const metadata = dpUtils[name].getMetadata({ options, page, dataPPT });

      if (metadata) {
        data[name] = dpUtils[name].getDocdata(metadata);
      }
    }
  });

  return data;
}

/**
 * 将「设计页」的数据转换成「内容页」的value
 */
export function metadataToDocvalue(
  theme,
  template,
  dataPPT,
  page,
  pageNum,
  needBr,
) {
  const options = buildOptions(
    theme,
    template,
    dataPPT.customTheme,
    page.customTemlate,
  );

  if (page.type === 'Catalogue') {
    return '';
  }
  if (page.type === 'Index') {
    return '';
  }
  let value = '';
  // 非首页增加分页符
  if (needBr) {
    value += `<br key='${
      page.id
    }' style='page-break-before:always'  type='${selectionType(
      options.type,
    )}'/>`;
  }

  //添加type
  function selectionType(type) {
    let resType = '';
    switch (type) {
      case 'Index':
        resType = 'index';
        break;
      case 'Transition':
        resType = 'transition';
        break;
      default:
        resType = 'normal';
    }
    return resType;
  }

  // 增加key
  function convertItemValue(item) {
    if (!item.value) {
      return '';
    }
    if (!item.value.startsWith('<')) {
      item.value = `<p>${item.value}</p>`;
    }
    let v = '';
    const doc = new DOMParser().parseFromString(item.value, 'text/html');
    doc.body.childNodes.forEach((ele) => {
      ele?.setAttribute?.('key', `${page.id}.${item.key}`);
      if (item.id) {
        ele?.setAttribute?.('id', item.id);
      }
      v += ele.outerHTML;
    });
    return v;
  }

  //增加tagName的key
  function tagNameItemValue(item, tagName) {
    if (!item.value) {
      return '';
    }
    let v = '';
    let placeholder;
    let bold;

    if (item.value.startsWith('<')) {
      //剥夺<p>
      const doc = new DOMParser().parseFromString(item.value, 'text/html');
      doc.body.childNodes.forEach((ele) => {
        v += ele.innerText;
        if (ele.getAttribute('data-placeholder')) {
          placeholder = ele.getAttribute('data-placeholder');
        }
        if (tagName === 'h2' || tagName === 'h3') {
          bold = ele.getAttribute('data-bold');
        }
      });
    } else {
      v = item.value;
    }
    let newElement = document.createElement(tagName);
    let key = page.id + '.' + item.key;
    newElement.setAttribute('key', key);
    if (bold) {
      newElement.setAttribute('data-bold', bold);
    }

    if (placeholder) {
      newElement.setAttribute('data-placeholder', placeholder);
    }

    newElement.innerText = v;
    return newElement.outerHTML.replace(/(&[a-z]{3,4};)/, ' ');
  }

  _.keys(dpUtils).forEach((name) => {
    let docdata = { value: '' };
    if (['Index'].indexOf(name) !== -1) {
      const metadata = dpUtils[name].getMetadata({ options, page, dataPPT });
      docdata = dpUtils[name].getDocdata(metadata);
      if (_.keys(docdata).length !== 0) {
        const h1 = document.createElement('h1');
        h1.setAttribute('key', page.id);
        Object.keys(docdata).forEach((k) => {
          const item = docdata[k];
          switch (k) {
            case 'header':
              h1.innerText = item.value;
              break;
            case 'description': {
              const figcaption = document.createElement('figcaption');
              figcaption.setAttribute('type', 'description');
              figcaption.innerText = item.value;
              if (item.value) {
                h1.appendChild(figcaption);
              }
              break;
            }
            case 'author': {
              const figcaption = document.createElement('figcaption');
              figcaption.setAttribute('type', 'author');
              figcaption.innerText = item.value;
              if (item.value) {
                h1.appendChild(figcaption);
              }
              break;
            }
            case 'date': {
              const figcaption = document.createElement('figcaption');
              figcaption.setAttribute('type', 'date');
              figcaption.innerText = item.value;
              if (item.value) {
                h1.appendChild(figcaption);
              }
              break;
            }
          }
        });
        let text = h1.outerHTML;
        text = text.replace(/&lt;p&gt;/g, '');
        text = text.replace(/&lt;\/p&gt;/g, '');
        value += text;
      }
    } else if (['Transition'].indexOf(name) !== -1) {
      docdata = getDocdata('Transition', page);
      if (_.keys(docdata).length !== 0) {
        if (docdata.header) {
          value += tagNameItemValue(docdata.header, 'h2');
        }
        if (docdata.subheader) {
          value += convertItemValue(docdata.subheader);
        }
      }
    }

    if (['List'].indexOf(name) !== -1) {
      let valueObj = page.List?.value || {};
      const arr = [];
      Object.keys(valueObj).forEach((key) => {
        const item = valueObj[key];
        const value = item.value;
        Object.keys(value).forEach((type) => {
          if (type === 'number') {
            return;
          }
          const valueItem = value[type];
          const obj = {
            type: type,
            key: `List.value.${key}.value.${type}.value`,
            value: valueItem.value,
            id: value[type].id,
          };
          if (obj.value) {
            if (obj.type !== 'image') {
              if (!obj.value.startsWith('<')) {
                obj.value = `<p>${obj.value}</p>`;
              }
            }
            switch (obj.type) {
              case 'image':
                break;
              case 'header':
                obj.value = obj.value
                  .replaceAll('<p', '<h4')
                  .replaceAll('p>', 'h4>');
                break;
              case 'description':
                obj.value = obj.value
                  .replaceAll('<p', '<h5')
                  .replaceAll('p>', 'h5>');
                break;
            }
            arr.push(obj);
          }
        });
      });
      arr.forEach((item) => {
        switch (item.type) {
          case 'image':
            value += `<figure class='image'>
                     <img key='${page.id}.${item.key}' src='${item.value}' type='listImage'/>
                  </figure>`;
            break;
          default:
            value += convertItemValue(item);
        }
      });
    } else if (['plate'].indexOf(name) !== -1) {
      const valueObj = page.plate?.value ?? {};
      const arr = [];
      Object.keys(valueObj).forEach((key) => {
        const item = valueObj[key];
        const obj = {
          key: `${page.id}.plate.value.${key}.value.image.value`,
          src: item?.value?.image?.value,
        };
        if (obj.src) {
          arr.push(obj);
        }
      });
      arr.forEach((item) => {
        value += `<figure class='image'>
                        <img key='${item.key}' src='${item.src}' type='plateImage'/>
                  </figure>`;
      });
    } else if (['header'].includes(name)) {
      const metadata = dpUtils[name].getMetadata({ options, page, dataPPT });
      const docdata = dpUtils[name].getDocdata(metadata) || {};
      if (docdata.value) {
        value += tagNameItemValue(docdata, 'h3');
      }
    } else if (['describe'].indexOf(name) !== -1) {
      docdata = {
        value: _.get(page, 'describe.body-top.value.text.value'),
        key: 'describe.body-top.value.text.value',
      };
      if (docdata.value) {
        if (docdata.value) {
          value += convertItemValue(docdata);
        }
      }
    } else if (['charts'].indexOf(name) !== -1) {
      const metadata = dpUtils[name].getMetadata({ options, page, dataPPT });
      if (metadata && page) {
        docdata = dpUtils[name].getDocdata(metadata, page) || [];
        docdata.forEach((data) => {
          data.forEach((item) => {
            const isTable =
              item.value?.table?.value !== undefined
                ? item.value.table.value
                : true;
            switch (item.itemkey) {
              case 'charts': {
                const isNormalTable = item.value.table.value;
                const figure = document.createElement('figure');
                figure.setAttribute('class', 'table');
                const table = document.createElement('table');
                const tbody = document.createElement('tbody');
                table.setAttribute('table', isTable.toString());
                figure.append(table);
                table.append(tbody);
                const series = item.value.series;
                const title = item.value.title?.value;
                if (title) {
                  const figcaption = document.createElement('figcaption');
                  figure.append(figcaption);
                  figcaption.innerText = title;
                }
                if (!isTable) {
                  if (series) {
                    for (let key in series) {
                      const item = series[key];
                      if (item.name) item.data.unshift(item.name);
                    }
                  }
                }
                const xAxis = item.value.xAxis;
                const trLength = _.keys(series).length + 1;
                const tdLength =
                  series['0']?.data?.length ?? xAxis?.data?.length;
                for (let i = 0; i < trLength; i++) {
                  const tr = document.createElement('tr');
                  for (let j = 0; j < tdLength; j++) {
                    const td = document.createElement('td');
                    if (!isNormalTable && i === 0 && j === 0) {
                      td.classList.add('import');
                    }
                    if (i === 0) {
                      const text = xAxis.data[j - (isTable ? 0 : 1)];
                      if (text) td.innerText = text.toString();
                    } else {
                      const text = _.values(series)[i - 1]?.data[j];
                      if (text) td.innerText = text.toString();
                    }
                    tr.append(td);
                  }
                  tbody.append(tr);
                }
                table.setAttribute('key', item.key);
                value += figure.outerHTML;
              }
                break;
            }
          });
        });
      }
    } else if (dpUtils[name].getDocdata) {
      const metadata = dpUtils[name].getMetadata({ options, page, dataPPT });
      if (metadata) {
        docdata = dpUtils[name].getDocdata(metadata);
        if (docdata.value) {
          value += convertItemValue(docdata);
        }
      }
    }
  });
  return value;
}

export const needBr = (index, type, hasCatalogue) => {
  let needBr = true;
  if (index === 0) {
    needBr = false;
  } else if (index === 1) {
    needBr = false;
  } else if (index === 2) {
    needBr = !hasCatalogue;
  }
  return needBr;
};
