import { v4 } from 'uuid'
import { getDataByPage } from '@/pages/Edit/common/node/nodemark'
import { transformNodeValue } from './transformNodeValue'
import data from './testData'
import { decodePage } from '@/pages/Edit/common'

export const generateHTMLPage = (page, { theme, chapterNum, mainNum } = {}) => {
    const data = getDataByPage(page, theme)
    const htmlValue = transformNodeValue({
        pageType: page.type,
        data,
        chapterNum,
        mainNum,
    })

    return {
        id: v4(),
        type: 'html',
        value: htmlValue,
    }
}

export const createHTMLPage = () => {
    return {
        id: v4(),
        type: 'html',
        value: '',
        placeholder: '输入内容或',
    }
}

export const generateHTMLPageByPPTPage = (pptPageList, theme) => {
    let arr = []
    for (let i = 0; i < pptPageList.length; i++) {
        const page = pptPageList[i]
        // const htmlPage = generateHTMLPage(page, theme)
        // arr.push(htmlPage)
        arr.push(page)
    }

    return arr
}

// let chNum = 0
// let mainNum = 0
// const newData = data.map((page) => decodePage(page)).map((v) => {
//     if (v.type === 'transition') {
//         chNum++
//         mainNum = 0
//     }
//     if (v.type === 'main') {
//         mainNum++
//     }
//     return generateHTMLPage(v, { chapterNum: chNum, mainNum })
// })
// console.log('newData', newData)
