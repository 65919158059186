import { createSlice } from '@reduxjs/toolkit';

import initState from '@/pages/Edit/redux/initState';


export default createSlice({
  name: 'hintStep',
  initialState: initState.hintStep,
  reducers: {
    set(state, { payload }) {
      return payload;
    },
    add(state, {payload}) {
      state = state + 1
      return state
    }
  },
});
