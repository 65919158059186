import { isArray } from "lodash"

/* eslint-disable guard-for-in */
const ELEMENT_TAGS = {
    A: (el) => ({ type: 'link', url: el && el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'quote' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-two' }),
    H3: () => ({ type: 'heading-three' }),
    H4: () => ({ type: 'heading-four' }),
    H5: () => ({ type: 'heading-five' }),
    H6: () => ({ type: 'heading-six' }),
    IMG: (el) => ({ type: 'image', url: el && el.getAttribute('src'), children: [{ text: '' }] }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'bulleted-list' }),
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
}

const SERIALIZE_TEXT_TAGS = {
    CODE: 'code',
    DEL: 'strikethrough',
    I: 'italic',
    S: 'strikethrough',
    STRONG: 'bold',
    U: 'underline',
}

export const serialize = (node) => {
    if (Array.isArray(node)) {
        return node.map((n) => serialize(n)).join('')
    }

    if (node.text) {
        let text = node.text
        // 根据TEXT_TAGS逐个检查属性，并将相应的标签包围在文本周围
        for (let tag in TEXT_TAGS) {
            const hasTag = node[SERIALIZE_TEXT_TAGS[tag]]
            if (hasTag) {
                switch (tag) {
                    case 'CODE':
                        text = `<code>${text}</code>`
                        break
                    case 'DEL':
                        text = `<del>${text}</del>`
                        break
                    case 'EM':
                        text = `<em>${text}</em>`
                        break
                    case 'I':
                        text = `<i>${text}</i>`
                        break
                    case 'S':
                        text = `<s>${text}</s>`
                        break
                    case 'STRONG':
                        text = `<strong>${text}</strong>`
                        break
                    case 'U':
                        text = `<u>${text}</u>`
                        break
                    default:
                        break
                }
            }
        }
        return text
    }
    const children = isArray(node.children) ? node.children.map((n) => serialize(n)).join('') : ''

    for (let tag in ELEMENT_TAGS) {
        if (node.type === ELEMENT_TAGS[tag]().type) {
            switch (tag) {
                case 'A':
                    return `<a href="${node.url}">${children}</a>`
                case 'BLOCKQUOTE':
                    return `<blockquote>${children}</blockquote>`
                case 'H1':
                    return `<h1>${children}</h1>`
                case 'H2':
                    return `<h2>${children}</h2>`
                case 'H3':
                    return `<h3>${children}</h3>`
                case 'H4':
                    return `<h4>${children}</h4>`
                case 'H5':
                    return `<h5>${children}</h5>`
                case 'H6':
                    return `<h6>${children}</h6>`
                case 'IMG':
                    return `<img src="${node.url}" alt=""/>`
                case 'LI':
                    return `<li>${children}</li>`
                case 'OL':
                    return `<ol>${children}</ol>`
                case 'P':
                    return `<p>${children}</p>`
                case 'PRE':
                    return `<pre><code>${children}</code></pre>`
                case 'UL':
                    return `<ul>${children}</ul>`
                default:
                    return children
            }
        }
    }

    return children
}
