import { processNormalOrIconType, processBigNumberOrTimeType, processPlateType } from './pointTypeHandlers'

export const processMainPage = (dataItem, pointType) => {
    const handlers = {
        normal: processNormalOrIconType,
        icon: processNormalOrIconType,
        bigNumber: processBigNumberOrTimeType,
        time: processBigNumberOrTimeType,
        plate: processPlateType,
    }

    const handler = handlers[pointType] || processNormalOrIconType
    return handler(dataItem)
}
