import { createSlice } from '@reduxjs/toolkit';
import initState from '../initState';

export default createSlice({
  name: 'resizeText',
  initialState: initState.resizeText,
  reducers: {
    setWidth(state, { payload }) {
       state.width = payload;
    },
  },
});
