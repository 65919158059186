import { createSlice } from '@reduxjs/toolkit'
import produce from 'immer'
import createImageNode from '@/pages/Edit/common/node/createImageNode'
import createTextNode from '@/pages/Edit/common/node/createTextNode'
import { getTextWidth } from '@/pages/Edit/common/node/utils'

export const proccessFooterPageList = (pageList, footer) => {
    return produce(pageList, (newList) => {
        newList.forEach((page, index) => {
            const pageNumberConfig = {
                isVisible: footer.pageNumber.visible,
                createNodeFunc: () => {
                    const textContent = index < 10 ? `0${index + 1}` : index + 1
                    const pageNumberText = createTextNode(textContent)
                    pageNumberText.style.top = 425
                    pageNumberText.style.left = 770
                    pageNumberText.style.width = 50
                    pageNumberText.style.zIndex = 1000
                    pageNumberText.style.fontSize = 12
                    pageNumberText.fontType = 'pageNumber'
                    pageNumberText.footer = footer.pageNumber
                    pageNumberText.value = `<p><span style="color:#999999;font-size:12px;">${textContent}</span></p>`
                    return pageNumberText
                },
                updateNodeFunc: (node) => {
                    const textContent = index < 10 ? `0${index + 1}` : index + 1
                    node.footer = footer.pageNumber
                    node.value = `<p><span style="color:#999999;font-size:12px;">${textContent}</span></p>`
                },
                type: 'pageNumber',
            }

            const textConfig = {
                isVisible: footer.text.visible,
                createNodeFunc: () => {
                    const textContent = footer.text.value
                    const textNode = createTextNode(textContent)
                    const textWidth = getTextWidth(textContent, 10)
                    textNode.style.top = 425
                    textNode.style.width = 375
                    textNode.style.fontSize = 10
                    textNode.style.left = 400 - textWidth / 2
                    textNode.style.zIndex = 1000
                    textNode.footer = footer.text
                    textNode.value = `<p><span style="color:#999999;font-size:10px;">${textContent}</span></p>`
                    textNode.fontType = 'text'

                    return textNode
                },
                updateNodeFunc: (node) => {
                    const textContent = footer.text.value
                    const textWidth = getTextWidth(textContent, 10)
                    node.style.left = 400 - textWidth / 2
                    node.value = `<p><span style="color:#999999;font-size:10px;">${textContent}</span></p>`
                    node.footer = footer.text
                },
                type: 'text',
            }

            const logoConfig = {
                isVisible: footer.logo.visible,
                createNodeFunc: () => {
                    // const imageNode = createImageNode(footer.logo.value);
                    // imageNode.style.zIndex = 1000
                    const imgValue = footer.logo.value
                    const logoSize = footer.logo.size
                    const originWidth = footer.logo.originWidth
                    const originHeight = footer.logo.originHeight
                    const imageNode = createImageNode(imgValue)
                    imageNode.style.zIndex = 1000
                    imageNode.originWidth = originWidth
                    imageNode.originHeight = originHeight
                    imageNode.footer = footer.logo

                    if (originWidth < originHeight) {
                        const radio = logoSize / originWidth
                        imageNode.style.width = logoSize
                        imageNode.style.height = originHeight * radio
                    } else {
                        const radio = logoSize / originHeight
                        imageNode.style.height = logoSize
                        imageNode.style.width = originWidth * radio
                    }

                    imageNode.value = imgValue
                    imageNode.style.top = 10
                    imageNode.style.left = 10
                    imageNode.fontType = 'logo'
                    return imageNode
                },
                updateNodeFunc: (imageNode) => {
                    const logoSize = footer.logo.size
                    const originWidth = footer.logo.originWidth
                    const originHeight = footer.logo.originHeight
                    const position = footer.logo.position
                    imageNode.originWidth = originWidth
                    imageNode.originHeight = originHeight

                    if (originWidth < originHeight) {
                        const radio = logoSize / originWidth
                        imageNode.style.width = logoSize
                        imageNode.style.height = originHeight * radio
                    } else {
                        const radio = logoSize / originHeight
                        imageNode.style.height = logoSize
                        imageNode.style.width = originWidth * radio
                    }
                    imageNode.value = footer.logo.value

                    // 处理位置
                    if (position === 'leftTop') {
                        imageNode.style.top = 10
                        imageNode.style.left = 10
                    } else if (position === 'rightTop') {
                        imageNode.style.left = 800 - imageNode.style.width - 10
                        imageNode.style.top = 10
                    } else if (position === 'leftBottom') {
                        imageNode.style.left = 10
                        imageNode.style.top = 450 - imageNode.style.height - 10
                    }
                    imageNode.footer = footer.logo
                },
                type: 'logo',
            }

            updateOrRemoveNode(page, pageNumberConfig)
            updateOrRemoveNode(page, textConfig)
            updateOrRemoveNode(page, logoConfig)
        })
    })
}

async function updateOrRemoveNode(page, nodeConfig) {
    const {
        isVisible, createNodeFunc, updateNodeFunc, type,
    } = nodeConfig

    // 查找已存在的节点ID
    const existingNodeId = Object.keys(page.nodes).find((key) => page.nodes[key]?.fontType === type)

    if (isVisible) {
        if (existingNodeId) {
            // 如果节点已存在，只更新node
            updateNodeFunc(page.nodes[existingNodeId])
            // page.nodes[existingNodeId].value = nodeConfig.value;
        } else {
            // 否则，创建新节点
            const newNode = createNodeFunc()
            page.nodes[newNode.id] = newNode
        }
    } else if (existingNodeId) {
    // 如果节点存在且不应该可见，则删除它
        delete page.nodes[existingNodeId]
    }
}

const initialState = {
    // 字体
    font: {
        group: '',
        font1: {},
        font2: {},
    },
    fontStyle: {
        title: {
            letterSpacing: 0,
            lineHeight: 0,
        },
        text: {
            letterSpacing: 0,
            lineHeight: 0,
        },
    },
    theme: null,
    loading: false,
    color: '',
    isSave: true, // 检测用户操作是否保存
    pptList: [], // ppt list数据
    customThemeColorList: [], // 用户保存的自定义主题
    footer: {
        pageNumber: {
            visible: false,
        },
        text: {
            visible: false,
            value: '©2023 Proprietary and Confidential. All Rights Reserved.',
        },
        logo: {
            visible: false,
            value: 'https://oss.jianzeppt.com/jz-ppt-img/logo1231322131.png',
            size: 30,
            originWidth: 216,
            originHeight: 48,
            position: 'leftTop',
        },
    },
}

// 自定义主题
export const appSlice = createSlice({
    name: 'customTheme',
    initialState,
    reducers: {
        setResetContentTheme(state) {
            state.font = {
                group: '',
                font1: {},
                font2: {},
            }
            state.theme = null
            state.color = ''
            state.isSave = true
            state.loading = false
            state.pptList = []
            state.footer = {
                pageNumber: {
                    visible: false,
                },
                text: {
                    visible: false,
                    value: '©2023 Proprietary and Confidential. All Rights Reserved.',
                },
                logo: {
                    visible: false,
                    value: 'https://oss.jianzeppt.com/jz-ppt-img/logo1231322131.png',
                    size: 40,
                    originWidth: 216,
                    originHeight: 48,
                    position: 'leftTop',
                },
            }
        },
        setFonts(state, action) {
            const font = action.payload
            state.font = font
            state.isSave = false
        },
        setSave(state, action) {
            const value = action.payload
            state.isSave = value
        },
        setFontStyle(state, action) {
            if (action.payload?.text) {
                state.fontStyle = action.payload
            } else {
                const { title, type, value } = action.payload
                let temp = state.fontStyle
                if (title === '字间距') {
                    if (type === 'title') {
                        temp.title.letterSpacing = value
                    } else {
                        temp.text.letterSpacing = value
                    }
                } else if (type === 'text') {
                    temp.text.lineHeight = value
                } else {
                    temp.title.lineHeight = value
                }
                state.fontStyle = temp
            }
        },
        setPPTList(state, action) {
            const value = action.payload
            state.pptList = value
            // 如果value中存在已设置的footer，则处理一下
            state.pptList.forEach((page) => {
                const keys = Object.keys(page.nodes).filter((key) => page.nodes[key].fontType && page.nodes[key].footer)
                if (keys) {
                    keys.forEach((key) => {
                        const node = page.nodes[key]
                        if (node) {
                            const { fontType, footer } = node
                            state.footer[fontType] = footer
                        }
                    })
                }
            })
        },
        setPPTColor(state, action) {
            const value = action.payload
            state.color = value
            state.isSave = false
        },
        setLoading(state, action) {
            const value = action.payload
            state.loading = value
        },
        setPPTTheme(state, action) {
            const value = action.payload
            state.theme = value
        },
        setFooter(state, action) {
            const { type, value } = action.payload
            // 更新value
            state.footer[type] = value
            state.pptList = proccessFooterPageList(state.pptList, state.footer)
        },
        setCustomThemeColorList(state, action) {
            const value = action.payload
            state.customThemeColorList = value
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setFonts, setSave, setPPTList, setPPTColor, setLoading, setResetContentTheme, setPPTTheme, setFooter, setFontStyle, setCustomThemeColorList,
} = appSlice.actions
export default appSlice.reducer
