import modelExtend from 'dva-model-extend';
import { model } from '@/utils/model';
import { getTemplateById } from '@/service/services';
import _ from 'lodash';
import * as pptx from '../utils/pptx';
import { getDvaApp } from 'umi';

const map = new Map();
export default modelExtend(model, {
  namespace: 'pptxTemplate',
  state: {
    initial: false,
    loading: false,
    error: null,
    byId: {},
    allIds: [],
  },
  subscriptions: {},
  effects: {
    *fetchTemplateById({ payload }, { call, put }) {
      yield call(fetchTemplateById, payload);
      yield put({ type: 'stateByAny' });
    },
  },
  reducers: {
    stateByAny(state) {
      return { ...state };
    },
    parseById(state, { payload }) {
      cacheTemplate(state, payload);
      return _.assign({}, state);
    },
  },
});

function cacheTemplate(state, data) {
  if (state.byId[data.id]) {
    state.byId[data.id] = data;
    state.allIds = [...state.allIds];
  } else {
    state.byId[data.id] = data;
    state.allIds = [...state.allIds, data.id];
  }
}

export const fetchTemplateById = async (id) => {
  const app = getDvaApp();
  const store = app._store;
  const { getState, dispatch } = store;
  const { pptxTemplate } = getState();
  const template = pptxTemplate.byId[id];
  if (template) {
    return template;
  } else {
    const flag = map.get(id);
    if (flag) return flag;
    const promise = await getTemplateById({ id });
    map.set(id, promise);
    const d = await promise;
    let item = d?.data ? JSON.parse(d.data) : undefined;
    if (!item) {
      Promise.reject().catch(() => void 0);
      return;
    }
    item.id = d.id;
    item.name = d.name;
    item.type = d.type;
    item.info = d.description;
    if (item.component) {
      item.component = d.component;
    }
    item.createTime = d.createTime;
    item.templateOrigin = d;
    const templateBase = _.get(pptx.dpUtils, `${item.component}.templateBase`);
    const data = templateBase ? pptx.buildOptions(templateBase(), item) : item;
    cacheTemplate(pptxTemplate, data);
    map.set(id, undefined);
  }
};

export const transform = (templateOriginal) => {
  const d = templateOriginal;
  const item = d.data ? JSON.parse(d.data) : undefined;
  if (!item) Promise.reject().catch(() => void 0);
  item.id = d.id;
  item.name = d.name;
  item.type = d.type;
  item.info = d.description;
  if (item.component) {
    item.component = d.component;
  }
  item.createTime = d.createTime;
  item.templateOrigin = d;
  const templateBase = _.get(pptx.dpUtils, `${item.component}.templateBase`);
  return templateBase ? pptx.buildOptions(templateBase(), item) : item;
};

export const transformAndCache = (templateOriginal) => {
  const app = getDvaApp();
  const store = app._store;
  const { getState } = store;
  const { pptxTemplate } = getState();
  const template = transform(templateOriginal);
  cacheTemplate(pptxTemplate, template);
  return template;
};
