import modelExtend from 'dva-model-extend';
import { model } from '@/utils/model';
import { deletePageById, getPagesByPptxId, getPptxById, requestPPTPageSave } from '@/service/services';
import { decodePage, encodePage } from '@/pages/Edit/common';
import { getDvaApp } from '@@/plugin-dva/exports';

export default modelExtend(model, {
  namespace: 'edit',
  state: {
    current: 0,
    ppt: {},
    pages: [],
    focusNodeId: undefined,
  },
  subscriptions: {},
  effects: {
    * fetchPPTThunk({ payload }, { call, put, select }) {
      const { id } = payload;
      const data = yield call(getPptxById, { id: id });
      const edit = yield select((state) => state.edit);
      edit.ppt = data;
      yield put({ type: 'stateAny' });
      return data;
    },
    * fetchPagesThunk({ payload }, { call, put, select }) {
      const { pptId } = payload;
      const data = yield call(getPagesByPptxId, { pptId, limit: 199, page: 1 });
      const edit = yield select((state) => state.edit);
      edit.pages = data.dataList.map((item) => decodePage(item));
      yield put({ type: 'stateAny' });
      return data;
    },
    * createPageThunk({ payload }, { call, put, select }) {
      const { pptId } = payload;
      const body = encodePage({ pptId, nodeList: [] });
      const data = yield call(requestPPTPageSave, body);
      const edit = yield select((state) => state.edit);
      edit.pages.push(decodePage(data));
      edit.pages = [...edit.pages];
      yield put({ type: 'stateAny' });
      return data;
    },
    * deletePageThunk({ payload }, { call, put, select }) {
      const { id } = payload;
      const data = yield call(deletePageById, { id });
      const edit = yield select((state) => state.edit);
      const { pages } = edit;
      const index = pages.findIndex((item) => item.id === id);
      if (index !== -1) {
        pages.splice(index, 1);
        edit.pages = [...pages];
      }
      yield put({ type: 'stateAny' });
      return data;
    },
    * updatePageThunk({ payload }, { call, put, select }) {
      const { id } = payload;
      const data = yield call(updatePage, id);
      yield put({ type: 'stateAny' });
      return data;
    },


  },
  reducers: {
    stateAny(state, { payload = {} }) {
      return { ...state, ...payload };
    },
  },
});


export  const updatePage = async (id) => {
  const app = getDvaApp();
  const store = app._store;
  const { getState, dispatch } = store;
  const { edit } = getState();
  const page = edit.pages.find((page) => page.id === id);
  if (page) {
    const body = encodePage(page);
    return requestPPTPageSave(body);
  }
};
