import produce from 'immer'
import { getParam } from './log'
import { compressAndResizeImage } from './image'
// import UAParser from 'ua-parser-js'

export const isNewLoadingPage = () => {
    return getParam('newTitleText')
}

function isImageUrl(url) {
    return url.match(/\.(jpeg|jpg|gif|png|bmp|svg|webp)$/) !== null
}

function processSvg(svgStr) {
    const parser = new DOMParser()
    const serializer = new XMLSerializer()
    const appendUrl = '?x-oss-process=image/resize,w_700/quality,q_80'

    // Parse the SVG string to a Document
    const doc = parser.parseFromString(svgStr, 'text/html')

    // Get all the image elements
    const images = doc.getElementsByTagName('image')

    // Iterate over each image element and update xlink:href attribute
    for (let i = 0; i < images.length; i++) {
        const image = images[i]
        const href = image.getAttribute('xlink:href')
        if (!href.includes('x-oss-process') && isImageUrl(href)) {
            image.setAttribute('xlink:href', href + appendUrl)
        }
    }

    // Serialize and return the updated SVG string
    return serializer.serializeToString(doc)
}

async function processSvgImageToBase64(svgStr) {
    const parser = new DOMParser()
    const serializer = new XMLSerializer()

    // Parse the SVG string to a Document
    const doc = parser.parseFromString(svgStr, 'text/html')

    // Get all the image elements
    const images = doc.getElementsByTagName('image')

    // Iterate over each image element and update xlink:href attribute
    for (let i = 0; i < images.length; i++) {
        const image = images[i]
        const href = image.getAttribute('xlink:href')
        if (!href.includes('x-oss-process') && isImageUrl(href)) {
            const base64 = await toBase64(href)
            image.setAttribute('xlink:href', base64)
        }
    }

    // Serialize and return the updated SVG string
    return serializer.serializeToString(doc)
}

const toBase64 = (url) => fetch(url)
    .then((response) => response.blob())
    .then((blob) => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }))

// 将ppt里面的图片数URL转换成Base64
export const transformImg = async (pageList) => {
    // First collect all promises for converting images to base64
    const p = [...pageList]
    for (let i = 0; i < p.length; i++) {
        const page = p[i]
        const nodes = page.nodes
        for (let key in nodes) {
            const node = nodes[key]
            if (node.type === 'image') {
                // console.log('old node.value', node.value)
                // node.value = await toBase64(node.value)
                // console.log('new node.value', node.value)
            } else if (node.type === 'svg') {
                node.value = await processSvgImageToBase64(node.value)
            }
        }
    }

    return p
}

// 将ppt里面的图片数据转换成更小的size
export const transformImgSize = (pageList, getNodes) => {
    const list = produce(pageList, (newPageList) => {
        newPageList.forEach((page, index) => {
            try {
                const nodes = getNodes ? getNodes(page) : page.nodes
                Object.keys(nodes).forEach((key) => {
                    const node = nodes[key]
                    if (node.type === 'image') {

                    } else if (node.type === 'svg') {
                        node.value = processSvg(node.value)
                    }
                })
            } catch (error) {
                console.log('error', error)
            }
        })
    })
    return list
}

export const transformPageImage = (page) => {
    if (!page || !page.nodes) {
        return page
    }
    const nodes = page.nodes
    if (page.backgroundImage) {
        page.backgroundImage = compressAndResizeImage(page.backgroundImage, { p: 50 })
    }

    Object.keys(nodes).forEach((key) => {
        const node = nodes[key]
        if (node.type === 'image') {
            // node.value = compressAndResizeImage(node.value, { p: 50 })
        } else if (node.type === 'svg') {
            // node.value = processSvg(node.value)
        }
    })
}

export const transformPageListImg = (pageList) => {
    const list = produce(pageList, (newPageList) => {
        newPageList.forEach((page, index) => {
            try {
                transformPageImage(page)
            } catch (error) {
                console.log('error', error)
            }
        })
    })
    return list
}

export const getJzImageUrl = (imgName) => {
    return `https://oss.jianzeppt.com/jz-ppt-img/${imgName}`
}

export const sleep = (timeout = 3000) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, timeout)
    })
}

// export const getBrowser = () => {
//   const ua = new UAParser()
//   return ua.getBrowser()
// }

export const copyText = (text) => {
    if (navigator.clipboard) {
    // clipboard api 复制
        navigator.clipboard.writeText(text)
    } else {
        let textarea = document.createElement('textarea')
        document.body.appendChild(textarea)
        // 隐藏此输入框
        textarea.style.position = 'fixed'
        textarea.style.clip = 'rect(0 0 0 0)'
        textarea.style.top = '10px'
        // 赋值
        textarea.value = text
        // 选中
        textarea.select()
        // 复制
        document.execCommand('copy', true)
        // 移除输入框
        document.body.removeChild(textarea)
    }
}

export const isStreamPPT = () => {
    return getParam('isStream')
}
