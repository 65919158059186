import getNodes from '@/pages/Edit/common/node/getNodes';


//Cover
export const getCoverData = (page, theme) => {
  const nodes = getNodes({ page, theme: theme });
  const nodeMark = page.nodeMark;
  return {
    title: nodes[nodeMark?.pageTitle?.[0]],
    des: nodes[nodeMark?.subtitle?.[0]],
    author: nodes[nodeMark?.author?.[0]],
    date: nodes[nodeMark?.date?.[0]],
    others: nodeMark?.describe?.map?.((id, index) => nodes[id]).filter((i) => !!i) || [],
  };
};
export const getCoverNodeList = (data) => {
  return [data.title, data.des, data.author, data.date].concat(data.others).filter((node) => !!node);
};
export const coverNodeType = {
  title: 'title',
  des: 'des',
  author: 'author',
  date: 'date',
  others: 'others',
};
export const getCoverNodeTypeText = (type) => {
  switch (type) {
    case coverNodeType.title: {
      return '标题';
    }
    case coverNodeType.des: {
      return '副标题';
    }
    case coverNodeType.author: {
      return '作者';
    }
    case coverNodeType.date: {
      return '日期';
    }

    case coverNodeType.others: {
      return '其他文本';
    }
  }
};
export const getCoverNodeTypeByNode = (page, theme, node) => {
  const data = getDataByPage(page, theme);
  switch (node.id) {
    case data.title?.id: {
      return coverNodeType.title;
    }
    case data.des?.id: {
      return coverNodeType.des;
    }
    case data.author?.id: {
      return coverNodeType.author;
    }
    case data.date?.id: {
      return coverNodeType.date;
    }
  }

  if (data.others.findIndex((item) => item.id === node.id) > -1) {
    return coverNodeType.others;
  }
};


//transition
export const transitionNodeType = {
  title: 'title',
  partNum: 'partNum',
  other: 'other',
};
export const getTransitionNodeTypeText = (type) => {
  switch (type) {
    case transitionNodeType.title: {
      return '标题';
    }
    case transitionNodeType.partNum: {
      return '序号';
    }
    case transitionNodeType.other: {
      return '其他文本';
    }
  }
};


export const getTransitionData = (page, theme) => {
  const nodes = getNodes({ page, theme: theme });
  const nodeMark = page.nodeMark;
  return {
    title: nodes[nodeMark?.pageTitle?.[0]],
    partNum: nodeMark?.partNum?.map((id) => nodes[id]),
    others: nodeMark?.describe?.map?.((id, index) => nodes[id]).filter((i) => !!i) || [],
  };
};
export const getTransitionNodeList = (data) => {
  return [].concat(data.partNum).concat([data.title]).concat(data.others).filter((node) => !!node);
};
export const getTransitionNodeTypeByNode = (page, theme, node) => {
  const data = getDataByPage(page, theme);
  switch (node.id) {
    case data.title?.id: {
      return transitionNodeType.title;
    }
  }
  if (data.partNum?.findIndex((item) => item.id === node.id) > -1) {
    return transitionNodeType.partNum;
  }
  if (data.others.findIndex((item) => item.id === node.id) > -1) {
    return transitionNodeType.other;
  }
};


// Catalog
export const getCatalogData = (page, theme) => {
  const nodes = getNodes({ page, theme: theme });
  const nodeMark = page.nodeMark;
  return {
    list: nodeMark.catalog?.map?.((item) => nodes[item.title?.id]).filter((node) => !!node),
    others: nodeMark?.describe?.map?.((id) => nodes[id]).filter((i) => !!i) || [],
  };
};
export const getCatalogNodeList = (data) => {
  return [data.list].concat(data.others).filter((node) => !!node);
};


// Main
export const getMainData = (page, theme) => {
  const nodes = getNodes({ page, theme: theme });
  const nodeMark = page.nodeMark;

  return {
    title: nodes[nodeMark?.pageTitle],
    main: nodes[nodeMark?.describe?.[0]],
    list: nodeMark?.point?.map?.((item) => {
      return {
        title: nodes[item?.title?.id],
        valueNodes: item?.valueNodes?.map?.((i) => nodes[i?.id]),
        plate: item?.plate?.map?.((id) => nodes[id]),
      };
    }),
    plate: nodeMark?.plate?.map?.((id) => nodes[id]),
    chart: nodeMark?.charts?.map?.((id) => nodes[id]),
    others: nodeMark?.describe?.slice(1, nodeMark?.describe.length).map?.((id) => nodes[id]).filter((i) => !!i) || [],
    pointFirst: nodeMark?.pointIndex === 0,
  };
};
export const getMainNodeList = (data) => {
  if (data.pointFirst) {
    return [data.title].concat(data.list.map((item) => [item?.title].concat(item?.valueNodes || [])).flat()).concat(data.plate).concat([data.main]).concat(data.plate).concat(data.chart).concat(data.others).filter((node) => !!node);
  } else {
    return [data.title, data.main].concat(data.list?.map?.((item) => [item?.title].concat(item?.valueNodes || [])).flat()).concat(data.plate).concat(data.chart).concat(data.others).filter((node) => !!node);
  }
};

// End
export const getEndData = (page, theme) => {
  const nodes = getNodes({ page, theme: theme });
  const nodeMark = page.nodeMark;
  return {
    title: nodes[nodeMark?.pageTitle?.[0]],
    others: nodeMark?.describe?.map?.((id) => nodes[id]).filter((i) => !!i) || [],
  };
};
export const getEndNodeList = (data) => {
  return [].concat([data.title]).concat([data.others]).filter((node) => !!node);
};

//-------------------
export const getDataByPage = (page, theme = {}) => {
  switch (page.type) {
    case 'cover': {
      return getCoverData(page, theme);
    }
    case 'transition': {
      return getTransitionData(page, theme);
    }
    case 'catalog': {
      return getCatalogData(page, theme);
    }
    case 'main': {
      return getMainData(page, theme);
    }
    case 'other': {
      return getMainData(page, theme);
    }
    case 'end': {
      return getEndData(page, theme);
    }

    default: {
      return getMainData(page, theme);
    }
  }
};
export const getNodeListByPage = (page, theme) => {
  const data = getDataByPage(page, theme);
  switch (page.type) {
    case 'cover': {
      return getCoverNodeList(data);
    }
    case 'transition': {
      return getTransitionNodeList(data);
    }
    case 'catalog': {
      return getCatalogNodeList(data);
    }
    case 'main': {
      return getMainNodeList(data);
    }
    case 'other': {
      return getMainNodeList(data);
    }
    case 'end': {
      return getEndNodeList(data);
    }
    default: {
      return getMainNodeList(data);
    }
  }

};
export const getIdList = (nodeList) => {
  return nodeList.map((node) => node.id);
};
export const getTypeByNode = (page, theme,node) => {
  switch (page.type) {
    case 'cover': {
      return getCoverNodeTypeByNode(page, theme, node);
    }
    case 'transition': {
      return getTransitionNodeTypeByNode(page, theme, node);
    }
    default: {
    }
  }
};


//用于审核
