import { getTextContentByHTML } from '@/utils/text'
import { serialize } from '../serialize'
import { createListItem } from './utils'

export const processCatalogPage = (dataItem, type) => {
    return serialize({
        type,
        children: [
            {
                type: 'heading-two',
                children: [{
                    text: '目录',
                }],
            },
            {
                type: 'numbered-list',
                children: dataItem.map((item) => createListItem(getTextContentByHTML(item.value))),
            },
        ],
    })
}
