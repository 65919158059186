// import c from './echart/zh-CN';
import pay from './pay/const';
import user from './user/const';
import templates from './templates/const';
import templatesDetail from './templateDetail/const';
import footer from './footer/const';
import workspace from './workspace/const';
import editContent from './edit-content/const';
import templateChange from './template-change/const';
import templateChangeMulti from './template-change-multi/const';
import echart from './echart/const';
import pptxIndex from './pptxIndex/const'
import errorModal from './components/ErrorModal/const';
import fileUpload from './components/FileUpload/const';
import imageModal from './components/ImageModal/const';
import personalCenter from './personalCenter/const';
import edit from './components/Edit/const';
import customUser from './customUser/const'




let obj = {
  MIAO_CHU: '秒出PPT',
  ZAI_XIAN_ZHING_NENG: '秒出PPT',
  CONG_XIN: '从心PPT',
  DES: '10秒做出PPT',
  BIG_TITLE: 'SecSlide',
  BIG_SLOGAN: 'Create a Slide in 10 Sec',
  START_EDITING: 'Start Editing',
  VIEW_TEMPLATE: 'View Templates',
  LOCAL_UPLOAD: 'LOCAL_UPLOAD',
  SECTION1: 'ChatGPT Generates Content with 1 Click',
  SUB_SECTION1: 'Input Content and Create Your Exclusive Slide in 10 Sec',
  SECTION2: 'Multi-Class Design Materials for Online Editing',
  SUB_SECTION2: '30K+ High-Quality and Commercially Available Templates',

  SECTION3: 'Collaborate Anytime and Anywhere',
  SUB_SECTION3: 'Share Links with 1 Click and Edit Together in Real-Time',

  SECTION4: 'Zero-Threshold Editor for Easy Design',
  SUB_SECTION4: 'Multiple Practical Functions for One-Stop Design',

  POINT1: 'Massive Free Templates',
  SUB_POINT1: '30K+ Free Design Templates for Easy Customization',

  POINT2: 'Exquisite Data Charts',
  SUB_POINT2: 'Create Beautiful Data Charts and Infographics',

  POINT3: 'One-Click Sharing',
  SUB_POINT3: 'Share Designs with Links, Invite Friends to Collaborate',

  POINT4: 'Drag and Drop Editing',
  SUB_POINT4: 'Use a Zero-Threshold Editor for Quick Design',

  END_TEXT1: 'Selected High-Quality Slide Templates',
  END_TEXT2: 'Use a Zero-Threshold Editor for Quick Design',
  MORE_BUTTON: 'More Templates',

  ABOUT_US: 'About Us',
  USER_AGREE: 'User Agreement',
  ANNOUNCE: 'Copyright Statement',

  SUCAI: 'Materials',
  MUBAN: 'Templates',
  CREATE_CONTENT: 'CREATE_CONTENT',
};


const getConst = (w) => {
  let o = {};
  Object.keys(w).forEach((k) => {
    o[k] = k;
  });
  return o;
};

export default Object.assign({},
  obj,
  pay,
  user,
  templates,
  footer,
  templatesDetail,
  workspace,
  editContent,
  templateChange,
  templateChangeMulti,
  echart,
  pptxIndex,
  errorModal,
  fileUpload,
  imageModal,
  personalCenter,
  edit,
  customUser
);
