import axios from 'axios'
import store from 'store'
import { history, getDvaApp } from 'umi'
import { message } from 'antd'
import ErrorCode, { errorCodeSome } from '@/common/ErrorCode'
import { getParam } from './log'
import { isAeTemplate } from './ae'

const HTTP_STATUS = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    CLIENT_ERROR: 400,
    AUTHENTICATE: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    SERVER_ERROR: 500,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
}
let fetchAnonymousUserF = { f: Promise.resolve() }
let f = false

const getPPTType = () => {
    // 先从url中获取判断
    const pathname = window.location.pathname
    if (pathname === '/pptx/prezi') {
        return 'prezi'
    }
    if (pathname === ('/pptx/')) {
        return 'ppt'
    }
    if (pathname === ('/pptx/mg')) {
        return 'mg'
    }
    if (pathname === ('/pptx/video')) {
        return 'video'
    }
    // 再从query中判断
    const pptType = getParam('pptType')
    if (pptType === 'animation') {
        return 'mg'
    }
    if (pptType === 'video') {
        return 'video'
    }
    if (pptType === 'hugescreen') {
        return 'prezi'
    }

    // 再从window中判断
    if (isAeTemplate()) {
        return 'video'
    }
    if (window.preziTemplate) {
        return 'prezi'
    }
    if (window.mgTemplate) {
        return 'mg'
    }
    return 'ppt'
}

function request(url, data = {}, method = 'GET', cancelToken, onUploadProgress) {
    const user = store.get('user') || {}
    return new Promise(async (resolve, reject) => {
        try {
            if (!user.id && !f) {
                f = true
                const app = getDvaApp?.()
                fetchAnonymousUserF.f = app._store.dispatch({ type: 'user/fetchAnonymousUser' }).finally(() => {
                    f = false
                })
            }
        } catch (e) {

        }
        await fetchAnonymousUserF.f
        const user1 = store.get('user') || {}
        const pptType = getPPTType()
        let axiosJson = {
            url,
            method,
            cancelToken,
            onUploadProgress,
            headers: {
                'User-Id': user1.id || 0,
                'User-Token': user1.token || user1.accessToken || 'QQ',
                'Content-Type': 'application/json; charset=UTF-8',
                'WEB-TYPE': pptType,
            },
        }
        if (method === 'GET') {
            axiosJson.params = data
        } else {
            axiosJson.data = data
        }
        axios(axiosJson).then((res) => {
            // 升级
            try {
                const app = getDvaApp()
                if (res.data.code !== 0 && ErrorCode.find((code) => code === res.data.code)) {
                    app._store.dispatch({ type: 'user/stateByAny', payload: { errorCode: res.data.code } })
                }
            } catch (e) {

            }
            let errMsg = !navigator.onLine ? '网络连接异常，请检查您的网络连接后重试！' : (res?.data?.msg || '系统繁忙，请稍后重试')
            if (res.status === HTTP_STATUS.NOT_FOUND) {
                message.error('请求资源不存在')
                reject('请求资源不存在')
            } else if (res.status === HTTP_STATUS.BAD_GATEWAY) {
                message.error('系统繁忙，请稍后重试')
                reject('服务端出现了问题')
            } else if (res.status === HTTP_STATUS.FORBIDDEN) {
                message.error('没有权限访问')
                reject('没有权限访问')
            } else if (res.status === HTTP_STATUS.AUTHENTICATE) {
                // pageToLogin()
                message.error('需要鉴权')
                reject('需要鉴权')
            } else if (res.status === HTTP_STATUS.CLIENT_ERROR) {
                // 请求参数异常
                console.log('参数异常')
            } else if (res.status === HTTP_STATUS.SUCCESS) {
                if (res.data.code === 0 || res.data.errno === 0) {
                    resolve(res.data.data)
                } else if (res.data.code === 1001) {
                    try {
                        const app = getDvaApp?.()
                        app._store.dispatch({ type: 'user/fetchAnonymousUser' })
                    } catch (e) {

                    }
                } else if (res.data.code === 1009) {
                    if (user1) {
                        store.remove('user')
                        history.push(`/pptx/login?redirectUrl=${window.location.href}`)
                    } else {
                        history.push(`/pptx/login?redirectUrl=${window.location.href}`)
                    }
                    resolve(res.data.data)
                } else {
                    // if (res.data.code && res.data.code !== 1011 && !errorCodeSome(res.data.code)) {
                    //   message.error(errMsg)
                    // }
                    reject(res.data)
                }
            }
        }).catch((err) => {
            let errMsg = !navigator.onLine ? '网络连接异常，请检查您的网络连接后重试！' : '系统繁忙，请稍后重试'
            if (!cancelToken) {
                if (!err?.message?.includes('status code 400')) {
                    message.error(errMsg)
                }
            }
            reject(err)
        })
    })
}

request.get = (url, data, ...arr) => {
    return request(url, data, 'GET', ...arr)
}

request.post = (url, data, ...arr) => {
    return request(url, data, 'POST', ...arr)
}

request.postFile = (url, data) => {
    const user = store.get('user') || {}
    return axios({
        method: 'post',
        url,
        data,
        headers: {
            'User-Id': user.id,
            'User-Token': user.token,
        },
    })
}

export default request
