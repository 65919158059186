export default {
  "EDIT_CONTENT_1": "My Design",
  "EDIT_CONTENT_2": "Write Content",
  "EDIT_CONTENT_3": "Modify Design",
  "EDIT_CONTENT_4": "Go Download",
  "EDIT_CONTENT_5": "Next Step",
  "EDIT_CONTENT_6": "Previous Step",
  "EDIT_CONTENT_7": "List Navigation",
  "EDIT_CONTENT_8": "Original Template",
  "EDIT_CONTENT_9": "Enter the PPT theme content you want to write",
  "EDIT_CONTENT_10": "Regenerate",
  "EDIT_CONTENT_11": "Switch Template",
  "EDIT_CONTENT_12": "Download Original Template",
  "EDIT_CONTENT_13": "All Changes Saved",
  "EDIT_CONTENT_14": "Upload Word",
  "EDIT_CONTENT_15": "Smart Layout",
  "EDIT_CONTENT_16": "Modify Details",
  "EDIT_CONTENT_17": "Cover Page",
  "EDIT_CONTENT_18": "Table of Contents Page",
  "EDIT_CONTENT_19": "Chapter Page",
  "EDIT_CONTENT_20": "Content Page",
  "EDIT_CONTENT_21": "End Page",
  "EDIT_CONTENT_22": "Add Single Page",
  "EDIT_CONTENT_23": "Text",
  "EDIT_CONTENT_24": "Shape",
  "EDIT_CONTENT_25": "Image",
  "EDIT_CONTENT_26": "Chart",
  "EDIT_CONTENT_27": "Canvas",
  "EDIT_CONTENT_28": "Animation",
  "EDIT_CONTENT_29": "Replace Image",
  "EDIT_CONTENT_30": "Overlay",
  "EDIT_CONTENT_31": "Color",
  "EDIT_CONTENT_32": "Text Format",
  "EDIT_CONTENT_33": "Text Adjustment",
  "EDIT_CONTENT_34": "Default Color",
  "EDIT_CONTENT_35": "Recommended Color",
  "EDIT_CONTENT_36": "Recently Used",
  "EDIT_CONTENT_37": "More Colors",
  "EDIT_CONTENT_38": "Slide Transition",
  "EDIT_CONTENT_39": "Material Animation",
  "EDIT_CONTENT_40": "Apply to All Pages",
  "EDIT_CONTENT_41": "Add Animation",
  "EDIT_CONTENT_42": "Fill",
  "EDIT_CONTENT_43": "Outline",
  "EDIT_CONTENT_44": "Opacity",
  "EDIT_CONTENT_45": "Outline Color",
  "EDIT_CONTENT_46": "No Effect",
  "EDIT_CONTENT_47": "Fade In Fade Out",
  "EDIT_CONTENT_48": "Enter from Bottom, Exit from Top",
  "EDIT_CONTENT_49": "Enter from Left, Exit from Right",
  "EDIT_CONTENT_50": "Enter from Top, Exit from Bottom",
  "EDIT_CONTENT_51": "Enter from Right, Exit from Left",
  "EDIT_CONTENT_52": "Preview All",
  "EDIT_CONTENT_53": "On Click",
  "EDIT_CONTENT_54": "With Previous",
  "EDIT_CONTENT_55": "After Previous",
  "EDIT_CONTENT_56": "Collaborative Sharing",
  "EDIT_CONTENT_57": "Presentation",
  "EDIT_CONTENT_58": "Select All",
  "EDIT_CONTENT_59": "Download Options",
  "EDIT_CONTENT_60": "File Type",
  "EDIT_CONTENT_61": "Select Page",
  "EDIT_CONTENT_62": "Download Now",
  "EDIT_CONTENT_63": "All Pages",
  "EDIT_CONTENT_64": "Every Page (Page {res})",
  "EDIT_CONTENT_65": "Current Page (Page {res})",
  "EDIT_CONTENT_66": "First Page",
  "EDIT_CONTENT_67": "Generate Multiple Points Content",
  "EDIT_CONTENT_68": "Generate Image and Text Content",
  "EDIT_CONTENT_69": "Enter Title to Generate Content",
  "EDIT_CONTENT_70": "Custom Content",
  "EDIT_CONTENT_71": "Download",
  "EDIT_CONTENT_72": "Upload Content",
  "EDIT_CONTENT_73": "Workspace",
  "EDIT_CONTENT_74": "Return to Content Editing",
  "EDIT_CONTENT_75": "AI Assistant Mode",
  "EDIT_CONTENT_76": "Make Slides like Writing Word",
  "EDIT_CONTENT_77": "Slides Editing Mode",
  "EDIT_CONTENT_78": "Slides PowerPoint Layout",
  "EDIT_CONTENT_79": "Custom Theme Color and Font",
  "EDIT_CONTENT_80": "Change Template, Keep Content",
  "EDIT_CONTENT_81": "Analyze Theme Style, Mix and Match",
  "EDIT_CONTENT_82": "Summon AI Assistant",
  "EDIT_CONTENT_83": "AI Rewrite",
  "EDIT_CONTENT_84": "AI Add an Image",
  "EDIT_CONTENT_85": "Manual Adjustment",
  "EDIT_CONTENT_86": "Title",
  "EDIT_CONTENT_87": "Subtitle",
  "EDIT_CONTENT_88": "Presenter",
  "EDIT_CONTENT_89": "Presentation Time",
  "EDIT_CONTENT_90": "Chapter Number",
  "EDIT_CONTENT_91": "Text",
  "EDIT_CONTENT_92": "Add Image",
  "EDIT_CONTENT_93": "Image",
  "EDIT_CONTENT_94": "Add Chart",
  "EDIT_CONTENT_95": "Chart",
  "EDIT_CONTENT_96": "Add Table",
  "EDIT_CONTENT_97": "Table",
  "EDIT_CONTENT_98": "Hi, I'm the Instant Assistant. What would you like to do with the content?",
  "EDIT_CONTENT_99": "Enter the content you want to change",
  "EDIT_CONTENT_100": "You can say to me like this",
  "EDIT_CONTENT_101": "Add a matching image",
  "EDIT_CONTENT_102": "Delete the first point",
  "EDIT_CONTENT_103": "Change the title to 'Work Summary'",
  "EDIT_CONTENT_104": "Help me simplify the description of the first point",
  "EDIT_CONTENT_105": "Copy Image",
  "EDIT_CONTENT_106": "Download Image",
  "EDIT_CONTENT_107": "Delete Image",
  "EDIT_CONTENT_108": "Copy Chart",
  "EDIT_CONTENT_109": "Delete Chart",
  "EDIT_CONTENT_110": "Summon Assistant",
  "EDIT_CONTENT_111": "Close Assistant",
  "EDIT_CONTENT_112": "Add Image and Text",
  "EDIT_CONTENT_113": "Clear Content",
  "EDIT_CONTENT_114": "Copy Current Page",
  "EDIT_CONTENT_115": "Delete This Page",
  "EDIT_CONTENT_116": "Main Text Points",
  "EDIT_CONTENT_117": "Character Introduction",
  "EDIT_CONTENT_118": "Timeline",
  "EDIT_CONTENT_119": "Number Display",
  "EDIT_CONTENT_120": "Icon Display",
  "EDIT_CONTENT_121": "Add an Item",
  "EDIT_CONTENT_122": "Description",
  "EDIT_CONTENT_123": "Delete All Points",
  EDIT_CONTENT_124: 'Regenerate key point descriptions based on{res}',
  EDIT_CONTENT_125: 'Break down {res} into 4 key points',
  EDIT_CONTENT_126: 'Break down {res} into 3 key points',
  EDIT_CONTENT_127: 'Simplify the content of {res}',
  EDIT_CONTENT_128: 'Regenerate graphic and text content based on {res}',
  EDIT_CONTENT_129: 'Regenerate key point content based on {res}',
  EDIT_CONTENT_130: 'Generate graphic and text content based on {res}',
  EDIT_CONTENT_131: 'Generate key points based on {res}',
  EDIT_CONTENT_132: 'Smart detection complete!',
  EDIT_CONTENT_133: '{res}',
  EDIT_CONTENT_134: 'Slides editing mode',
  EDIT_CONTENT_135: 'Issues and Help',
  EDIT_CONTENT_136: 'FAQs',
  EDIT_CONTENT_137: 'How to Edit PPT Content?',
  EDIT_CONTENT_138: 'Method 1:',
  EDIT_CONTENT_139: 'Method 2:',
  EDIT_CONTENT_140: 'In the content editing area, change your text and the corresponding PPT will be updated',
  EDIT_CONTENT_141: 'In the content area, summon the assistant to add images, charts, etc.',
  EDIT_CONTENT_142: 'In the design area, select images, tables, etc. to add',
  EDIT_CONTENT_143: 'Between content area pages, click the add button to add a page; click the delete button at the top-right to remove the page, and the PPT will update accordingly',
  EDIT_CONTENT_144: 'In the design area, click the top-right delete button to remove the page, and the PPT will update accordingly',
  EDIT_CONTENT_145: 'How to Add Images, Charts?',
  EDIT_CONTENT_146: 'How to Add/Delete PPT Pages?',
  EDIT_CONTENT_147: 'How to Adjust the Working Area Size?',
  EDIT_CONTENT_148: 'Drag the vertical line icon in the middle of the page to adjust the working area size',
  EDIT_CONTENT_149: 'How to Change Layout?',
  EDIT_CONTENT_150: 'Select the layout below the page you want to change',
  EDIT_CONTENT_151: 'How to Switch Templates?',
  EDIT_CONTENT_152: 'Click the "Switch Template" button at the top-right of the page, and select your preferred template',
  EDIT_CONTENT_153: 'In the popup below pages other than "Content Page", select to switch templates',
  EDIT_CONTENT_154: 'How to Generate PPT from Existing Content?',
  EDIT_CONTENT_155: 'Click the "Upload Content" button at the top-right of the page, supports free text input and file upload to generate PPT',
  EDIT_CONTENT_156: 'How to Download/Export PPT?',
  EDIT_CONTENT_157: 'Click the "Download" button at the top-right of the page, select the PPT pages and file type you wish to download',
  EDIT_CONTENT_158: 'Icon',
  EDIT_CONTENT_159: 'Background',
  EDIT_CONTENT_160: 'Replace Image',
  EDIT_CONTENT_161: 'Detach Background Image',
  EDIT_CONTENT_162: 'Delete',
  EDIT_CONTENT_163: 'Apply to All Pages',
  EDIT_CONTENT_164: 'Rectangle',
  EDIT_CONTENT_165: 'Circle',
  EDIT_CONTENT_166: 'Line',
  EDIT_CONTENT_167: 'Arrow',
  EDIT_CONTENT_168: 'Double Arrow',
  EDIT_CONTENT_169: 'Triangle',
  EDIT_CONTENT_170: 'Star',
  EDIT_CONTENT_171: 'Add Major Title',
  EDIT_CONTENT_172: 'Add Minor Title',
  EDIT_CONTENT_173: 'Add Body Text',
  EDIT_CONTENT_174: 'Insert',
  EDIT_CONTENT_175: 'Original Template Single Page',
  EDIT_CONTENT_176: 'All Icons',
  EDIT_CONTENT_177: 'Linear Icons',
  EDIT_CONTENT_178: 'Solid Icons',
  EDIT_CONTENT_179: 'Search Among a Huge Selection of Beautiful Icons',
  'EDIT_CONTENT_180': 'Text Box Settings',
  'EDIT_CONTENT_181': 'AI Generate',
  EDIT_CONTENT_182: 'Template Station',
  'EDIT_CONTENT_183': 'Generate all now',
  'EDIT_CONTENT_184': 'Template Station',
  EDIT_CONTENT_185: 'Complaint Feedback',
  EDIT_CONTENT_186: 'Giant Screen Presentation',
  EDIT_CONTENT_187: 'Apply Template Theme and Create New',
  'EDIT_CONTENT_191': 'Add Remark',

}
