import createNodeId from '@/pages/Edit/common/createNodeId'
import { INodeType } from '@/pages/Edit/type/INode'

export default () => {
    return {
        id: createNodeId(),
        type: INodeType.image,
        value:
      'https://pub.jianzeppt.cn/image/islide/239615.jpg?x-oss-process=image/auto-orient,1/interlace,1/format,jpg',
        transform: {
            rotate: 0,
            translateY: 0,
            translateX: 0,
        },
        imageTransform: {},
        style: {
            index: 0,
            left: 350,
            top: 175,
            height: 100,
            width: 100,
            position: 'absolute',
            zIndex: 200,
        },
    }
}
