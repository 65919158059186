export default {
  PPTXINDEX_1: '客户端下载',
  PPTXINDEX_2: '插件下载',
  PPTXINDEX_3: '导入内容转PPT',
  PPTXINDEX_4: '注册/登录',
  PPTXINDEX_5: '请输入搜索内容',
  PPTXINDEX_6: '1000万',
  PPTXINDEX_7: '千万级精选素材',
  PPTXINDEX_8: '每日更新',
  PPTXINDEX_9: '超过3000万+用户',
  PPTXINDEX_10: '加入{res}尊享权益保障',
  PPTXINDEX_11: '微信扫码登录或注册',
  PPTXINDEX_12: '扫码登录 快速下载',
  PPTXINDEX_13: '扫码关注“{res}”即可登录注册',
  PPTXINDEX_14: '首次扫码将自动注册新账号',
  PPTXINDEX_15: '注册登录即代表同意《用户协议》',
  PPTXINDEX_16: '加入我们',
};
