export default {
  WORKSPACE_1: 'Quick Create',
  WORKSPACE_2: 'AI generates Slides for you',
  WORKSPACE_3: 'Generate',
  WORKSPACE_4: 'Enter your Slides Topic',
  WORKSPACE_5: 'Select Slides Template',
  WORKSPACE_6: 'Convert Word to Slide',
  WORKSPACE_7: 'Local Slides Template',
  WORKSPACE_8: 'My Design',
  WORKSPACE_9: 'Recycle Bin',
  WORKSPACE_10: 'Recycle Bin',
  WORKSPACE_11: 'Plugin Download',
  WORKSPACE_12: 'Client Download',
  WORKSPACE_13: 'Change Language',
  WORKSPACE_16: 'Automatic Generation',
  WORKSPACE_17: 'Browse Templates',
  WORKSPACE_18: 'working summary',
  WORKSPACE_19: 'business plan',
  WORKSPACE_20: 'marketing program',
  WORKSPACE_21: 'graduation defense',
  WORKSPACE_22: 'corporate training',
  WORKSPACE_23: 'corporate communications',
  WORKSPACE_24: 'Supports free input, Word, Markdown, Freemind, and other formats',
  WORKSPACE_25: 'Multi-format Content to PPT',
  WORKSPACE_26: 'Almost Done...',
  WORKSPACE_27: 'Generating Slides',
  WORKSPACE_28: 'Wrong File Type Uploaded',
  WORKSPACE_29: 'Drag Files Here',
  WORKSPACE_30: 'Supports doc, docx, and other file formats',
  WORKSPACE_31: 'Upload Word File'

};
