import modelExtend from 'dva-model-extend';
import { model } from '@/utils/model';

export default modelExtend(model, {
  namespace: 'templateThum',
  state: {
    templateThumb: {},
    loading: {},
    storePage: {},
    maxTemplateSize: 0,
    pageId: 0,
  },
  subscriptions: {},
  effects: {},
  reducers: {
    stateByAny(state, { payload }) {
      return { ...state, ...payload };
    },
    stateAny(state, { payload }) {
      return { ...state, ...payload };
    },
    setLoading(state, { payload }) {
      const { pageId, searchPage } = payload;
      let page = 0;
      if (searchPage) page = searchPage;
      _.set(state, `loading.p${pageId}.p${page}`, true);
      return _.cloneDeep(state);
    },
    generateThumb(state, { payload }) {
      const { pageId, bestTemplate, searchPage } = payload;
      let page = 0;
      if (searchPage) page = searchPage;
      _.set(state, `templateThumb.p${pageId}.p${page}`, bestTemplate);
      _.set(state, `loading.p${pageId}.p${page}`, false);
      return _.assign({}, state);
    },
    setPage(state, { payload }) {
      const { pageId, searchPage } = payload;
      _.set(state, `storePage.${pageId}`, searchPage>=0 ? searchPage : 0); // eslint-disable-line
      return _.cloneDeep(state);
    },

    setThumbSetting(state, { payload }) {
      const { maxTemplateSize } = payload;
      _.set(state, 'maxTemplateSize', maxTemplateSize);
      return _.assign({}, state);
    },
  },
});
