export default {
    EDIT_CONTENT_1: '我的设计',
    EDIT_CONTENT_2: '写内容',
    EDIT_CONTENT_3: '改设计',
    EDIT_CONTENT_4: '去下载',
    EDIT_CONTENT_5: '下一步',
    EDIT_CONTENT_6: '上一步',
    EDIT_CONTENT_7: '列表导航',
    EDIT_CONTENT_8: '原始模板',
    EDIT_CONTENT_9: '输入你要写的PPT主题内容',
    EDIT_CONTENT_10: '重新生成',
    EDIT_CONTENT_11: '切换模板',
    EDIT_CONTENT_12: '下载原始模板',
    EDIT_CONTENT_13: '所有更改已保存',
    EDIT_CONTENT_14: '上传Word',
    EDIT_CONTENT_15: '智能排版',
    EDIT_CONTENT_16: '修改细节',
    EDIT_CONTENT_17: '封面页',
    EDIT_CONTENT_18: '目录页',
    EDIT_CONTENT_19: '章节页',
    EDIT_CONTENT_20: '内容页',
    EDIT_CONTENT_21: '结尾页',
    EDIT_CONTENT_22: '添加单页',
    EDIT_CONTENT_23: '文本',
    EDIT_CONTENT_24: '形状',
    EDIT_CONTENT_25: '图片',
    EDIT_CONTENT_26: '图表',
    EDIT_CONTENT_27: '画布',
    EDIT_CONTENT_28: '动画',
    EDIT_CONTENT_29: '图片替换',
    EDIT_CONTENT_30: '覆盖层',
    EDIT_CONTENT_31: '颜色',
    EDIT_CONTENT_32: '文本格式',
    EDIT_CONTENT_33: '文本调整',
    EDIT_CONTENT_34: '默认颜色',
    EDIT_CONTENT_35: '推荐颜色',
    EDIT_CONTENT_36: '最近使用',
    EDIT_CONTENT_37: '更多颜色',
    EDIT_CONTENT_38: '切页动画',
    EDIT_CONTENT_39: '素材动画',
    EDIT_CONTENT_40: '应用于所有页面',
    EDIT_CONTENT_41: '添加动画',
    EDIT_CONTENT_42: '填充',
    EDIT_CONTENT_43: '描边',
    EDIT_CONTENT_44: '不透明度',
    EDIT_CONTENT_45: '颜色',
    EDIT_CONTENT_46: '无效果',
    EDIT_CONTENT_47: '淡入淡出',
    EDIT_CONTENT_48: '下进上出',
    EDIT_CONTENT_49: '左进右出',
    EDIT_CONTENT_50: '上进下出',
    EDIT_CONTENT_51: '右进左出',
    EDIT_CONTENT_52: '预览所有',
    EDIT_CONTENT_53: '单击时',
    EDIT_CONTENT_54: '与上一项同时',
    EDIT_CONTENT_55: '在上一项之后',
    EDIT_CONTENT_56: '协作分享',
    EDIT_CONTENT_57: '演示',
    EDIT_CONTENT_58: '全选',
    EDIT_CONTENT_59: '下载选项',
    EDIT_CONTENT_60: '文件类型',
    EDIT_CONTENT_61: '选择页面',
    EDIT_CONTENT_62: '立即下载',
    EDIT_CONTENT_63: '全部页面',
    EDIT_CONTENT_64: '所有页面（{res}页）',
    EDIT_CONTENT_65: '当前页（第 {res} 页）',
    EDIT_CONTENT_66: '第一页',
    EDIT_CONTENT_67: '生成多要点内容',
    EDIT_CONTENT_68: '生成图文内容',
    EDIT_CONTENT_69: '输入标题一键生成内容',
    EDIT_CONTENT_70: '自定义内容',
    EDIT_CONTENT_71: '下载',
    EDIT_CONTENT_72: '上传内容',
    EDIT_CONTENT_73: '工作台',
    EDIT_CONTENT_74: '返回内容编辑',
    EDIT_CONTENT_75: '智能辅助模式',
    EDIT_CONTENT_76: '像写Word一样做 PPT',
    EDIT_CONTENT_77: '传统编辑模式',
    EDIT_CONTENT_78: '传统的PowerPoint布局',
    EDIT_CONTENT_79: '自定义主题颜色和字体',
    EDIT_CONTENT_80: '内容不变，换模板',
    EDIT_CONTENT_81: '解析主题风格，随心搭配',
    EDIT_CONTENT_82: '召唤小助手，添加图文',
    EDIT_CONTENT_83: '智能改写',
    EDIT_CONTENT_84: '智能添加一张图片',
    EDIT_CONTENT_85: '手动调整',
    EDIT_CONTENT_86: '标题',
    EDIT_CONTENT_87: '副标题',
    EDIT_CONTENT_88: '汇报人',
    EDIT_CONTENT_89: '汇报时间',
    EDIT_CONTENT_90: '章节编号',
    EDIT_CONTENT_91: '正文',
    EDIT_CONTENT_92: '添加图片',
    EDIT_CONTENT_93: '图片',
    EDIT_CONTENT_94: '添加图表',
    EDIT_CONTENT_95: '图表',
    EDIT_CONTENT_96: '添加表格',
    EDIT_CONTENT_97: '表格',
    EDIT_CONTENT_98: '嗨，我是秒出小助手。你想对内容做些什么呢？',
    EDIT_CONTENT_99: '输入想改变的内容',
    EDIT_CONTENT_100: '你可以这样和我说',
    EDIT_CONTENT_101: '添加一张配图',
    EDIT_CONTENT_102: '删除第一个要点',
    EDIT_CONTENT_103: '把标题修改为‘工作总结’',
    EDIT_CONTENT_104: '帮我简化第一个要点描述的内容',
    EDIT_CONTENT_105: '复制图片',
    EDIT_CONTENT_106: '下载图片',
    EDIT_CONTENT_107: '删除图片',
    EDIT_CONTENT_108: '复制图表',
    EDIT_CONTENT_109: '删除图表',
    EDIT_CONTENT_110: '召唤助手',
    EDIT_CONTENT_111: '关闭助手',
    EDIT_CONTENT_112: '添加图文',
    EDIT_CONTENT_113: '清空内容',
    EDIT_CONTENT_114: '复制当前页',
    EDIT_CONTENT_115: '删除此页',
    EDIT_CONTENT_116: '正文要点',
    EDIT_CONTENT_117: '人物介绍',
    EDIT_CONTENT_118: '时间线',
    EDIT_CONTENT_119: '数字展示',
    EDIT_CONTENT_120: '图标展示',
    EDIT_CONTENT_121: '添加一项',
    EDIT_CONTENT_122: '描述',
    EDIT_CONTENT_123: '删除全部要点',
    EDIT_CONTENT_124: '以{res}重新生成要点描述',
    EDIT_CONTENT_125: '把{res}拆分成4个要点',
    EDIT_CONTENT_126: '把{res}拆分成3个要点',
    EDIT_CONTENT_127: '简化{res}的内容',
    EDIT_CONTENT_128: '以{res}重新生成图文内容',
    EDIT_CONTENT_129: '以{res}重新生成要点内容',
    EDIT_CONTENT_130: '以{res}生成图文内容',
    EDIT_CONTENT_131: '以{res}生成要点内容',
    EDIT_CONTENT_132: '智能检测完成！',
    EDIT_CONTENT_133: '{res}分',
    EDIT_CONTENT_134: '进入传统编辑模式',
    EDIT_CONTENT_135: '问题与帮助！',
    EDIT_CONTENT_136: '常见问题',
    EDIT_CONTENT_137: '如何修改PPT内容？',
    EDIT_CONTENT_138: '方式一：',
    EDIT_CONTENT_139: '方式二：',
    EDIT_CONTENT_140: '在内容编辑区域，修改您文本内容，右侧PPT内容也会直接修改',
    EDIT_CONTENT_141: '在内容区域，召唤秒出助手，帮你添加图片、图表等内容',
    EDIT_CONTENT_142: '在设计区域，选择图片、表格等进行添加',
    EDIT_CONTENT_143: '在内容区域页面之间，选择添加按钮，即可添加页面；点击右上角删除，可删除本页，右侧设计区域的PPT页面会同步删除',
    EDIT_CONTENT_144: '在设计区域，点击右上角删除，可删除本页，右侧设计区域的PPT页面会同步删除',
    EDIT_CONTENT_145: '如何添加图片、图表？',
    EDIT_CONTENT_146: '如何添加/删除PPT页面？',
    EDIT_CONTENT_147: '如何调整操作区域大小？',
    EDIT_CONTENT_148: '选中页面中部竖线图标，左右拖动，即可调整操作区域的大小',
    EDIT_CONTENT_149: '如何更换排版？',
    EDIT_CONTENT_150: '在您想要更换排版的页面下，选择版式，即可更换排版',
    EDIT_CONTENT_151: '如何切换模板？',
    EDIT_CONTENT_152: '在页面右上角选择“切换模版”按钮，选择心仪的模版，立即更换模板',
    EDIT_CONTENT_153: '在除“内容页”以外的其他页面下方弹窗中，选择切换模板，立即更换模板',
    EDIT_CONTENT_154: '已有内容如何生成PPT？',
    EDIT_CONTENT_155: '在页面右上角选择“上传内容”按钮，支持自由输入内容、上传文件，生成PPT',
    EDIT_CONTENT_156: '怎么下载/导出PPT？',
    EDIT_CONTENT_157: '在页面右上角选择“下载”按钮，选择想要下载的PPT页面和文件类型，即可下载文件',
    EDIT_CONTENT_158: '图标',
    EDIT_CONTENT_159: '背景',
    EDIT_CONTENT_160: '替换图片',
    EDIT_CONTENT_161: '分离背景图',
    EDIT_CONTENT_162: '删除',
    EDIT_CONTENT_163: '应用到全部页面',
    EDIT_CONTENT_164: '矩形',
    EDIT_CONTENT_165: '圆形',
    EDIT_CONTENT_166: '直线',
    EDIT_CONTENT_167: '箭头',
    EDIT_CONTENT_168: '双箭头',
    EDIT_CONTENT_169: '三角形',
    EDIT_CONTENT_170: '星形',
    EDIT_CONTENT_171: '添加大标题',
    EDIT_CONTENT_172: '添加小标题',
    EDIT_CONTENT_173: '添加正文',
    EDIT_CONTENT_174: '添加',
    EDIT_CONTENT_175: '原模板单页',
    EDIT_CONTENT_176: '所有图标',
    EDIT_CONTENT_177: '线性图标',
    EDIT_CONTENT_178: '面性图标',
    EDIT_CONTENT_179: '在海量精美图标中搜索',
    EDIT_CONTENT_180: '文本框设置',
    EDIT_CONTENT_181: '一键生成',
    EDIT_CONTENT_182: '浏览模板站',
    EDIT_CONTENT_183: '立即全部生成',
    EDIT_CONTENT_184: '模板站',
    EDIT_CONTENT_185: '投诉反馈',
    EDIT_CONTENT_186: '巨幕演示',
    EDIT_CONTENT_187: '应用模版主题并新建',
    EDIT_CONTENT_188: '关于我们',
    EDIT_CONTENT_189: '关于我们',
    EDIT_CONTENT_190: '关于我们',
    EDIT_CONTENT_191: '添加批注',
    EDIT_CONTENT_192: '椭圆',
}
