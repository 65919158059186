export default {
  premSel: '精品臻选',
  enhEff: '提升效率',
  higQuaAff: '质优价廉',
  compSat: '100%满意',
  seaTem: '搜索模板',
  genOne: '一键生成',
  pptTem: 'PPT模板',
  imgMat: '图片素材',
  choIco: '精选图标',
  goWor: '进入工作台',
};
