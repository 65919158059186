import modelExtend from 'dva-model-extend';
import { model } from '@/utils/model';
import { getThemeById } from '@/service/services';
import * as pptx from '../utils/pptx';
import { jsonTo } from '../../src/web-utils/index';
import { getDvaApp } from '@@/plugin-dva/exports';

export default modelExtend(model, {
  namespace: 'pptxTheme',
  state: {
    // 是否已初始化(fetch)当前state
    initial: false,
    loading: false,
    error: null,
    byId: {},
    allIds: [],
  },
  subscriptions: {},
  effects: {
    * getById({ payload }, { call, put }) {
      try {
        const d = yield call(getThemeById, { id: payload });
        const noBase = false;
        const item = jsonTo(d.data);
        if (!item) {
          return null;
        }
        item.id = d.id;
        item.name = d.name;
        item.description = d.description;
        item.createTime = d.createTime;
        const data = noBase ? item : pptx.buildOptions(pptx.themeBase(), item);
        if (_.isObject(data)) {
          yield put({
            type: 'parseById',
            payload: data,
          });
        } else {
          throw new Error(`Not Found`);
        }
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    },
    * fetchThemeById({ payload }, { call, put }) {
      try {
        const d = yield call(getThemeById, { id: payload });
        const noBase = false;
        const item = jsonTo(d.data);
        item.id = d.id;
        item.name = d.name;
        item.description = d.description;
        item.createTime = d.createTime;
        const data = noBase ? item : pptx.buildOptions(pptx.themeBase(), item);
        yield put({ type: 'parseById', payload: data });
        return data;
      } catch (e) {

      }
    },
  },
  reducers: {
    parseById(state, { payload }) {
      dataParser(state, payload);
      return _.assign({}, state);
    },
  },
});

function dataParser(state, data) {
  if (state.byId[data.id]) {
    state.byId[data.id] = data;
    state.allIds = [...state.allIds];
  } else {
    state.byId = _.assign({}, state.byId);
    state.byId[data.id] = data;
    state.allIds = [...state.allIds, data.id];
  }
}


export const fetchThemeById = async (id) => {
  const app = getDvaApp();
  const store = app._store;
  const { getState, dispatch } = store;
  const d = await getThemeById({ id });
  const noBase = false;
  const item = jsonTo(d.data);
  item.id = d.id;
  item.name = d.name;
  item.description = d.description;
  item.createTime = d.createTime;
  const data = noBase ? item : pptx.buildOptions(pptx.themeBase(), item);
  const { pptxTheme } = getState();
  dataParser(pptxTheme, data);
  return data;
};
