import { buildMetadata, combineOption, try2LastIndex } from '../utils';

export const getMetadata = (props) => {
  const { options, page } = props;
  if (!_.get(options, 'plate.valueModel') || !_.get(page, 'plate.value')) {
    return null;
  }
  const metadata = buildMetadata(options.plate, page.plate)(
    options,
    false,
    false,
  );
  let count = 0;
  for (let i of metadata.valueKeys) {
    if (Number(i)) {
      count++;
    }
  }
  metadata.style.gridTemplateAreas = metadata.defaultTemplateAreas[count - 1];
  metadata.valueKeys.forEach((key, index) => {
    let value = metadata.value[key]?.value;
    let style = metadata.value[key]?.style;
    metadata.value[key] = buildMetadata(
      options.plate.valueModel,
      try2LastIndex(options.plate.valueLength, [count, index]),
      _.get(options.plate.valueIndex, index),
      _.get(page.plate.value, key),
      // 标记grid area
      { style: { gridArea: String.fromCharCode(97 + index), ...style } },
    )(options);
    if (key === 'frame' && metadata.value[key]?.value?.image) {
      metadata.value[key].value.image.value = value;
      metadata.value[key].style = style;
    }
    metadata.value[key].valueKeys = metadata.value[key].valueKeys.filter(
      (d) => !!_.get(metadata, `value.${key}.value.${d}.norm`),
    );
  });
  metadata.grid = combineOption(metadata.grid, options, '', null);
  return metadata;
};

const valueKey = (platekey, itemkey, metakey) => {
  let str = 'plate.value';
  if (!platekey) {
    return str;
  }
  str = `${str}.${platekey}`;
  if (!itemkey) {
    return str;
  }
  str = `${str}.value.${itemkey}`;
  if (metakey) {
    return str;
  }
  return `${str}.value`;
};

export const addplateItem = (page, dft) => {
  const plateMap = _.get(page, valueKey());
  const newId =
    ((plateMap ? _.max(_.keys(plateMap).map((d) => +d || 1)) : 1) || 1) + 1;
  const sort = (_.max(_.keys(plateMap).map((d) => +plateMap[d].sort)) || 1) + 1;

  _.set(page, valueKey(newId), _.merge({ sort }, dft));

  return String(newId);
};

export const getDocdata = (metadata) => {
  if (!metadata) {
    return [];
  }

  const docdata = [];
  metadata.valueKeys.forEach((key) => {
    const itemdata = metadata.value[key];
    const data = [];
    [
      ['image', { type: ['Image'] }],
      ['title', { type: ['Text'] }],
    ].forEach(([name, dft]) => {
      let item = _.get(itemdata, `value.${name}`);
      if (!item) {
        item = dft;
      }
      data.push({
        value: item.value,
        key: valueKey(key, name),
        type: item.type,
        attrs: item.attrs,
        show: _.get(item, 'style.display') !== 'none',
        metakey: valueKey(key, name, 1),
        // 删除时使用
        platekey: key,
        // 暂未用，先放着
        itemkey: name,
      });
    });
    docdata.push(data);
  });

  return docdata;
};
/**
 * 更新来自「内容页」的 value 到 page
 * @method d2mUpdate
 * @param  {[type]}  plate [参考 getDocdata 的返回值]
 * @param  {[type]}  page [description]
 * @return {[type]}       [空数组表名无更新（因此不需要触发api）]
 */
export const d2mUpdate = (plate, page) => {
  if (!plate || !plate.length) {
    return [];
  }
  const saveKeys = plate
    .map((data) => {
      if (!data || !data.length) {
        return [];
      }
      const updates = data.filter((d) => !!d.key);
      if (updates.length) {
        // update 操作，上方的 filter 可能过滤掉了错误（不含 key）的数据
        const count = updates.filter((item) => {
          let update = false;
          if (_.get(page, item.key) !== item.value) {
            _.set(page, item.key, item.value);
            update = true;
          }
          const itemdata = _.get(page, item.metakey);
          if (itemdata) {
            if (item.show && _.get(itemdata, 'style.display') === 'none') {
              delete itemdata.style.display;
              update = true;
            } else if (
              !item.show &&
              _.get(itemdata, 'style.display') !== 'none'
            ) {
              _.set(itemdata, 'style.display', 'none');
              update = true;
            }
          }
          return update;
        }).length;
        if (!count) {
          return [];
        }
      } else {
        // 新建 plate 项目
        const value = {
          image: { value: _.get(data, '0.value') },
          title: { value: _.get(data, '1.value') },
        };
        const KEYS = ['image', 'text'];
        data.slice(0, 3).forEach((item, i) => {
          if (!item.show) {
            value[KEYS[i]].style = { display: 'none' };
          }
        });
        addplateItem(page, { value });
      }
      return ['plate'];
    })
    .reduce((a, b) => a.concat(b), []);

  return _.uniq(saveKeys);
};
/**
 * 删除 plate
 * @method d2mDelete
 * @param  {[type]}  plate [参考 getDocdata 的返回值的 第二维度数据]
 * @param  {[type]}  page [description]
 * @return {[type]}       [空数组表名无更新（因此不需要触发api）]
 */
export const d2mDelete = (plate, page) => {
  if (!plate || !plate.length) {
    return [];
  }
  const saveKeys = plate
    .map((data) => {
      const item = _.get(page, valueKey());
      if (!item) {
        // 不存在 plate
        return [];
      }
      return (_.isArray(data) ? data : [data])
        .map((d) => {
          if (!item[d.platekey]) {
            // 无标记数据或不存在的被标记的数据，无法删除，略过
            return [];
          }
          delete item[d.platekey];
          return ['plate'];
        })
        .reduce((a, b) => a.concat(b), []);
    })
    .reduce((a, b) => a.concat(b), []);

  return _.uniq(saveKeys);
};
