import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import initState from '@/pages/Edit/redux/initState'
import { getIntelligentTemplateTheme } from '@/service/services'
import { AddShadowAndGradientForPage } from '@/utils/svg/addShadow'

export const fetchThemeThunk = createAsyncThunk(
    'themes/fetchThemeThunk',
    async (payload) => {
        const { themeId, pptId } = payload
        let theme = JSON.parse(
            await getIntelligentTemplateTheme(undefined, { templateId: themeId, pptId }),
        )
        theme.id = themeId
        AddShadowAndGradientForPage(theme, theme)
        return theme
    },
)

export default createSlice({
    name: 'themes',
    initialState: initState.themes,
    reducers: {
        set(state, { payload }) {
            return payload
        },
        add(state, { payload }) {
            if (payload) {
                state[payload.id] = payload
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchThemeThunk.fulfilled, (state, action) => {
            state[action.payload.id] = action.payload
        })
    },
})
