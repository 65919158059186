import { createSlice } from '@reduxjs/toolkit'
import { v4 } from 'uuid'
import initState from '@/pages/Edit/redux/initState'
import history from '@/pages/Edit/redux/version'

export default createSlice({
    name: 'history',
    initialState: initState.history,
    reducers: {
        clear(state) {
            history.clear()
            state.id = undefined
        },
        preVersion(state) {
            history.preVersion()
            state.id = history.currentVersion.id
        },
        nextVersion(state) {
            history.nextVersion()
            state.id = history.currentVersion.id
        },
        push(state, { payload }) {
            history.push(payload)
            state.id = history.currentVersion.id
        },
        updateNodeHistory(state) {
            state.nodeHistory = v4()
        },
    },
})
