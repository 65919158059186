import { createSlice } from '@reduxjs/toolkit';
import initState from '@/pages/Edit/redux/initState';

export default createSlice({
  name: 'originTemplateDetail',
  initialState: initState.originTemplateDetail,
  reducers: {
    set(state, { payload }) {
      return payload;
    },
  },
});
