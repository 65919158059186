export default {
  USER_1: '升级会员',
  USER_2: '下载记录',
  USER_3: 'AI生成记录',
  USER_4: '我的收藏',
  USER_5: '退出登录',
  USER_6: '本月剩余',
  USER_7: '次',
  USER_8: '页',
  USER_9: '手机号绑定',
};
