export default {
  TEMPLATE_CHANGE_MULTI_1: 'Switch Slide Templates',
  TEMPLATE_CHANGE_MULTI_2: 'Company specified a template?',
  TEMPLATE_CHANGE_MULTI_3: 'Analyze and match the new page without worry',
  TEMPLATE_CHANGE_MULTI_4: 'Upload',
  TEMPLATE_CHANGE_MULTI_5: 'Return',
  TEMPLATE_CHANGE_MULTI_6: 'Year-end work summary plan',
  TEMPLATE_CHANGE_MULTI_7: 'Number of pages',
  TEMPLATE_CHANGE_MULTI_8: 'Apply Entire Template Set',
  TEMPLATE_CHANGE_MULTI_9: 'Upload Local Template',
};
