import { cloneDeep } from 'lodash'
import { getDataByPage } from '@/pages/Edit/common/node/nodemark'
import { splitTextByHTML } from '@/utils/text'

export const processCoverSplitTitle = (pageList, splitText) => {
    return pageList.map((p) => {
        const page = cloneDeep(p)
        if (page.type === 'cover') {
            const data = getDataByPage(page, {})
            const titleNode = data?.title
            if (titleNode && splitText && splitText.length > 0) {
                titleNode.value = splitTextByHTML(titleNode, splitText)
                titleNode.style.lineHeight = 1.5
            }
        }

        return page
    })
}
