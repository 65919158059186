import getNodes from '@/pages/Edit/common/node/getNodes'
import { INodeType } from '@/pages/Edit/type/INode'
import { getTextByNode } from '@/components/NodeMarkRender'

// Cover
export const getCoverData = (page, theme) => {
    const nodes = getNodes({ page, theme })
    const { nodeMark } = page
    return {
        title: nodes[nodeMark?.pageTitle?.[0]],
        des: nodes[nodeMark?.subtitle?.[0]],
        subTitle: nodes[nodeMark?.subtitle?.[0]],
        author: nodes[nodeMark?.author?.[0]],
        date: nodes[nodeMark?.date?.[0]],
        user: nodeMark?.user?.map?.((id) => nodes[id])?.filter?.((node) => node?.type === INodeType.text),
        // others: nodeMark?.describe?.map?.((id, index) => nodes[id]).filter((i) => !!i) || [],
    }
}
export const getCoverNodeList = (data) => {
    return [].concat([data.title]).concat([data.des]).concat([data.author]).concat([data.date])
        .concat(data.user)
        .filter((node) => !!node)
}

// transition

export const getTransitionData = (page, theme) => {
    const nodes = getNodes({ page, theme })
    const { nodeMark } = page
    return {
        title: nodes[nodeMark?.pageTitle?.[0]],
        partNum: nodes[nodeMark?.partNum?.[0]],
        user: nodeMark?.user?.map?.((id) => nodes[id])?.filter?.((node) => node?.type === INodeType.text),
        subTitle: nodes[nodeMark?.subtitle?.[0]],
        des: nodes[nodeMark?.describe?.[0]],
        // others: nodeMark?.describe?.map?.((id, index) => nodes[id]).filter((i) => !!i) || [],
    }
}
export const getTransitionNodeList = (data) => {
    return [].concat([data.partNum]).concat([data.title]).concat([data.subTitle]).concat(data.user)
        .filter((node) => !!node)
}

// Catalog
export const getCatalogData = (page, theme) => {
    const nodes = getNodes({ page, theme })
    const { nodeMark } = page
    return {
        list: nodeMark?.catalog?.map?.((item) => nodes[item.title?.id]).filter((node) => !!node),
        user: nodeMark?.user?.map?.((id) => nodes[id])?.filter?.((node) => node?.type === INodeType.text),
        // others: nodeMark?.describe?.map?.((id) => nodes[id]).filter((i) => !!i) || [],
    }
}
export const getCatalogNodeList = (data) => {
    return [].concat(data.list).concat(data.user).filter((node) => !!node)
}

// Main
const createElement = (params) => {
    const {
        id, children = [], type, value, level, type2,
    } = params
    return {
        id,
        children,
        type: INodeType.text,
        type2,
        value,
        level,
    }
}
const parseUl = (node, list, level = 0) => {
    if (node.tagName === 'UL') {
        node.childNodes.forEach((node) => {
            parseUl(node, list, level + 1)
        })
    } else if (node.tagName === 'LI') {
        node.childNodes.forEach((node) => {
            parseUl(node, list, level)
        })
    } else if (node.nodeType === 3) {
        list.push(createElement({ value: node.nodeValue, type2: 'li', level }))
    } else {
        list.push(createElement({ value: node.outerHTML, type2: 'li', level }))
    }
}
const parseVDescribe2Node = (node) => {
    if (!node) return
    const { id } = node
    const isUl = node.value.indexOf('<ul') !== -1
    if (isUl) {
        const dom = new DOMParser().parseFromString(node.value, 'text/html')
        const list = []
        dom.body.childNodes.forEach((node) => {
            if (node.tagName === 'P') {
                list.push(createElement({ value: node.outerHTML, type2: 'p' }))
            } else if (node.tagName === 'UL') {
                parseUl(node, list)
            } else {
                list.push(createElement({ value: node.outerHTML, type2: 'p' }))
            }
        })
        list.forEach((item, index) => {
            item.id = `${node.id}-${index}`
            item.style = {}
        })
        return { id, list, type2: 'ul' }
    }
    return node
}
export const getMainListTextNodeList = (data) => {
    return [].concat(data.list?.map?.((item) => [item?.title]
        .concat([item?.time])
        .concat([item?.bigNumber])
        .concat((item?.valueNodes?.map?.((node) => (node?.type2 === 'ul' ? node?.list : node)) || []).flat())).flat()).filter((node) => !!node)
}
export const getMainData = (page, theme) => {
    const nodes = getNodes({ page, theme })
    const { nodeMark } = page
    return {
        title: nodes[nodeMark?.pageTitle],
        main: nodes[nodeMark?.describe?.[0]],
        // subTitle: nodes[nodeMark?.pageSecondTitle], TODO: 这里后端返回的是subtitle
        subTitle: nodeMark?.subtitle ? nodes[Array.isArray(nodeMark?.subtitle) ? nodeMark?.subtitle[0] : nodeMark?.subtitle] : '',
        list: nodeMark?.point?.map?.((item) => {
            if (!item) return undefined
            return {
                title: nodes[item?.title?.id],
                valueNodes: nodes[item.valueNodes?.[0]?.id] ? [parseVDescribe2Node(nodes[item.valueNodes?.[0]?.id])] : undefined,
                plate: item?.plate?.map?.((id) => nodes[id])?.slice?.(0, 1),
                time: nodeMark.pointType === 'time' ? nodes[item?.time?.id] : undefined,
                icon: nodes[item?.icon?.id],
                bigNumber: nodeMark.pointType === 'bigNumber' ? nodes[item?.bigNumber?.id] : undefined,
            }
        }).filter((i) => !!i),
        plate: nodeMark?.plate?.map?.((id) => nodes[id]).filter((i) => !!i),
        chart: nodeMark?.charts?.map?.((id) => nodes[id]).filter((i) => !!i),
        table: nodeMark?.table?.map?.((id) => nodes[id]).filter((i) => !!i),
        video: nodeMark?.video?.map?.((id) => nodes[id]).filter((i) => !!i),
        others: nodeMark?.otherDescribe?.map?.((id) => nodes[id]).filter((i) => !!i) || [],
        pointFirst: nodeMark?.pointIndex === 0,
        user: nodeMark?.user?.map?.((id) => nodes[id])?.filter?.((node) => node?.type === INodeType.text),
        flowChart: nodeMark?.flowchart?.map?.((id) => nodes[id]).filter((i) => !!i),
        animationElement: nodeMark?.animationElement?.map((id) => nodes[id]),
        pointType: nodeMark?.pointType,
    }
}

export const getMainPointNodeList = (data) => {
    return [].concat(data.list?.map?.((item) => [item?.title]
        .concat([item?.time])
        .concat([item?.bigNumber])
        .concat([item?.icon])
        .concat(item?.plate?.map?.((node) => node))
        .concat((item?.valueNodes?.map?.((node) => (node?.type2 === 'ul' ? node?.list : node)) || []).flat())).flat()).filter((node) => !!node)
}
export const getMainPointDesNodeList = (data) => {
    return [].concat(data.list?.map?.((item) => (item?.valueNodes?.map?.((node) => (node?.type2 === 'ul' ? node?.list : node)) || []).flat())).flat().filter((node) => !!node)
}

export const getMainNodeList = (data, options = {}) => {
    const { excludeOthers, excludePoint } = options
    return [data.title, data.subTitle, data.main]
        .concat(data?.user)
        .concat(excludePoint ? [] : getMainPointNodeList(data))
        .concat(data.plate)
        .concat(data.table)
        .concat(data.chart)
        .concat(data.video)
        .concat(data.flowChart)
        .concat(data?.animationElement)
        .concat(excludeOthers ? [] : data.others)
        .filter((node) => !!node)
}
export const getMainNodeMarkIdList = (page) => {
    const { nodeMark } = page
    return [
        nodeMark?.pageTitle,
        nodeMark?.subtitle,
        nodeMark?.describe?.[0],
        (nodeMark?.point?.map((item) => ([item?.title?.id, item.valueNodes?.[0]?.id, item.plate?.[0], item?.time?.id, item?.icon?.id, item?.bigNumber?.id])) || []).flat(),
        nodeMark?.plate,
        nodeMark?.charts,
        nodeMark?.table,
        nodeMark?.otherDescribe,
    ].flat().filter((i) => !!i)
}
export const getListTargetById = (data, id) => {
    return data.list.find((item) => {
        return (item.title?.id === id || item.valueNodes?.some?.((i) => i?.id === id))
    })
}
// End
export const getEndData = (page, theme) => {
    const nodes = getNodes({ page, theme })
    const { nodeMark } = page
    return {
        title: nodes[nodeMark?.pageTitle?.[0]],
        user: nodeMark?.user?.map?.((id) => nodes[id])?.filter?.((node) => node?.type === INodeType.text),
        subTitle: nodeMark?.subtitle ? nodes[Array.isArray(nodeMark?.subtitle) ? nodeMark?.subtitle[0] : nodeMark?.subtitle] : '',
        // others: nodeMark?.describe?.map?.((id) => nodes[id]).filter((i) => !!i) || [],
    }
}
export const getEndNodeList = (data) => {
    return [].concat([data.title]).concat(data.user).filter((node) => !!node)
}
// -------------------——
export const getDataByPage = (page, theme = {}) => {
    if (!page) return undefined
    switch (page.type) {
        case 'cover': {
            return getCoverData(page, theme)
        }
        case 'transition': {
            return getTransitionData(page, theme)
        }
        case 'catalog': {
            return getCatalogData(page, theme)
        }
        case 'main': {
            return getMainData(page, theme)
        }
        case 'other': {
            return getMainData(page, theme)
        }
        case 'end': {
            return getEndData(page, theme)
        }

        default: {
            return getMainData(page, theme)
        }
    }
}
export const getNodeListByPage = (page, theme, options) => {
    if (!page) return
    const data = getDataByPage(page, theme)
    switch (page.type) {
        case 'cover': {
            return getCoverNodeList(data, options)
        }
        case 'transition': {
            return getTransitionNodeList(data, options)
        }
        case 'catalog': {
            return getCatalogNodeList(data, options)
        }
        case 'main': {
            return getMainNodeList(data, options)
        }
        case 'other': {
            return getMainNodeList(data, options)
        }
        case 'end': {
            return getEndNodeList(data, options)
        }
        default: {
            return getMainNodeList(data, options)
        }
    }
}

export const getIdList = (nodeList) => {
    return nodeList.map((node) => node.id)
}
