import { createSlice } from '@reduxjs/toolkit';
import initState from '@/pages/Edit/redux/initState';

// showFullScreenAnimation: false,
// showSyllabusAnimation: false,
// showContentAnimation: false,
export default createSlice({
  name: 'animationProgress',
  initialState: initState.animationProgress,
  reducers: {
    setAnimationProgress(state, { payload }) {
      return payload
    },
  },
});
