// 处理有错误数据的pageData

import { v4 } from 'uuid'
import { checkChartOption, defaultLineChartOption } from '@/utils/charts'
import { INodeType } from '../type/INode'

export const processTextUUID = (page) => {
    if (page?.type === 'html' || !page?.nodes) return page
    try {
        const p = page
        Object.keys(p.nodes).forEach((key) => {
            const node = p.nodes[key]
            if (node.type === INodeType.text) {
                node.uuid = v4()
            }
        })
        return p
    } catch (error) {
        console.log('处理失败', error, page)
        return page
    }
}
