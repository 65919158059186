import 'whatwg-fetch';
import React from 'react';
import { notification, Modal } from 'antd';

const HTTP_CONTENT_TYPE = Math.random();
// http 请求网络级的报错，如: 404、503、断网等
const HTTP_ERROR = Math.random();
/**
 * 统一处理: http错误码
 * @method
 * @param  {Number} [times=1000] [description]
 * @return {[type]}              [description]
 */
const httpError = () => {
  notification.error({
    key: 'http error',
    duration: null,
    message: '请求出错',
    description: (
      <div>
        服务器开了个小差，请稍后再试！
        <br />
        或请检查您的网络是否连通；
      </div>
    ),
  });
};
/**
 * 统一处理: 权限不足
 * @method
 * @param  {Number} [times=1000] [description]
 * @return {[type]}              [description]
 */
const login401 = function login401(times = 1000) {
  if (this.modal) {
    return;
  }
  clearTimeout(this.timer);
  this.timer = setTimeout(() => {
    this.modal = Modal.error({
      zIndex: 10000,
      title: '权限不足',
      content: (
        <div>
          您没有该系统的访问权限，请联系管理员；
          <br />
          或者
          <a role="link" tabIndex="0" onClick={MVP.logout}>
            更换账号
          </a>
          再试；
        </div>
      ),
      onOk: () => Promise.reject(),
    });
  }, times);
}.bind({});

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return null;
  }

  const error = new Error(response.statusText);
  error.response = response;
  return error;
}

/**
 * [实际的使用中, 请使用 fetchGet 或 fetchPost
 *  下面的 TODO 都是需要在实际项目中根据需要选择/更改使用
 * ]
 * @method request
 * @param  {String} url     [包含: 主机, pathname, search, hash 等信息的 URL]
 * @param  {Object} options [其他可配置的参数(参见 whatwg-fetch), 如: headers]
 * @return {Promise}         [description]
 */
export default function request(url, options) {
  return fetch(url, options)
    .then(
      (response) => {
        // 拦截所有请求状态
        const error = checkStatus(response);
        if (error) {
          return {
            error,
            // 服务器出错, 比如: 500
            code: HTTP_ERROR,
          };
        }
        const contentType = response.headers.get('Content-Type') || '';
        if (contentType.toLowerCase().indexOf('application/json') !== -1) {
          return response.json();
        }
        return {
          code: HTTP_CONTENT_TYPE,
          // 可能需要执行：
          // 1、response.blob()
          // 2、response.text()
          response,
        };
      },
      (error) => ({
        error,
        // 通信异常, 比如: 网络中断、跨域等
        code: HTTP_ERROR,
      }),
    )
    .catch((error) => ({
      // 捕获其他所有报错, 保证之后的 then 可以只处理 resolve, 以 code 来判断请求结果
      error,
      // 未知异常
      code: HTTP_ERROR,
    }))
    .then((data) => {
      // 统一处理接口状态, 返回调用层"需要的数据"
      if (data.code === HTTP_CONTENT_TYPE) {
        // 非 json 类型的响应，交由上层处理
        return data.response;
      }
      if (data.code === HTTP_ERROR) {
        // throw http 级的状态错误
        httpError();
        return Promise.reject(data.error);
      }
      if (data.code === 0) {
        // TODO 1、检查请求成功的标志
        // 和接口方约定, data.data 为请求返回的数据
        return data.data;
      }
      if (data.error) {
        // TODO 2、前端错误处理
        return data;
      }
      if (data.code === 10404) {
        // TODO 3、检查是否为用户未登录状态
        // 判断是否为 check-token 的请求,
        // 如果是, 则应交由调用层处理
        if (url.indexOf('/check-token') !== -1) {
          return data;
        }
        // 如果否, 则应刷新页面(或提示用户登录)
        window.location.reload();
        return { code: '未登录' };
      }
      if (data.code === 40101) {
        // TODO 3、检查是否有权限
        login401();
        return { code: '权限不足' };
      }
      // TODO 4、检查当前版本是否为最新版
      // TODO 5、检查其他状态码
      // TODO 6、未知异常
      return data;
    });
}
