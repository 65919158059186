export default {
    TEMPLATE_CHANGE_1: '版式切换',
    TEMPLATE_CHANGE_2: '确定',
    TEMPLATE_CHANGE_3: '取消',
    TEMPLATE_CHANGE_4: '移至回收站',
    TEMPLATE_CHANGE_5: '文档移入回收站，保留{res}天后将自动彻底删除',
    TEMPLATE_CHANGE_6: '退出协作',
    TEMPLATE_CHANGE_7: '退出协作后，将无法获得本文档的相应权限。',
    TEMPLATE_CHANGE_8: '演示文稿',
    TEMPLATE_CHANGE_9: '编辑',
    TEMPLATE_CHANGE_10: '复制',
    TEMPLATE_CHANGE_11: '移入回收站',
    TEMPLATE_CHANGE_12: '退出协作',
    TEMPLATE_CHANGE_13: '可选排版',
    TEMPLATE_CHANGE_14: '暂无合适排版推荐，建议减少些内容哦～',
    TEMPLATE_CHANGE_15: '更多',
    TEMPLATE_CHANGE_16: '保存',
    TEMPLATE_CHANGE_17: '从头开始',
    TEMPLATE_CHANGE_18: '从当前开始',
    TEMPLATE_CHANGE_19: '共享此设计',
    TEMPLATE_CHANGE_20: '所有者',
    TEMPLATE_CHANGE_21: '编辑者',
    TEMPLATE_CHANGE_22: '查看者',
    TEMPLATE_CHANGE_23: '分享链接',
    TEMPLATE_CHANGE_24: '链接权限',
    TEMPLATE_CHANGE_25: '复制成功',
    TEMPLATE_CHANGE_26: '可以编辑、评论、分享文件',
    TEMPLATE_CHANGE_27: '不可编辑，可以查看、评论文件',
    TEMPLATE_CHANGE_28: '一小时',
    TEMPLATE_CHANGE_29: '一天',
    TEMPLATE_CHANGE_30: '七天',
    TEMPLATE_CHANGE_31: '有效期',
    TEMPLATE_CHANGE_32: ' 复制链接',
    TEMPLATE_CHANGE_33: 'PDF',
    TEMPLATE_CHANGE_34: 'Word-仅内容',
    TEMPLATE_CHANGE_35: 'Markdown-仅内容',
    TEMPLATE_CHANGE_36: 'Freemind-仅内容',
    TEMPLATE_CHANGE_37: '第{res}页',
    TEMPLATE_CHANGE_38: 'Word-仅摘要、标题、内容',
    TEMPLATE_CHANGE_39: '编组',
    TEMPLATE_CHANGE_40: '拆分组',
    TEMPLATE_CHANGE_41: '设为背景',
    TEMPLATE_CHANGE_42: '分离背景图片',
    TEMPLATE_CHANGE_43: '删除背景图片',
    TEMPLATE_CHANGE_44: '剪切',
    TEMPLATE_CHANGE_45: '粘贴',
    TEMPLATE_CHANGE_46: '解锁图层',
    TEMPLATE_CHANGE_47: '锁定图层',
    TEMPLATE_CHANGE_48: '图层顺序',
    TEMPLATE_CHANGE_49: '移到顶层',
    TEMPLATE_CHANGE_50: '上移一层',
    TEMPLATE_CHANGE_51: '下移一层',
    TEMPLATE_CHANGE_52: '移到底层',
    TEMPLATE_CHANGE_53: '删除图层',
    TEMPLATE_CHANGE_54: '评论',
    TEMPLATE_CHANGE_55: '粗体',
    TEMPLATE_CHANGE_56: '斜体',
    TEMPLATE_CHANGE_57: '下划线',
    TEMPLATE_CHANGE_58: '删除线',
    TEMPLATE_CHANGE_62: '竖排',
}
