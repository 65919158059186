import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPptxById } from '@/service/services';
import initState from '@/pages/Edit/redux/initState';


export const fetchPPTThunk = createAsyncThunk(
  'pages/fetchPPTThunk',
  async (id, thunkAPI) => {
    return await getPptxById({ id });
  },
);


export default createSlice({
  name: 'ppt',
  initialState: initState.ppt,
  reducers: {
    set(state, { payload }) {
      return payload;
    },
    setThemeId(state, { payload }) {
      state.themeId = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPPTThunk.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});
