import { getTextContentByHTML } from '@/utils/text'
import { createListItem, createTitleDescChildren, createParagraph } from './utils'
import { serialize } from '../serialize'
import getSVGImage from '../../../PPTContainer/NodeComponent/base/SvgComponent/getSVGImage'

export const processNormalOrIconType = (dataItem) => {
    const result = dataItem.map((item) => {
        const titleValue = getTextContentByHTML(item?.title?.value)
        const descValues = item?.valueNodes?.map((node) => getTextContentByHTML(node.value)).join('')
        const children = createTitleDescChildren(titleValue, descValues)
        return createListItem(children)
    })

    return serialize({
        type: 'bulleted-list',
        children: result,
    })
}

export const processBigNumberOrTimeType = (dataItem) => {
    const result = dataItem.map((item) => {
        const prefixValue = getTextContentByHTML(item.bigNumber?.value || item.time?.value)
        const titleValue = getTextContentByHTML(item?.title?.value)
        const descValues = item?.valueNodes?.map((node) => getTextContentByHTML(node.value)).join('')
        const children = createTitleDescChildren(`${prefixValue}：${titleValue}`, descValues)
        return createListItem(children)
    })

    return serialize({
        type: 'bulleted-list',
        children: result,
    })
}

export const processPlateType = (dataItem) => {
    const result = dataItem.map((item) => {
        const imgUrl = getSVGImage(item.plate?.[0]?.value)
        const titleValue = getTextContentByHTML(item?.title?.value)
        const descValues = item?.valueNodes?.map((node) => getTextContentByHTML(node.value)).join('')

        return [
            { type: 'image', url: imgUrl },
            createParagraph(titleValue, true),
            createParagraph(descValues),
        ]
    })

    return serialize(result)
}
