export default {
  "WORKSPACE_1":"WORKSPACE_1",
  "WORKSPACE_2":"WORKSPACE_2",
  "WORKSPACE_3":"WORKSPACE_3",
  "WORKSPACE_4":"WORKSPACE_4",
  "WORKSPACE_5":"WORKSPACE_5",
  "WORKSPACE_6":"WORKSPACE_6",
  "WORKSPACE_7":"WORKSPACE_7",
  "WORKSPACE_8":"WORKSPACE_8",
  "WORKSPACE_9":"WORKSPACE_9",
  "WORKSPACE_10":"WORKSPACE_10",
  "WORKSPACE_11":"WORKSPACE_11",
  "WORKSPACE_12":"WORKSPACE_12",
  "WORKSPACE_13":"WORKSPACE_13",
  "WORKSPACE_15":"WORKSPACE_15",
  "WORKSPACE_16":"WORKSPACE_16",
  "WORKSPACE_17":"WORKSPACE_17",
  "WORKSPACE_18":"WORKSPACE_18",
  "WORKSPACE_19":"WORKSPACE_19",
  "WORKSPACE_20":"WORKSPACE_20",
  "WORKSPACE_21":"WORKSPACE_21",
  "WORKSPACE_22":"WORKSPACE_22",
  "WORKSPACE_23":"WORKSPACE_23",
  "WORKSPACE_24":"WORKSPACE_24",
  "WORKSPACE_25":"WORKSPACE_25",
  "WORKSPACE_26":"WORKSPACE_26",
  "WORKSPACE_27":"WORKSPACE_27",
  "WORKSPACE_28":"WORKSPACE_28",
  "WORKSPACE_29":"WORKSPACE_29",
  "WORKSPACE_30":"WORKSPACE_30",
  "WORKSPACE_31":"WORKSPACE_31",
  "WORKSPACE_32":"WORKSPACE_32",
  "WORKSPACE_33":"WORKSPACE_33",
  "WORKSPACE_34":"WORKSPACE_34",
  "WORKSPACE_35":"WORKSPACE_35",
  "WORKSPACE_36":"WORKSPACE_36",
  "WORKSPACE_37":"WORKSPACE_37",

}
