export default {
  IMAGEMODAL_01: 'All',
  IMAGEMODAL_02: 'Business Trade',
  IMAGEMODAL_03: 'Business Office',
  IMAGEMODAL_04: 'Science and Technology',
  IMAGEMODAL_05: 'Internet',
  IMAGEMODAL_06: 'Human Emotions',
  IMAGEMODAL_07: 'Education and Culture',
  IMAGEMODAL_08: 'Holidays and Festivals',
  IMAGEMODAL_09: 'Background Materials',
  IMAGEMODAL_10: 'Architecture and Space',
  IMAGEMODAL_11: 'Natural Scenery',
  IMAGEMODAL_12: 'Travel and Vacation',
  IMAGEMODAL_13: 'Food and Dining',
  IMAGEMODAL_14: 'Beauty and Wellness',
  IMAGEMODAL_15: 'Indoor Furnishings',
  IMAGEMODAL_16: 'Lifestyle',
  IMAGEMODAL_17: 'Health and Medical',
  IMAGEMODAL_18: 'Automobiles and Transportation',
  IMAGEMODAL_19: 'Others',
  IMAGEMODAL_20: 'Historical Uploads',
  IMAGEMODAL_21: 'View Only Background-Removed Images',
  'IMAGEMODAL_22': 'Template Smart Beautify',
  'IMAGEMODAL_23': 'AI Color Matching',
  'IMAGEMODAL_24': 'Unified Font',
  'IMAGEMODAL_25': 'Applying Effects...',
  'IMAGEMODAL_26': 'Apply Beautification?',
  'IMAGEMODAL_27': 'Preview not yet applied, apply beautification?',
  'IMAGEMODAL_28': 'Apply and Exit',
  'IMAGEMODAL_29': 'Confirm Exit',
  'IMAGEMODAL_30': 'Warm Tones',
  'IMAGEMODAL_31': 'Sunset Twilight',
  'IMAGEMODAL_32': 'Honey Citrus',
  'IMAGEMODAL_33': 'Orange Soda',
  'IMAGEMODAL_34': 'Rose Tea',
  'IMAGEMODAL_35': 'Hot Beach',
  'IMAGEMODAL_36': 'Champagne Rose',
  'IMAGEMODAL_37': 'Cool Tones',
  'IMAGEMODAL_38': 'Tech Blue',
  'IMAGEMODAL_39': 'Classic Blues',
  'IMAGEMODAL_40': 'Artsy Forest Green',
  'IMAGEMODAL_41': 'Vibrant Plants',
  'IMAGEMODAL_42': 'Summer Seaside',
  'IMAGEMODAL_43': 'Grape Jam',
  'IMAGEMODAL_44': 'Neutral Tones',
  'IMAGEMODAL_45': 'Vintage',
  'IMAGEMODAL_46': 'Gray-Green Cedar',
  'IMAGEMODAL_47': 'Green Veins',
  'IMAGEMODAL_48': 'Smoky Haze',
  'IMAGEMODAL_49': 'Rich Coffee',
  'IMAGEMODAL_50': 'Sea Mist',
  'IMAGEMODAL_51': 'My Colors',
  'IMAGEMODAL_52': 'Apply Effects',
  'IMAGEMODAL_53': 'Supports ppt, pptx, etc.',
  'IMAGEMODAL_54': 'Solid Fill',
  'IMAGEMODAL_55': 'No Fill',
  'IMAGEMODAL_56': 'Image Fill',
  'IMAGEMODAL_57': 'No Border',
  'IMAGEMODAL_58': 'Solid Border',
  IMAGEMODAL_59: 'ALL',
  IMAGEMODAL_60: 'Photograph',
};
