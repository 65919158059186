import { combineReducers } from 'redux'
import { createReducer } from '@reduxjs/toolkit'
import initState from '@/pages/Edit/redux/initState'
import { STATE_RESET } from '@/pages/Edit/redux/actions'

import currentFocusNodeIdSlice from '@/pages/Edit/redux/services/currentFocusNodeIdSlice'
import currentHoverNodeSlice from '@/pages/Edit/redux/services/currentHoverNodeSlice'
import svgToolSlice from '@/pages/Edit/redux/services/svgToolSlice'
import historySlice from '@/pages/Edit/redux/services/historySlice'
import onlyViewSlice from '@/pages/Edit/redux/services/onlyViewSlice'
import collaborationUsersSlice from '@/pages/Edit/redux/services/collaborationUsersSlice'
import currentThemeSlice from '@/pages/Edit/redux/services/themesSlice'
import showCommentSlice from '@/pages/Edit/redux/services/showCommentSlice'
import showRecommendSlice from '@/pages/Edit/redux/services/showRecommendSlice'
import showRecommend2Slice from '@/pages/Edit/redux/services/showRecommend2Slice'
import showAnimationSlice from '@/pages/Edit/redux/services/showAnimationSlice'
import checkedIdListSlice from '@/pages/Edit/redux/services/checkedIdListSlice'
import editModeSlice from '@/pages/Edit/redux/services/editModeSlice'
import currentSlice from './services/currentSlice'
import pptSlice from './services/pptSlice'
import pagesSlice from '@/pages/Edit/redux/services/pagesSlice'
import showHelpSlice from '@/pages/Edit/redux/services/showHelpSlice'
import hintStepSlice from '@/pages/Edit/redux/services/hintStepSlice'
import originTemplateDetailSlice from '@/pages/Edit/redux/services/originTemplateDetailSlice'
import openPPTActionSlice from '@/pages/Edit/redux/services/openPPTActionSlice'
import imageAdjustModalSlice from '@/pages/Edit/component/Toolbar/component/ImageAdjustModal/imageAdjustModalSlice'
import ContentThemeSlice from '../component/Content_new/component/right/components/ContentThemeSlice'
import pptGenerationSlice from './services/pptGenerationSlice'
import showPopupWindow from '@/pages/Edit/redux/services/showPopupWindow'
import chartsColorSlice from './services/chartsColorSlice'
import animationSlice from './services/animationSlice'
import animationProgressSlice from './services/animationProgressSlice'
import animakerSlice from './services/animakerSlice'
import resizeText from './services/resizeText'
import currentFocusNodeUUIDSlice from './services/currentFocusNodeUUIDSlice'
import newEditSlice from './services/newEditSlice'

const combineReducer = combineReducers({
    current: currentSlice.reducer,
    ppt: pptSlice.reducer,
    pages: pagesSlice?.reducer,
    currentFocusNodeId: currentFocusNodeIdSlice.reducer,
    currentFocusNodeUUID: currentFocusNodeUUIDSlice.reducer,
    showAnimation: showAnimationSlice.reducer,
    currentHoverNode: currentHoverNodeSlice.reducer,
    svgTool: svgToolSlice.reducer,
    history: historySlice.reducer,
    onlyView: onlyViewSlice.reducer,
    collaborationUsers: collaborationUsersSlice.reducer,
    themes: currentThemeSlice.reducer,
    showComment: showCommentSlice.reducer,
    showHelp: showHelpSlice.reducer,
    showRecommend: showRecommendSlice.reducer,
    showRecommend2: showRecommend2Slice.reducer,
    checkedIdList: checkedIdListSlice.reducer,
    editMode: editModeSlice.reducer,
    hintStep: hintStepSlice.reducer,
    originTemplateDetail: originTemplateDetailSlice.reducer,
    openPPTAction: openPPTActionSlice.reducer,
    canvasImage: imageAdjustModalSlice,
    customTheme: ContentThemeSlice,
    pptGeneration: pptGenerationSlice.reducer,
    popupWindow: showPopupWindow.reducer,
    chartsColor: chartsColorSlice.reducer,
    animation: animationSlice.reducer,
    animationProgress: animationProgressSlice.reducer,
    animaker: animakerSlice.reducer,
    resizeText: resizeText.reducer,
    newEdit: newEditSlice.reducer,
})

const reducer = createReducer(initState, (builder) => {
    builder
        .addCase(STATE_RESET, (state, action) => {
            return initState
        })
        .addDefaultCase(combineReducer)
})

export default reducer
