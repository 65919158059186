import React, { useMemo } from 'react';
import { Divider, Space } from 'antd';
import { INodeType } from '@/pages/Edit/type/INode';
import { coverNodeType, getCoverNodeTypeByNode, getCoverNodeTypeText, getDataByPage, getTransitionData } from '@/pages/Edit/common/node/nodemark2';
import getNodes from '@/pages/Edit/common/node/getNodes';


export const getTextByNode = (node) => {
  if (!node) return "";
  return getTextByValue(node.value);
};
export const getTextByValue = (value = '') => {
  const dom = new DOMParser().parseFromString(value, 'text/html');
  return (dom.body.textContent || '').replace(/\n/g, "")
};
export const CoverNodeMarkRender = (props) => {
  const { page, theme } = props;
  const data = getDataByPage(page, theme);
  return <>
    <Space direction='vertical'>
      <div>{getCoverNodeTypeText(coverNodeType.title)}：<span>{getTextByNode(data.title)}</span></div>
      <div>{getCoverNodeTypeText(coverNodeType.des)}：<span>{getTextByNode(data.des)}</span></div>
      <div>{getCoverNodeTypeText(coverNodeType.author)}：<span>{getTextByNode(data.author)}</span></div>
      <div>{getCoverNodeTypeText(coverNodeType.date)}：<span>{getTextByNode(data.date)}</span></div>
    </Space>
  </>;
};
export const TransitionNodeMarkRender = (props) => {
  const { page, theme } = props;
  const nodes = getNodes({ page, theme: theme });
  const nodeMark = page.nodeMark;
  const partNum = nodeMark?.partNum?.map((id) => nodes[id]);
  const data = getTransitionData(page, theme);
  return <>
    <Space direction='vertical'>
      <div>标题：<span>{getTextByNode(data.title)}</span></div>
      {partNum?.map((item) => !!item && <div key={item?.id}>序号：{getTextByNode(item)}</div>)}
    </Space>
  </>;
};
export const MainNodeMarkRender = (props) => {
  const { page, theme } = props;
  const data = getTransitionData(page, theme);
  return <>
    <Space direction='vertical' style={{ marginTop: 20 }}>
      <div>标题：<span>{getTextByNode(data.title)}</span></div>
      <div>正文：<span>{getTextByNode(data.des)}</span></div>
      <div>
        <div>列表:</div>
        {
          data.list?.map((item) => {
            let svg;
            let src;
            let node = item.plate?.[0] || {};
            if (node.type === INodeType.svg) {
              svg = node.value;
            } else if (node.type === INodeType.image) {
              src = node.value;
            }

            return <div style={{ padding: '10px 20px' }}>
              <div>标题:{getTextByNode(item.title)}</div>
              <div>描述:{getTextByNode(item.valueNodes[0])}</div>
              <div>图片:
                {src && <div style={{ width: 40, height: 40, backgroundImage: src ? `url(${src})` : undefined, backgroundSize: 'cover' }} />}
                {svg && <div style={{ width: 40, height: 40, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: svg }} />}
              </div>
            </div>;
          })
        }
      </div>
      <div>
        <div>图片:</div>
        {
          data.plate?.map((node) => {
            let svg;
            let src;
            if (node.type === INodeType.svg) {
              svg = node.value;
            } else if (node.type === INodeType.image) {
              src = node.value;
            }

            return <div style={{ padding: '10px 20px' }}>
              {src && <div style={{ width: 40, height: 40, backgroundImage: src ? `url(${src})` : undefined, backgroundSize: 'cover' }} />}
              {svg && <div style={{ width: 40, height: 40, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: svg }} />}
            </div>;
          })
        }
      </div>
    </Space>
  </>;
};


export const Content = (props) => {
  const { nodeMark, nodes } = props;
  const titleId = nodeMark.pageTitle;
  const describeId = nodeMark.describe && nodeMark.describe[0];

  const titleNode = nodes[titleId];
  const mainNode = nodes[describeId];
  const pointNodeArr = nodeMark.point?.map?.((item) => {
    return {
      title: nodes[item?.title?.id],
      valueNodes: item.valueNodes?.map?.((i) => nodes[i?.id]),
      plate: item.plate?.map?.((id) => nodes[id]),
    };
  }).filter((item) => {
    return item?.title;
  });
  const plateNodeArr = nodeMark.plate?.map?.((id) => nodes[id]);
  const chartNodeArr = nodeMark.charts?.map?.((id) => nodes[id]);


  const getTextByNode = (node) => {
    if (node) {
      const dom = new DOMParser().parseFromString(node.value, 'text/html');
      return dom.body.innerText;
    }
  };


  return <div>
    <Space direction='vertical' style={{ marginTop: 20 }}>
      <div>标题：<span>{getTextByNode(titleNode)}</span></div>
      <div>正文：<span>{getTextByNode(mainNode)}</span></div>
      <div>
        <div>列表:</div>
        {
          pointNodeArr?.map((item) => {
            let svg;
            let src;
            let node = item.plate?.[0] || {};
            if (node.type === INodeType.svg) {
              svg = node.value;
            } else if (node.type === INodeType.image) {
              src = node.value;
            }

            return <div style={{ padding: '10px 20px' }}>
              <div>标题:{getTextByNode(item.title)}</div>
              <div>描述:{getTextByNode(item.valueNodes[0])}</div>
              <div>图片:
                {src && <div style={{ width: 40, height: 40, backgroundImage: src ? `url(${src})` : undefined, backgroundSize: 'cover' }} />}
                {svg && <div style={{ width: 40, height: 40, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: svg }} />}
              </div>
            </div>;
          })
        }
      </div>
      <div>
        <div>图片:</div>
        {
          plateNodeArr?.map((node) => {
            let svg;
            let src;
            if (node.type === INodeType.svg) {
              svg = node.value;
            } else if (node.type === INodeType.image) {
              src = node.value;
            }

            return <div style={{ padding: '10px 20px' }}>
              {src && <div style={{ width: 40, height: 40, backgroundImage: src ? `url(${src})` : undefined, backgroundSize: 'cover' }} />}
              {svg && <div style={{ width: 40, height: 40, overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: svg }} />}
            </div>;
          })
        }
      </div>
    </Space>
  </div>;
};

export default (props) => {
  const { page, theme } = props;
  const render = () => {
    switch (page.type) {
      case 'cover': {
        return <CoverNodeMarkRender page={page} theme={theme} />;
      }
      case 'transition': {
        return <TransitionNodeMarkRender page={page} theme={theme} />;
      }
      case 'main': {
        return <MainNodeMarkRender page={page} theme={theme} />;
      }
      case 'other': {
        return <MainNodeMarkRender page={page} theme={theme} />;
      }
    }
  };
  return <Space direction='vertical'>
    <div>id：{page.id}</div>
    <div>pageId：{page.originId}</div>
    <div>pandaId：{page.pandaId}</div>
    <div>templateID：{page.pageTemplateId}</div>
    <Space direction='vertical'>
      <div>nodeMarkAnalysis：</div>
      {
        page?.nodeMarkAnalysis?.map((obj) => {
          return Object.keys(obj).map((key) => {
            return <div key={key}>{key}：{obj[key]}</div>;
          });
        })
      }
    </Space>
    {render()}
  </Space>;
}
