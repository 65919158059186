export default {
  PAY_1: 'LITE Features',
  PAY_27: 'PRO Features',
  PAY_2: 'PPT DL Limit',//跟中文语序不一样，应该是XX Downloads a Month
  PAY_3: 'AI ChatGPT',//XX Pg ChatGPT a Month
  PAY_4: 'Single PPT Pg Limit',//XX Pg per Slides
  PAY_5: 'Workstation Edit File Count',//XX Files for Workspace
  PAY_6: 'Recycle Bin Keep',//XX D Retention for Recycle Bin
  PAY_7: 'File DL Record',//XX D Downloads History
  PAY_8: 'File Upload Space',//XX GB of Upload Space
  PAY_9: 'Collab Design Invite ',//XX Ppl for Collab Design
  PAY_10: 'Exclusive Templates',
  PAY_11: 'Priority Support',
  PAY_12: 'Plugin (Coming Soon)',
  PAY_13: 'Future Adv Features',
  PAY_14: 'Compare Features',
  PAY_15: 'Limited Time 90% Off',
  PAY_16: 'Limited Time 80% Off',
  PAY_17: 'Best Choice for Trial',
  PAY_18: 'Lifetime',
  PAY_19: 'Yearly',
  PAY_20: 'Monthly',
  PAY_21: 'Original Price',
  PAY_22: 'Total Charge',
  PAY_23: 'Discount',
  PAY_24: 'Payment Implies Agreement to ',
  PAY_25: 'If Any Questions,',
  PAY_26: 'Contact Customer Service',
  PAY_28: 'time',//英文单位应该不能直接这样翻译，调整下语序后可能不需要这个字段？
  PAY_29: 'M',//英文单位应该不能直接这样翻译，调整下语序后可能不需要这个字段？
  PAY_30: 'Pg',//英文单位应该不能直接这样翻译，调整下语序后可能不需要这个字段？
  PAY_31: '',//这个“个”很难翻译啊，应该用不上了
  PAY_32: 'D',//英文单位应该不能直接这样翻译，调整下语序后可能不需要这个字段？
  PAY_33: 'Ppl',//英文单位应该不能直接这样翻译，调整下语序后可能不需要这个字段？
  PAY_34: 'Limited Time 60% Off',
  PAY_35: 'Lifetime',
  PAY_36: 'Yearly',
  PAY_37: 'Monthly',
  PAY_38: 'PRO',
  PAY_39: 'LITE',
  PAY_40: 'Membership',
  PAY_41: 'Agreement',
  PAY_42: '￥',
  PAY_43: '3-Year VIP',
  PAY_44: '3-Year SVIP',
  PAY_45: '3-Year VIP',
  PAY_46: 'Adapt to AI in the workplace or be replaced by it',
  PAY_47: 'Upgrade to SVIP Membership'
};
