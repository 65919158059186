const list = {
    "powerPointTitle": "自我成长的三个关键阶段",
    "pageListRow": [
        {
            "nodeMark": {
                "pageTitle": [
                    "08cd7f2e-8504-454c-a5a5-38a0e84e438a"
                ]
            },
            "nodes": {
                "08cd7f2e-8504-454c-a5a5-38a0e84e438a": {
                    "id": "08cd7f2e-8504-454c-a5a5-38a0e84e438a",
                    "type": "text",
                    "value": "自我成长的三个关键阶段"
                }
            },
            "sort": 1,
            "type": "cover"
        },
        {
            "nodeMark": {
                "pageTitle": [
                    "e33cbf58-c7b5-42f6-8efe-5cf1a7f502a3"
                ]
            },
            "nodes": {
                "e33cbf58-c7b5-42f6-8efe-5cf1a7f502a3": {
                    "id": "e33cbf58-c7b5-42f6-8efe-5cf1a7f502a3",
                    "type": "text",
                    "value": "第一部分：发现自我"
                }
            },
            "sort": 2,
            "type": "transition"
        },
        {
            "nodeMark": {
                "pageTitle": "7c71fb44-9cce-4b69-8f19-74c68a8e5bb4",
                "describe": [
                    "0eb1a991-1213-431b-bcdc-979505d03e30"
                ]
            },
            "nodes": {
                "0eb1a991-1213-431b-bcdc-979505d03e30": {
                    "id": "0eb1a991-1213-431b-bcdc-979505d03e30",
                    "type": "text",
                    "value": "自我认知是自我成长的基础。通过定期反思自己的思维、情感和行为，我们可以更好地了解自己的内在世界。自我反思和探索有助于明确我们的目标和价值观，塑造我们的身份。"
                },
                "7c71fb44-9cce-4b69-8f19-74c68a8e5bb4": {
                    "id": "7c71fb44-9cce-4b69-8f19-74c68a8e5bb4",
                    "type": "text",
                    "value": "1.1 自我认知的重要性"
                }
            },
            "sort": 3,
            "type": "main"
        },
        {
            "nodeMark": {
                "pageTitle": "a22123a9-2089-4537-87b8-e2169b6a97e6",
                "describe": [
                    "fd028ae4-dc7c-46bb-9304-b82902f72fa1"
                ]
            },
            "nodes": {
                "a22123a9-2089-4537-87b8-e2169b6a97e6": {
                    "id": "a22123a9-2089-4537-87b8-e2169b6a97e6",
                    "type": "text",
                    "value": "1.2 探索兴趣和爱好"
                },
                "fd028ae4-dc7c-46bb-9304-b82902f72fa1": {
                    "id": "fd028ae4-dc7c-46bb-9304-b82902f72fa1",
                    "type": "text",
                    "value": "探索兴趣和爱好是发现自己的激情和热情所在的关键。当我们投入时间去尝试各种不同的活动和领域时，我们更有可能发现自己热衷的事物。这些热情可以成为我们自我成长的动力，驱使我们朝着目标前进。"
                }
            },
            "sort": 4,
            "type": "main"
        },
        {
            "nodeMark": {
                "pageTitle": [
                    "75042047-5f9f-4b96-860e-2f2fff36d409"
                ]
            },
            "nodes": {
                "75042047-5f9f-4b96-860e-2f2fff36d409": {
                    "id": "75042047-5f9f-4b96-860e-2f2fff36d409",
                    "type": "text",
                    "value": "第二部分：不断进步"
                }
            },
            "sort": 5,
            "type": "transition"
        },
        {
            "nodeMark": {
                "pageTitle": "64c33750-4202-4c15-8a29-7bf3eb986379",
                "describe": [
                    "cb51759c-ae81-46b8-bd91-c73468cf107a"
                ]
            },
            "nodes": {
                "64c33750-4202-4c15-8a29-7bf3eb986379": {
                    "id": "64c33750-4202-4c15-8a29-7bf3eb986379",
                    "type": "text",
                    "value": "2.1 坚持不懈的关键性"
                },
                "cb51759c-ae81-46b8-bd91-c73468cf107a": {
                    "id": "cb51759c-ae81-46b8-bd91-c73468cf107a",
                    "type": "text",
                    "value": "坚持不懈是实现自我成长的关键。无论面对多大的挑战，都需要坚守自己的目标。在生活中的各个领域，坚定地追求自己的目标有助于我们克服困难，不断前进。"
                }
            },
            "sort": 6,
            "type": "main"
        },
        {
            "nodeMark": {
                "pageTitle": "16596218-dc77-4e25-9122-4b9dd5bf621f",
                "describe": [
                    "2633701e-e951-4fc8-8c1c-89babe867660"
                ]
            },
            "nodes": {
                "16596218-dc77-4e25-9122-4b9dd5bf621f": {
                    "id": "16596218-dc77-4e25-9122-4b9dd5bf621f",
                    "type": "text",
                    "value": "2.2 持续学习和成长"
                },
                "2633701e-e951-4fc8-8c1c-89babe867660": {
                    "id": "2633701e-e951-4fc8-8c1c-89babe867660",
                    "type": "text",
                    "value": "持续学习新的技能、知识和经验是自我成长的动力之一。这可以通过不断探索新领域、参加培训课程、阅读书籍或与专家交流来实现。学习不仅帮助我们在个人和职业生活中不断进步，还使我们更能适应不断变化的环境。"
                }
            },
            "sort": 7,
            "type": "main"
        },
        {
            "nodeMark": {
                "pageTitle": [
                    "d0966b7d-f6cc-40bb-8dc4-09e1ebd47ee6"
                ]
            },
            "nodes": {
                "d0966b7d-f6cc-40bb-8dc4-09e1ebd47ee6": {
                    "id": "d0966b7d-f6cc-40bb-8dc4-09e1ebd47ee6",
                    "type": "text",
                    "value": "第三部分：分享与启发"
                }
            },
            "sort": 8,
            "type": "transition"
        },
        {
            "nodeMark": {
                "pageTitle": "53378e14-59bd-44fd-851f-070032ff0616",
                "describe": [
                    "b5f4afda-4554-494b-979e-1abdb2544644"
                ]
            },
            "nodes": {
                "b5f4afda-4554-494b-979e-1abdb2544644": {
                    "id": "b5f4afda-4554-494b-979e-1abdb2544644",
                    "type": "text",
                    "value": "与他人分享知识和经验，并提供支持，是自我成长的关键组成部分。帮助他人实现自我成长，可以建立有益的关系，同时也能促进我们自己的成长。"
                },
                "53378e14-59bd-44fd-851f-070032ff0616": {
                    "id": "53378e14-59bd-44fd-851f-070032ff0616",
                    "type": "text",
                    "value": "3.1 关心他人的成长"
                }
            },
            "sort": 9,
            "type": "main"
        },
        {
            "nodeMark": {
                "pageTitle": "b7179689-a92c-409d-aab0-1b04a3c508fb",
                "describe": [
                    "c3b53cfa-9eb0-4422-b774-fceeeadee7c8"
                ]
            },
            "nodes": {
                "c3b53cfa-9eb0-4422-b774-fceeeadee7c8": {
                    "id": "c3b53cfa-9eb0-4422-b774-fceeeadee7c8",
                    "type": "text",
                    "value": "成为榜样是启发他人的重要方式。通过自己的行为和成就，我们可以激励他人朝着自己的目标努力。在成为榜样的过程中，我们也会不断提高自己，实现更大的成长。自我成长的三个关键阶段"
                },
                "b7179689-a92c-409d-aab0-1b04a3c508fb": {
                    "id": "b7179689-a92c-409d-aab0-1b04a3c508fb",
                    "type": "text",
                    "value": "3.2 成为榜样"
                }
            },
            "sort": 10,
            "type": "main"
        }
    ]
}
export default list



export const pageData = {
    "outline": [
        {
            "pageIndex": "1",
            "title": "工作总结",
            "type": "封面页面"
        },
        {
            "pageIndex": "2",
            "title": "章节1",
            "type": "过渡页"
        },
        {
            "pageIndex": "3",
            "title": "工作亮点",
            "type": "列表页面"
        },
        {
            "pageIndex": "4",
            "title": "团队合作",
            "type": "列表页面"
        },
        {
            "pageIndex": "5",
            "title": "项目成果",
            "type": "图文页面"
        },
        {
            "pageIndex": "6",
            "title": "章节2",
            "type": "过渡页"
        },
/*        {
            "pageIndex": "7",
            "title": "挑战与解决",
            "type": "列表页面"
        },
        {
            "pageIndex": "8",
            "title": "个人成长",
            "type": "图表页面"
        },
        {
            "pageIndex": "9",
            "title": "展望未来",
            "type": "图文页面"
        },
        {
            "pageIndex": "10",
            "title": "章节3",
            "type": "过渡页"
        },
        {
            "pageIndex": "11",
            "title": "市场分析",
            "type": "图表页面"
        },
        {
            "pageIndex": "12",
            "title": "竞争优势",
            "type": "列表页面"
        },*/
/*        {
            "pageIndex": "13",
            "title": "市场策略",
            "type": "图文页面"
        },
        {
            "pageIndex": "14",
            "title": "章节4",
            "type": "过渡页"
        },
        {
            "pageIndex": "15",
            "title": "销售数据",
            "type": "图表页面"
        },
        {
            "pageIndex": "16",
            "title": "客户反馈",
            "type": "列表页面"
        },
        {
            "pageIndex": "17",
            "title": "市场推广",
            "type": "图文页面"
        },
        {
            "pageIndex": "18",
            "title": "章节5",
            "type": "过渡页"
        },
        {
            "pageIndex": "19",
            "title": "团队建设",
            "type": "列表页面"
        },
        {
            "pageIndex": "20",
            "title": "项目管理",
            "type": "图表页面"
        },
        {
            "pageIndex": "21",
            "title": "团队成果",
            "type": "图文页面"
        },
        {
            "pageIndex": "22",
            "title": "章节6",
            "type": "过渡页"
        },
        {
            "pageIndex": "23",
            "title": "总结与展望",
            "type": "列表页面"
        },
        {
            "pageIndex": "24",
            "title": "感谢与致辞",
            "type": "图文页面"
        },*/
        {
            "pageIndex": "25",
            "title": "致谢",
            "type": "致谢页面"
        }
    ],
    "powerPointTitle": "工作总结"
}
