export default {
  ECHART_1: 'histogram',
  ECHART_2: 'line chart',
  ECHART_3: 'pie Chart',
  ECHART_4: 'Edit chart',
  ECHART_5: 'insert chart',
  ECHART_6: 'Attribute setting',
  ECHART_7: 'title',
  ECHART_8: 'Display title',
  ECHART_9: 'cutline',
  ECHART_10: 'Legend position',
  ECHART_11: 'import data',
  ECHART_12: 'import data',
  ECHART_13: 'Numerical display',
  ECHART_14: 'Insert left column',
  ECHART_15: 'Insert right column',
  ECHART_16: 'delete columns',
  ECHART_17: 'Edit table',
  ECHART_18: 'Insert Table',
  ECHART_19: 'Title Content',
  ECHART_20: 'None',
  ECHART_21: 'Top',
  ECHART_22: 'Right',
  ECHART_23: 'Bottom',
  ECHART_24: 'Left',
  ECHART_25: 'Top Right',
  ECHART_26: 'Manual Adjustment',
  ECHART_27: 'Recommended',
  ECHART_28: 'AI Chat',
  ECHART_29: 'Confirm',
  ECHART_30: 'Cancel',
  ECHART_31: 'External',
  ECHART_32: 'Internal',
  ECHART_33: 'Hi, I am the AI Assistant. What would you like to do with the "chart"?',
  ECHART_34: 'would you like to make change?',
  ECHART_35: 'Add Rate of Change Curve',
  ECHART_36: 'Set the left axis minimum value to 1000 and display the specific numbers',
  ECHART_37: 'Show the legend and place it below the chart',
  ECHART_38: 'You can enter requests similar to the following:',
  ECHART_39: 'Show the legend and place it below the chart',
  ECHART_40: 'You can enter requests similar to the following:',
  ECHART_41: "Modifications may take time, it's recommended to give all modification requirements at once...",
  ECHART_42: 'Modifying Style...',
  ECHART_43: 'Before Modification',
  ECHART_44: 'After Modification:',
  ECHART_47: 'Move One Column to the Right',
  ECHART_48: 'Move One Column to the Left',
  ECHART_49: 'Delete Column',
  ECHART_50: 'Data',
  ECHART_51: 'Chart',
  ECHART_52: 'Title Content:',
  ECHART_53: 'Sales Revenue (in 10,000 yuan)',
  ECHART_54: 'Year',
  ECHART_55: 'Delete Row',
  ECHART_56: 'Move Row Down',
  ECHART_57: 'Move Row Up',
  ECHART_58: 'Insert Row Below',
  ECHART_59: 'Insert Row Above',
  ECHART_60: 'Delete Column',
  ECHART_61: 'Default Data',
  ECHART_62: 'Project Name {res}',


};
