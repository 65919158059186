export default {
    FileUpload_01: '自由输入',
    FileUpload_02: 'AI生成达到使用限制，请升级',
    FileUpload_03: '升级',
    FileUpload_04: '添加或粘贴内容',
    FileUpload_05: '在此处添加或粘贴您的内容，可以是简单的主题、项目符号、大纲或整个文档',
    FileUpload_06: '支持添加或粘贴 8000 字以内的内容（无格式要求）',
    FileUpload_07: '注：会智能缩写、拆解要点，并消耗AI使用量',
    FileUpload_08: '生成失败',
    FileUpload_09: '请认真阅读以下说明，并按照要求上传文件',
    FileUpload_10: '非格式上传',
    FileUpload_11: '支持 8000 字以内word文档上传（无格式要求）。但上传后内容会智能缩写、拆解要点',
    FileUpload_12: '格式上传',
    FileUpload_13: '请先',
    FileUpload_14: '下载Word导入说明.docx',
    FileUpload_15: '按要求填写后上传。格式上传的内容不会智能缩写',
    FileUpload_16: '下载Markdown导入说明.md',
    FileUpload_17: '按要求填写后上传',
    FileUpload_18: '下载Freemind导入说明.mm',
    FileUpload_19: '将文件拖拽至此区域或',
    FileUpload_20: '上传中',
    FileUpload_21: '我的上传',
    FileUpload_22: '按要求填写后上传',
    FileUpload_23: '选择文件上传',
    FileUpload_24: '暂无内容',
    FileUpload_25: '请上传后缀名为.docx或.doc的word文件',
    FileUpload_26: '请上传后缀名为.pptx或.ppt的ppt文件',
    FileUpload_27: '请上传后缀名为.md',
    FileUpload_28: '请上传后缀名为.mm',
    FileUpload_29: '格式选择',
    FileUpload_30: '支持粘贴自己的文本、图片等',
    FileUpload_31: '上传Word',
    FileUpload_32: '支持doc、docx等格式文件',
    FileUpload_33: '支持 Markdown 的格式文件',
    FileUpload_34: '幕布-Freemind',
    FileUpload_35: '支持 Freemind 的格式文件',
    FileUpload_36: '将文件拖拽至此区域或',
    FileUpload_37: '上传',
    FileUpload_38: '支持mm格式文件',
    FileUpload_39: '上传Word等文件',
    FileUpload_40: '支持doc、docx、pdf、txt、md等格式文件',
}
