import { buildMetadata } from '../utils';

// isPage: 是否不需要检查文档page中是否定义数据
export const getKeyBase = (keyName, isPage = false) => ({
  getMetadata: (props) => {
    const { options, page, illustrationImage } = props;
    if (!_.get(options, `${keyName}.value`)) {
      return null;
    }
    if (!isPage && !_.get(page, keyName)) {
      return null;
    }
    let metadata;
    if (!illustrationImage) {
      metadata = buildMetadata(options[keyName], page[keyName])(options);
    } else {
      metadata = buildMetadata(options[keyName], page[keyName])(options);
      metadata.value.image.value = illustrationImage;
    }
    metadata.valueKeys = metadata.valueKeys.filter(
      (d) => !!_.get(metadata, `value.${d}.norm`),
    );
    return metadata;
  },

  getDocdata: () => ({}),
  d2mUpdate: () => [],
  d2mDelete: () => [],
});
