import { detailClickButton } from '@/service/services'

export function getParam(name) {
    const url = window.location.href
    const urlObj = new URL(url)
    return urlObj.searchParams.get(name) || ''
}

// 详情页上传log
export const detailPageUploadLog = () => {
    const url = new URL(window.location.href)
    if (url.pathname.includes('detail')) {
        const id = getParam('id')
        detailClickButton({
            themeId: id,
            buttonName: 'upload',
        })
    }
}

export const log = (...args) => {
    if (__DEV__) {
        console.log('%c[debug]:', 'color: blue; font-weight: bold;', ...args)
    }
}
