import { createSlice } from '@reduxjs/toolkit';
import initState from '@/pages/Edit/redux/initState';


export default createSlice({
  name: 'editMode',
  initialState: initState.editMode,
  reducers: {
    set(state, { payload }) {
      return payload;
    },
  },
});
