import modelExtend from 'dva-model-extend';
import { model } from '@/utils/model';
import { message } from 'antd';
import {
  deletePageById,
  getPageById,
  getPagesByPptxId,
  getPptxById,
  getTemplateById,
  getThemeById,
  savePage,
  requestUpdateSort,
} from '@/service/services';
import * as pptx from '@/utils/pptx';
import { needBr } from '@/utils/pptx';
import { jsonClone, jsonTo, lstoreGet } from '../../src/web-utils/index';
import { fetchTemplateById } from '@/models/pptxTemplate';
import { getDvaApp } from 'umi';

export default modelExtend(model, {
  namespace: 'pptxEditor',
  state: {
    loading: false,
    editorMode: undefined,
    // ['design', 'context'].indexOf(lstoreGet('editting_type')) !== -1
    //   ? lstoreGet('editting_type')
    //   : 'design',
    error: null,
    // pptx
    data: {},
    // 每页
    pagesError: null,
    pagesLoading: false,
    pagesTotal: 0,
    byPageId: {},
    allPageIds: [],
    // 当前正在编辑的页数据
    editingPageId: lstoreGet('editting_page') || 0,

    // optionsByPageId
    obpId: {},

    // 记录保存的状态
    saveStatus: {},
    // 记录「缩略图」生成的标记
    thumbnailStatus: {
      // [pageId]: _.uniqueId('thumbnail-'),
    },
  },
  subscriptions: {},
  effects: {
    *fetchPPTById({ payload }, { call, put }) {
      const data = yield call(getPptxById, { id: payload });
      data.customTheme = jsonTo(data.customTheme);
      yield put({ type: 'stateAny', payload: { data } });
      return data;
    },
    *getById({ payload }, { call, put }) {
      yield put({
        type: 'stateAny',
        payload: { error: false, loading: true },
      });
      const state = { error: null, data: null, loading: false };
      try {
        const data = yield call(getPptxById, { id: payload });
        data.customTheme = jsonTo(data.customTheme);
        if (_.isObject(data)) {
          state.data = data;
        } else {
          throw new Error(`Not Found`);
        }
      } catch (error) {
        state.error = error;
      }
      yield put({
        type: 'stateAny',
        payload: state,
      });
    },
    *getPageById({ payload }, { call, put }) {
      try {
        const data = yield call(getPageById, { id: payload });
        const singlePage = pptx.dataPageFromApi(data);
        if (singlePage) {
          yield put({
            type: 'dataPagesParser',
            payload: { singlePage },
          });
        } else {
          throw new Error(`Not Found`);
        }
      } catch (error) {
        console.error(error);
      }
    },
    *getPagesByPptxId({ payload }, { call, put }) {
      yield put({
        type: 'stateAny',
        payload: { pagesError: false, pagesLoading: true },
      });
      const state = { error: null, data: null, loading: false };
      try {
        const data = yield call(getPagesByPptxId, payload);
        data.dataList = (data.dataList || [])
          .map((item) => pptx.dataPageFromApi(item))
          .sort((a, b) => a.sort - b.sort);
        if (_.isObject(data)) {
          state.data = data;
        } else {
          throw new Error(`:Not Found`);
        }
      } catch (error) {
        state.error = error;
      }
      yield put({
        type: 'dataPagesParser',
        payload: state,
      });
    },
    *getNewWordByPptxId({ payload }, { call, put, select }) {
      const { pptId, limit, page, saveObj } = payload;
      const obj = { pptId, limit, page };
      const { updateGuardPageById, id, pageObj } = saveObj;
      yield put({
        type: 'stateAny',
        payload: { pagesError: false, pagesLoading: true },
      });
      const state = { error: null, data: null, loading: false };
      try {
        const data = yield call(getPagesByPptxId, obj);
        data.dataList = (data.dataList || [])
          .map((item) => pptx.dataPageFromApi(item))
          .sort((a, b) => a.sort - b.sort);
        if (_.isObject(data)) {
          state.data = data;
        } else {
          throw new Error(`:Not Found`);
        }
      } catch (error) {
        state.error = error;
      }
      yield put({
        type: 'dataPagesParser',
        payload: state,
      });
      if (updateGuardPageById) {
        const byPageId = yield select((state) => state.pptxEditor.byPageId);
        const dataPage = yield select(
          (state) =>
            state.pptxEditor.byPageId[id || state.pptxEditor.editingPageId],
        );
        //请求新的模板
        let selectedState = yield select((_) => _);
        const dataPPT = yield select((state) =>
          _.get(state, 'pptxEditor.data'),
        );
        let theme = yield select((state) =>
          _.get(state, `pptxTheme.byId.${dataPPT.themeId}`),
        );
        //先拿到这个pptxEditor的数据然后转化成wordEditor的样子
        let newPageData = _.cloneDeep(
          _.get(selectedState.pptxEditor, `byPageId[${id}]`),
        );
        let { templateId } = newPageData;
        if (!templateId) {
          templateId = 66;
        }
        //模板先请求一下
        let template = yield select((state) =>
          _.get(state, `pptxTemplate.byId.${templateId}`),
        );
        if (!template) {
          const d = yield call(getTemplateById, { id: templateId });
          const noBase = false;
          if (!d) {
            return null;
          }
          const item = jsonTo(d.data);
          if (!item) {
            return null;
          }
          item.id = d.id;
          item.name = d.name;
          item.type = d.type;
          item.info = d.description;
          item.component = d.component;
          item.createTime = d.createTime;

          if (noBase) {
            return item;
          }
          const templateBase = _.get(
            pptx.dpUtils,
            `${item.component}.templateBase`,
          );
          template = templateBase
            ? pptx.buildOptions(templateBase(), item)
            : item;
          if (!template) {
            message.error('加载模板数据错误！');
            return;
          }
          yield put({
            type: 'pptxTemplate/parseById',
            payload: template,
          });
        }

        //转换数据
        let pageData = pptx.metadataToDocdata(
          theme,
          template,
          dataPPT,
          newPageData,
        );
        _.mapKeys(pageData, (value, key) => {
          if (!_.isString(value)) {
            pageData[key] = JSON.stringify(value);
          }
          pageData.pageId = id;
          pageData.pptId = pptId || payload.pptId;
          if (dataPage.templateId) {
            pageData.templateId = dataPage.templateId || 0;
          }
        });
        const newPage = yield call(savePage, {
          page: { id, ...pageObj },
          pptContent: pageData,
        });
        //更换id后保存
        dataPage.templateId = newPage.templateId;
        dataPage.type = newPage.type;
        byPageId[id] = dataPage;
        yield put({
          type: 'stateAny',
          payload: {
            byPageId,
          },
        });
      }
    },
    *updatePageById_new({ payload: id }, { call, put, select }) {
      const page = yield select((state) => state.pptxEditor.byPageId[id]);
      const pptxEditor = yield select((state) => state.pptxEditor);
      if (!page) return;
      let parsePage = pptx.dataPageToSave(page);
      parsePage.id = page.id;
      parsePage.type = page.type;
      yield call(savePage, { page: parsePage, pptContent: {} });
      pptxEditor.byPageId[id] = { ...page };
      yield put({ type: 'stateAny' });
    },
    *updatePageById({ payload }, { call, put, select }) {
      if (!payload) {
        return;
      }
      const dataPage = yield select((state) => {
        return state.pptxEditor.byPageId[
          _.get(payload, 'id') || state.pptxEditor.editingPageId
        ];
      });
      if (!dataPage) {
        payload = null;
        return;
      } else {
        payload = pptx.dataPageToSave(dataPage, _.get(payload, 'payload.keys'));
        payload.id = dataPage.id;
        payload.type = dataPage.type;
      }
      yield put({
        type: 'stateAny',
        payload: { saveStatus: { date: Date.now(), key: 'Saving' } },
      });
      const state = { saveStatus: { date: Date.now(), key: 'Saved' } };
      try {
        const page = yield call(savePage, { page: payload, pptContent: {} });
        if (!page.id) {
          state.saveStatus = { date: Date.now(), key: 'UnSaved' };
        } else {
          state.thumbnailStatus = { [page.id]: _.uniqueId('thumbnail-') };
        }
      } catch (error) {
        state.saveStatus = { date: Date.now(), key: 'UnSaved' };
      }
      yield put({
        type: 'stateAny',
        payload: state,
      });
    },
    *updatePageByIdWithReplaceTemplate({ payload }, { call, put, select }) {
      const page = yield call(updatePageByIdWithReplaceTemplate, payload);
      yield put({
        type: 'stateAny',
        payload: {},
      });
    },
    *updateGuardPageById({ payload }, { call, put, select }) {
      if (!payload) return;
      const { id, pageObj, updateObj } = payload;
      if (!id || !pageObj || !updateObj) return;
      const pptxEditor = yield select((state) => state.pptxEditor);
      const pptxTheme = yield select((state) => state.pptxTheme);
      const pptxTemplate = yield select((state) => state.pptxTemplate);
      const dataPPT = pptxEditor.data;
      const pptId = dataPPT.id;
      const page = pptxEditor.byPageId[id];
      let templateId = page.templateId || 66;
      const template = pptxTemplate.byId[templateId];
      if (!page) return;
      if (!template) {
        yield call(fetchTemplateById, templateId);
      }
      {
        page.Index = updateObj.Index;
        page.Catalogue = updateObj.Catalogue;
        page.List = updateObj.List;
        page.describe = updateObj.describe;
        page.footer = updateObj.footer;
        page.plate = updateObj.plate;
        page.header = updateObj.header;
        page.Transition = updateObj.Transition;
        page.charts = updateObj.charts;
        page.templateId = updateObj.templateId;
        page.type = updateObj.type;
      }
      let theme = pptxTheme.byId[dataPPT.themeId];
      let pageData = pptx.metadataToDocdata(theme, template, dataPPT, page);
      _.mapKeys(pageData, (value, key) => {
        if (!_.isString(value)) {
          pageData[key] = JSON.stringify(value);
        }
      });
      pageData.pageId = id;
      pageData.pptId = pptId;
      pageData.templateId = page.templateId;
      pageObj.id = id;
      const body = JSON.parse(pageObj.body);
      body.canMove = page.canMove;
      body.customStore = page.customStore;
      pageObj.body = JSON.stringify(body);
      const newPage = yield call(savePage, {
        page: pageObj,
        pptContent: pageData,
      });
      page.templateId = newPage.templateId;
      pptxEditor.byPageId[id] = { ...page };
    },
    *byPageDocdataParser({ payload }, { call, put, select }) {
      const {
        theme,
        dataPPT,
        pageId,
        tmpTemplates = {},
        isReturn = false,
      } = payload;
      const dataPage = yield select((state) =>
        _.get(state, `pptxEditor.byPageId.${pageId}`),
      );
      if (!dataPage) {
        message.error('不存在的页面，请刷新浏览器再试！');
        return false;
      }
      let template = tmpTemplates[dataPage.templateId];
      if (!template) {
        template = yield select((state) =>
          _.get(state, `pptxTemplate.byId.${dataPage.templateId}`),
        );
        if (!template) {
          const d = yield call(getTemplateById, { id: dataPage.templateId });
          const noBase = false;
          if (!d) {
            return null;
          }
          const item = jsonTo(d.data);
          if (!item) {
            return null;
          }
          item.id = d.id;
          item.name = d.name;
          item.type = d.type;
          item.info = d.description;
          item.component = d.component;
          item.createTime = d.createTime;

          if (noBase) {
            return item;
          }
          const templateBase = _.get(
            pptx.dpUtils,
            `${item.component}.templateBase`,
          );
          template = templateBase
            ? pptx.buildOptions(templateBase(), item)
            : item;

          if (!template) {
            message.error('加载模板数据错误！');
            return false;
          }
          yield put({
            type: 'pptxTemplate/parseById',
            payload: template,
          });
          // console.log(_.assign({},template));
        }
        tmpTemplates[dataPage.templateId] = template;
      }
      if (isReturn) {
        // 不做 parser 处理，只是校验 template
        return template;
      }
      const bpi = yield select((state) => _.get(state, 'wordEditor.byPageId'));
      const eAllPageIds = yield select((state) =>
        _.get(state, 'pptxEditor.allPageIds'),
      );
      let wAllPageIds = yield select((state) =>
        _.get(state, 'wordEditor.allPageIds'),
      );
      wAllPageIds = [...wAllPageIds, pageId];
      yield put({
        type: 'wordEditor/stateAny',
        payload: {
          byPageId: _.assign({}, bpi, {
            [pageId]: pptx.metadataToDocdata(
              theme,
              template,
              dataPPT,
              dataPage,
            ),
          }),
          allPageIds: eAllPageIds.filter((d) => wAllPageIds.indexOf(d) !== -1),
        },
      });
      return template;
    },
    *docdataParser({ payload }, { call, put, select }) {
      const dataPPT = yield select((state) => _.get(state, 'pptxEditor.data'));
      const editingPageId = yield select((state) =>
        _.get(state, 'pptxEditor.editingPageId'),
      );
      const byPageId = yield select((state) =>
        _.get(state, 'pptxEditor.byPageId'),
      );
      const allPageIds = yield select((state) =>
        _.get(state, 'pptxEditor.allPageIds'),
      );
      let theme = yield select((state) =>
        _.get(state, `pptxTheme.byId.${dataPPT.themeId}`),
      );
      if (!theme) {
        const d = yield call(getThemeById, { id: dataPPT.themeId });
        const noBase = false;
        const item = jsonTo(d.data);
        if (!item) {
          return null;
        }
        item.id = d.id;
        item.name = d.name;
        item.description = d.description;
        item.createTime = d.createTime;
        theme = noBase ? item : pptx.buildOptions(pptx.themeBase(), item);
        if (!theme) {
          message.error('加载主题数据错误！');
          return;
        }
        yield put({
          type: 'pptxTheme/parseById',
          payload: theme,
        });
      }
      if (_.get(payload, 'pageId')) {
        let pageId = payload.pageId;
        yield put({
          type: 'byPageDocdataParser',
          payload: { theme, dataPPT, pageId },
        });
        return;
      }
      const currentEditingPage = editingPageId || _.get(allPageIds, '0');
      // 缓存模板
      const tmpTemplates = {};
      if (!payload) {
        const wordState = {
          value: '',
          byPageId: {},
          allPageIds: [],
          loading: false,
          currentEditingPage,
        };
        for (let i = 0; i < allPageIds.length; i++) {
          const pageId = allPageIds[i];
          const isReturn = true;
          const template = yield put.resolve({
            type: 'byPageDocdataParser',
            payload: { theme, dataPPT, pageId, tmpTemplates, isReturn },
          });
          if (!template) {
            continue;
          }

          wordState.allPageIds.push(pageId);
          let need = needBr(i, byPageId[allPageIds[1]]?.type);
          wordState.value += pptx.metadataToDocvalue(
            theme,
            template,
            dataPPT,
            byPageId[pageId],
            i,
            need,
          );
          wordState.byPageId[pageId] = pptx.metadataToDocdata(
            theme,
            template,
            dataPPT,
            byPageId[pageId],
          );
        }
        yield put({
          type: 'wordEditor/stateAny',
          payload: wordState,
        });
        // yield put({
        //   type: 'stateAny',
        //   payload: { editorMode: 'context' },
        // });
        return;
      }
      yield put({
        type: 'wordEditor/stateAny',
        payload: {
          byPageId: {},
          allPageIds: [],
          loading: false,
        },
      });
      yield put({
        type: 'wordEditor/stateAny',
        payload: {
          currentEditingPage: currentEditingPage,
        },
      });
      for (let i = 0; i < allPageIds.length; i++) {
        const pageId = allPageIds[i];
        yield put({
          type: 'byPageDocdataParser',
          payload: { theme, dataPPT, pageId, tmpTemplates },
        });
        // yield take('effect1/@@end')
      }
    },
    *docdataDocAddNew({ payload }, { call, select }) {
      let { docdata, prevPageId } = payload;
      const pptxEditor = yield select((state) => _.get(state, 'pptxEditor'));
      let pptId = yield select((state) => +_.get(state, 'pptxEditor.data.id'));
      let template = yield select((state) =>
        _.get(state, `pptxTemplate.byId.${docdata.templateId}`),
      );
      if (!template) yield call(fetchTemplateById, docdata.templateId);
      template = yield select((state) =>
        _.get(state, `pptxTemplate.byId.${docdata.templateId}`),
      );
      let page = pptx.defaultDataPage();
      pptx.docdataToUpdate(docdata, page);
      page = pptx.dataPageToSave(page);
      page.templateId = template.id;
      page.type = template.type;
      {
        //兼容目录
        if (docdata.type) {
          page.type = docdata.type;
        }
        if (page.type === 'Catalogue') {
          page.templateId = 7000001;
        }
      }
      page.sort = 0;
      page.pptId = pptId;
      //保存数据
      const currentPage = yield call(savePage, { page, pptContent: {} });
      const pageId = currentPage.id;
      page.id = pageId;
      page = pptx.dataPageFromApi(page);
      pptxEditor.byPageId[pageId] = page;
      const index = pptxEditor.allPageIds.findIndex(
        (item) => item.toString() === prevPageId.toString(),
      );
      if (index > -1) {
        pptxEditor.allPageIds.splice(index + 1, 0, page.id);
      }
      yield call(updateSort);
    },
    *docdataDocAdd({ payload }, { call, put, select }) {
      let { pageList, prevPageId, resolve } = payload;
      prevPageId = yield select((state) =>
        _.get(state, 'pptxEditor.editingPageId'),
      );
      let { editorMode } = payload;
      let { pptId } = payload;
      if (!editorMode) {
        editorMode = yield select((state) =>
          _.get(state, 'pptxEditor.editorMode'),
        );
      }
      if (!pptId) {
        pptId = yield select((state) => +_.get(state, 'pptxEditor.data.id'));
      }
      if (!pptId) {
        message.error('当前无在编辑的PPT！');
        return;
      }
      const newPageList = [];
      let index = 0;
      while (index < pageList.length) {
        const docdata = pageList[index];
        if (!docdata.templateId) {
          message.error('创建 PPT 单页时未匹配到合适的模板！');
          return;
        }
        // 加载模板
        let template = yield select((state) =>
          _.get(state, `pptxTemplate.byId.${docdata.templateId}`),
        );
        if (!template) {
          const d = yield call(getTemplateById, { id: docdata.templateId });
          const noBase = false;
          if (!d) {
            return null;
          }
          const item = jsonTo(d.data);
          if (!item) {
            return null;
          }
          item.id = d.id;
          item.name = d.name;
          item.type = d.type;
          item.info = d.description;
          item.component = d.component;
          item.createTime = d.createTime;

          if (noBase) {
            return item;
          }
          const templateBase = _.get(
            pptx.dpUtils,
            `${item.component}.templateBase`,
          );
          template = templateBase
            ? pptx.buildOptions(templateBase(), item)
            : item;
          if (!template) {
            message.error('加载模板数据错误！');
            return;
          }
          yield put({
            type: 'pptxTemplate/parseById',
            payload: template,
          });
        }

        // 创建新页
        let page = pptx.defaultDataPage();
        // 更新到页
        pptx.docdataToUpdate(docdata, page);

        page = pptx.dataPageToSave(page);

        page.templateId = template.id;
        page.type = template.type;
        // 先放个假的 sort
        page.sort = 0;

        page.pptId = pptId;

        const currentPage = yield call(savePage, { page, pptContent: {} });

        const pageId = currentPage.id;
        if (!pageId) {
          message.error('创建 PPT 单页时出错！');
          return;
        }
        // 直接添加新页面的数据到 store，方便后面的 排序 action（上面放了个假的 sort）
        page.id = pageId;
        page = pptx.dataPageFromApi(page);
        if (resolve) resolve(pageId);
        newPageList.push(page);

        index += 1;
      }
      if (!newPageList.length) {
        return;
      }
      yield put({
        type: 'docdataDocAddNext',
        payload: {
          editorMode,
          dataList: newPageList,
          prevPageId,
        },
      });
      //排序
      yield put({
        type: 'pageSort',
        payload: {},
      });
      // TODO 暂时先不要更改value
      // if (editorMode == 'context') {
      //   yield put({
      //     type: 'docdataParser',
      //   });
      // }
    },
    *docdataAdd({ payload = {} }, { select, put }) {
      const { pageId, docdata } = payload;
      const page = yield select((state) =>
        _.get(state, `pptxEditor.byPageId.${pageId}`),
      );
      if (!page) {
        message.error('新增项目时，未找到对应的页面，请刷新浏览器再试！');
        return;
      }

      const saveKeys = pptx.docdataToUpdate(docdata, page);

      if (saveKeys && saveKeys.length) {
        yield put({
          type: 'fetchUpdatePage',
          payload: { keys: saveKeys, id: pageId },
        });
        yield put({
          type: 'stateByAny',
          payload: { key: `byPageId.${pageId}`, data: page },
        });
        yield put({
          type: 'stateByAny',
          payload: {
            key: `byPageId.${pageId}`,
            data: page,
          },
        });
        yield put({
          type: 'docdataParser',
          payload: { pageId },
        });
      }
    },
    *docdataUpdate({ payload }, { select, call, put }) {
      const { docdata, pageId } = payload;
      const page = yield select((state) => {
        return _.get(state, `pptxEditor.byPageId.${pageId}`);
      });
      if (!page) {
        message.error('更新时，未找到对应的页面，请刷新浏览器再试！');
        return;
      }
      // const saveKeys = pptx.docdataToUpdate(docdata, page);
      _.set(page, docdata.key, docdata.value);
      // saveKeys是大模块'Index','List'等
      const saveKeys = [docdata.key.split('.')[0]];
      // const saveKeys = pptx.docdataToUpdate(docdata, page);
      if (saveKeys && saveKeys.length) {
        const todo = yield call(pptx.debouncePageSave, pageId, saveKeys);
        if (todo) {
          yield put({
            type: 'fetchUpdatePage',
            payload: {
              keys: saveKeys,
              id: pageId,
            },
          });
        }
      }
    },
    *pageSort({ payload }, { call, put, select }) {
      yield put({
        type: 'pageReduserSort',
        payload: payload,
      });
      const byPageId = yield select((state) => state.pptxEditor.byPageId);
      const allPageIds = yield select((state) => state.pptxEditor.allPageIds);
      if (!allPageIds || !allPageIds.length || !byPageId) {
        return;
      }

      let prevSort = _.min(allPageIds.map((d) => +byPageId[d].sort || 0));
      prevSort = _.max([_.toInteger(prevSort) - 1, 0]);
      // 数据库字段类型：INT(4)
      if (
        prevSort >= 1000 ||
        _.max(allPageIds.map((d) => +byPageId[d].sort || 0)) >= 1000
      ) {
        prevSort = 100;
        allPageIds.forEach((d) => {
          // 重置所有 sort，以免下方 for 中误进 if
          byPageId[d].sort = 0;
        });
      }
      for (let i = 0; i < allPageIds.length; i++) {
        const page = byPageId[allPageIds[i]];
        const sort = _.toInteger(page.sort);
        if (sort !== page.sort || sort <= prevSort) {
          page.sort = prevSort;
          const query = pptx.dataPageToSave(page);
          query.id = allPageIds[i];
          if (!query.type) {
            query.type = page.type;
          }
          savePage({ page: query, pptContent: {} }).then(() => {
            put({
              type: 'stateAny',
              payload: { saveStatus: { date: Date.now(), key: 'Saved' } },
            });
          });
          prevSort += 10;
        } else {
          prevSort += 10;
        }
      }
    },
    *fetchAllTemplate({ payload }, { call, put, select }) {
      const pptxEditor = yield select((state) => state.pptxEditor);
      const editingPageId = yield select((state) => state.editingPageId);
      const pptxTemplate = yield select((state) => state.pptxTemplate);
      // console.log(pptxEditor,editingPageId,pptxTemplate);
      // console.log(editingPageId);
      const page = pptxEditor.byPageId[editingPageId];
      // const template = pptxEditor;
      // console.log(page);
      // pptxTemplate?.byId[page.templateId];
    },
    *putNewPPT({ payload }, { call, put, select }) {},
  },
  reducers: {
    stateByAny(state, { payload = {} }) {
      return { ...state, ...payload };
    },
    stateAny(state, { payload }) {
      return { ...state, ...payload };
    },
    stateClear(state) {
      _.assign(state, jsonClone(state));
      return _.assign({}, state);
    },
    newStateClear(state) {
      _.assign(state, jsonClone(state));
      state.byPageId = {};
      state.allPageIds = [];
      state.editingPageId = '';
      return _.assign({}, state);
    },
    stateEditting(state) {
      state.byPageId[state.editingPageId] = _.assign(
        {},
        state.byPageId[state.editingPageId],
      );
      return _.assign({}, state);
    },
    changeThemeColor(state, { payload }) {
      const { color } = payload;
      state.data.customTheme.color = color;
      return _.cloneDeep(state);
    },
    changeFontFamily(state, { payload }) {
      const { fontItem } = payload;
      _.set(state, 'data.customTheme.font', { fontItem });
      return _.cloneDeep(state);
    },
    fetchUpdatePage(state, { payload }) {
      const dataPage =
        state.byPageId[_.get(payload, 'payload.id') || state.editingPageId];
      if (!dataPage) {
        payload = null;
      } else {
        payload = pptx.dataPageToSave(dataPage, _.get(payload, 'payload.keys'));
        payload.id = dataPage.id;
      }

      // 这里是夹在 action 和 saga 之前的请求参数构建，因此不需要页面更新，所以返回原 state
      return state;
    },
    dataPagesParser(state, { payload }) {
      const { error, data, singlePage } = payload;
      if (singlePage) {
        funcDataPagesParser(state, singlePage);
      } else {
        if (!error) {
          data.dataList.forEach((dataPage) => {
            funcDataPagesParser(state, dataPage);
          });
          state.pagesTotal = data.count || 0;
        }
        state.pagesError = error;
        state.pagesLoading = false;
      }
      return _.assign({}, state);
    },
    docdataDocAddNext(state, { payload }) {
      const { prevPageId, dataList, editorMode } = payload;
      const { allPageIds } = state;
      let num,
        newAllPageIds = allPageIds;
      //获取匹配的位置在其之后插入
      for (let i = 0; i <= newAllPageIds.length - 1; i++) {
        if (prevPageId == newAllPageIds[i]) {
          num = i;
        }
      }
      //bug临时解决
      if (Number.isNaN(num + 1)) {
        num = allPageIds.length;
      }

      if (editorMode === 'design' && dataList.length) {
        state.editingPageId = dataList[0].id;
      }
      if (dataList.length) {
        dataList.forEach((d) => {
          state.allPageIds.splice(num + 1, 0, d.id);
          state.byPageId[d.id] = d;
        });
      }
      return _.assign({}, state);
    },
    pageReduserSort(state, { payload }) {
      const { pageId, siblingId } = payload;
      const { allPageIds } = state;
      if (pageId) {
        if (!siblingId) {
          state.allPageIds = Array.from(allPageIds.filter((d) => d !== pageId));
          state.allPageIds.push(pageId);
        } else {
          state.allPageIds = [];
          while (allPageIds.length) {
            const pid = allPageIds.shift();
            if (pid === pageId) {
              continue; // eslint-disable-line no-continue
            }
            if (pid === siblingId) {
              state.allPageIds.push(pageId);
            }
            state.allPageIds.push(pid);
          }
        }
      }
      return _.assign({}, state);
    },
    changeTemplate(state, { payload }) {
      const { pageId, needReplaceTemplate } = payload;
      const { byPageId } = state;
      const { templateId } = needReplaceTemplate;
      if (templateId) {
        _.set(byPageId, `${pageId}.templateId`, templateId);
      }
      return _.assign({}, state);
    },
    deletePageById(state, { payload }) {
      const { id } = payload;
      delete state.byPageId[id];

      state.allPageIds = state.allPageIds.filter((d) => d != id);

      return _.assign({}, state);
    },
    createPageById(state, { payload }) {
      const { preId, page } = payload;
      const index = state.allPageIds.findIndex((d) => d === preId);
      state.allPageIds.splice(index, 0, page.id);
      state.byPageId[page.id] = page;
      return _.assign({}, state);
    },
  },
});

export const createPage = async (pptId) => {
  const basePage = {
    Catalogue: '',
    body: JSON.stringify({
      Transition: { value: {} },
      List: { value: {} },
      Index: { value: {} },
    }),
    // customTemlate:JSON.stringify(),
    describe: '{}',
    extend: '',
    footer: '{}',
    List: '{}',
    header: '{}',
    templateId: 66,
    plate: '{}',
    pptId: pptId,
    sort: 0,
    type: 'List',
  };
  const newPage = await savePage({ page: basePage, pptContent: {} });
  return parsePage(newPage);
}; //创建
export const deletePage = async (id) => {
  const app = getDvaApp();
  const store = app._store;
  const { getState } = store;
  const { pptxEditor } = getState();
  const index = pptxEditor.allPageIds.findIndex(
    (item) => item.toString() === id.toString(),
  );
  if (index !== -1) pptxEditor.allPageIds.splice(index, 1);
  delete pptxEditor.byPageId[id];
  pptxEditor.allPageIds = [...pptxEditor.allPageIds];
  pptxEditor.byPageId = { ...pptxEditor.byPageId };
  await deletePageById({ id });
}; //删除
export const updatePageById = async (id) => {
  const app = getDvaApp();
  const store = app._store;
  const { getState } = store;
  const { pptxEditor } = getState();
  const page = pptxEditor.byPageId[id];
  if (!page) return;
  let parsePage = pptx.dataPageToSave(page);
  parsePage.id = page.id;
  parsePage.type = page.type;
  await savePage({ page: parsePage, pptContent: {} });
  pptxEditor.byPageId[id] = { ...page };
}; //更新
export const updateSort = async () => {
  const app = getDvaApp();
  const store = app._store;
  const { getState } = store;
  const { pptxEditor } = getState();
  const ppt = pptxEditor.data;
  const byPageId = pptxEditor.byPageId;
  let allPageIds = pptxEditor.allPageIds;
  allPageIds = Array.from(new Set(allPageIds));
  const list = [];
  allPageIds.forEach((id, index) => {
    const page = byPageId[id];
    page.sort = index;
    list.push([id, index]);
  });
  await requestUpdateSort({ pptId: ppt.id, list });
}; //根据 allPageIds 更新sort
export const updatePageByIdWithReplaceTemplate = async (id, state) => {
  const app = getDvaApp();
  const store = app._store;
  const { getState } = store;
  const { pptxEditor, pptxTheme, pptxTemplate } = state || getState();
  const page = pptxEditor.byPageId[id];
  if (!page) return;
  let parsePage = pptx.dataPageToSave(page);
  parsePage.id = page.id;
  parsePage.type = page.type;

  const ppt = pptxEditor.data;
  const theme = pptxTheme.byId[ppt.themeId];
  const template = pptxTemplate.byId[page.templateId];

  let pptContent = pptx.metadataToDocdata(theme, template, ppt, page);
  _.mapKeys(pptContent, (value, key) => {
    if (!_.isString(value)) {
      pptContent[key] = JSON.stringify(value);
    }
    pptContent.pageId = page.id;
    pptContent.pptId = page.pptId;
    if (page.templateId) {
      pptContent.templateId = page.templateId;
    }
  });
  const newPage = await savePage({ page: parsePage, pptContent });
  page.templateId = newPage.templateId;
  page.type = newPage.type;
  pptxEditor.byPageId[Number(id)] = { ...page };
  return pptxEditor.byPageId[id];
}; //更新并替换模板
export const parsePage = (page) => {
  let newPage = pptx.dataPageFromApi(page);
  newPage.id = page.id;
  newPage.type = page.type;
  newPage.templateId = page.templateId;

  // newPage.customTemlate = page.customTemlate?;
  return newPage;
}; //解析save page

const funcDataPagesParser = (state, dataPage) => {
  state.byPageId[dataPage.id] = dataPage;
  if (state.allPageIds.indexOf(dataPage.id) === -1) {
    state.allPageIds.push(dataPage.id);
  }
  state.allPageIds = _.sortBy(
    state.allPageIds.map((d) => state.byPageId[d]),
    ['sort', 'id'],
  ).map((d) => d.id);

  return state;
};

export const replacePosition = async (id, siblingId) => {
  const app = getDvaApp();
  const store = app._store;
  const { getState } = store;
  const { pptxEditor } = getState();
  const { allPageIds } = pptxEditor;
  //原本位置索引
  const index = allPageIds.findIndex(
    (item) => item.toString() === id.toString(),
  );
  allPageIds.splice(index, 1);

  if (siblingId) {
    //替换后兄弟索引
    const siblingIdIndex = allPageIds.findIndex(
      (item) => item.toString() === siblingId.toString(),
    );
    allPageIds.splice(siblingIdIndex, 0, Number(id));
  } else {
    allPageIds.push(Number(id));
  }
  pptxEditor.allPageIds = [...pptxEditor.allPageIds];
  await updateSort();
};

/**
 * 获取PPT数据
 * @param id
 * @returns {type[][]}
 */
export const getPagesList = async (id) => {
  const data = await getPagesByPptxId({ pptId: id, limit: 199, page: 1 });
  return (data.dataList || [])
    .map((item) => pptx.dataPageFromApi(item))
    .sort((a, b) => a.sort - b.sort);
};

/**
 * 存入 page 存入 store
 */
const pageIntoStore = (list) => {
  const app = getDvaApp();
  const store = app._store;
  const { getState } = store;
  const { pptxEditor } = getState();
  list.forEach((page) => {
    pptxEditor.byPageId[page.id] = page;
  });
  pptxEditor.allPageIds = list.map((item) => item.id);
};

const getValueByPageList = async ({ list, theme, ppt }) => {
  const app = getDvaApp();
  const store = app._store;
  const { getState } = store;
  const { pptxTemplate } = getState();

  let value = '';
  const templatePromise = list.map((page) => {
    return fetchTemplateById(page.templateId);
  });
  await Promise.all(templatePromise);

  list.forEach((item, index) => {
    const template = pptxTemplate.byId[item.templateId];
    let need = needBr(index, item.type, list[1]?.type === 'Catalogue');
    const html = pptx.metadataToDocvalue(
      theme,
      template,
      ppt,
      item,
      index,
      need,
    );
    value += html;
  });
  return value;
};

export const initDoc = async (pptId) => {
  const app = getDvaApp();
  const store = app._store;
  const { getState, dispatch } = store;
  const { wordEditor } = getState();

  if (!pptId) return;
  const ppt = await dispatch({
    type: 'pptxEditor/fetchPPTById',
    payload: pptId,
  }); //获取PPT数据
  const themeId = ppt.themeId;
  const theme = await dispatch({
    type: 'pptxTheme/fetchThemeById',
    payload: themeId,
  }); //获取主题
  const list = await getPagesList(pptId);
  pageIntoStore(list);
  wordEditor.value = await getValueByPageList({ list, theme, ppt });
  getState().pptxEditor.editingPageId = list[0].id;
};
