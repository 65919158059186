import { createSlice } from '@reduxjs/toolkit';
import initState from '../initState';

export default createSlice({
  name: 'pptGeneration',
  initialState: initState.pptGeneration,
  reducers: {
    setType(state, action) {
      const value = action.payload
      state.type = value
    },
    setIsTranslate(state, action) {
      const value = action.payload
      state.isTranslate = value
    }
  },
});
