import { mapErrorCodeToConfigInfo } from '@/components/ErrorModal/config'

const arr = [10001, 10002, 10101, 10102, 10201, 10301, 10302, 10303, 10004, 10202, 10005,
    ...Object.keys(mapErrorCodeToConfigInfo).map((code) => Number(code)),
]

export const errorCodeSome = (code) => {
    return arr.some((c) => c === code)
}
export default arr

export const getErrorConfigByCode = (code) => {
    if (code === 10001 || code === 10002) {
        return {
            des: 'AI生成达到使用限制，请升级',
        }
    } if (code === 10003) {
        return {
            des: 'AI生成达到使用限制，请升级',
        }
    } if (code === 10101 || code === 10102) {
        return {
            des: 'PPT下载达到使用限制，请升级',
        }
    } if (code === 10103) {
        return {
            des: 'PPT下载达到使用限制',
        }
    } if (code === 10201 || code === 10202) {
        return {
            des: '工作台同时编辑文件数量达到使用限制，请升级',
        }
    } if (code === 10203) {
        return {
            des: '工作台同时编辑文件数量达到使用限制',
        }
    } if (code === 10301 || code === 10302) {
        return {
            des: '单个PPT页数达到使用限制，请升级',
        }
    } if (code === 10303) {
        return {
            des: '单个PPT页数达到使用限制',
        }
    }
}
export const upgradeByErrorCode = (code) => {
    return code === 10001 || code === 10002 || code === 10101 || code === 10102 || code === 10201 || code === 10202 || code === 10301 || code === 10302
}
