import modelExtend from 'dva-model-extend';
import { jsonClone } from '../web-utils/index';
import { model } from '@/utils/model';
export default modelExtend(model, {
  namespace: 'pptxProperties',
  state: {
    // 当前属性面板所属的节点(keyPath)
    key: '',
    // 当前节点的类型：文本、图片。。。。
    type: '',
    // 属性面板的标题
    title: '',
    // 相关数据
    data: null,
    // 其他数据
    extras: null,
    metadata: null,
    itemdata: null,
    saveKeys: null,
    keyPath: '',
  },
  subscriptions: {},
  effects: {},

  reducers: {
    stateByAny(state, { payload }) {
      return { ...state, ...payload };
    },
    stateAny(state, { payload }) {
      return { ...state, ...payload };
    },
    stateClear(state, { payload }) {
      const newState = stateFunClear(state);
      return payload ? _.assign(newState, payload) : newState;
    },
    stateLifeAny(state, { payload }) {
      return { ...state, ...payload };
    },
    stateTryClear(state, { payload }) {
      if (payload === state.key) {
        return stateFunClear(state);
      }
      return state;
    },
  },
});
function stateFunClear(state) {
  _.assign(state, jsonClone(state));
  return _.assign({}, state);
}
