import { v4 } from 'uuid'
import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getDataByPage } from '@/pages/Edit/common/node/nodemark'
import { getTextByNode } from '@/components/NodeMarkRender'
import createNodeId from '@/pages/Edit/common/createNodeId'
import replaceHTML from '@/common/replaceHTML'

export default ({ pageList, theme }) => {
    try {
        if (!theme) {
            return pageList
        }
        if (!pageList) {
            return pageList
        }
        const { preziTheme } = theme
        if (!preziTheme) {
            return pageList
        }
        return produce(pageList, (clonePageList) => {
            // 保存所有 type === 'html' 的元素及其原始位置
            const htmlElements = []
            pageList.forEach((p, index) => {
                if (p.type === 'html') {
                    htmlElements.push({ item: p, index })
                }
            })

            // 过滤掉所有 type === 'html' 的元素，并处理非 html 类型数据
            let filteredList = clonePageList.filter((p) => p.type !== 'html')

            const clonePages = {}
            filteredList.forEach((page) => {
                clonePages[page.id] = page
            })
            const coverPage = filteredList.find((page) => page.type === 'cover')
            let transitionPageList = []
            const transitionWithMain = []
            const pageHandler = ({
                preziTheme, page, children, type,
            }) => {
                if (!preziTheme || !page) {
                    return
                }
                // 获取模板节点
                const preziNodePosition = preziTheme.positions
                const positionLength = Object.keys(preziTheme.positions).length
                const paddingChildren = children.slice(0, positionLength).filter((i) => !!i)
                const paddingChildrenLength = paddingChildren.length
                const nodeList = preziTheme.nodes[paddingChildren.length - 1]
                const positions = preziNodePosition[(paddingChildrenLength).toString()]
                // 初始化links
                page.links = {}
                // 处理过渡页
                paddingChildren.forEach((page$2, index) => {
                    const nodes = nodeList[index]
                    const preziTextNodeList = nodes.filter((node) => node.type === 'text')
                    const preziBackgroundNode = nodes.filter((node) => node.type !== 'text')
                    const firstNode = nodes[0]

                    const data = getDataByPage(page$2, theme)
                    const titleNode = data.title
                    const titleText = getTextByNode(titleNode)
                    const clonePreziTextNodeList = JSON.parse(JSON.stringify(preziTextNodeList))
                    const clonePreziTextNode = clonePreziTextNodeList[0]
                    const clonePreziBackgroundNode = JSON.parse(JSON.stringify(preziBackgroundNode))
                    const clonePreziNodeList = [...clonePreziTextNodeList, ...clonePreziBackgroundNode]

                    // 设置节点ID  //锁定节点
                    clonePreziNodeList.forEach((node) => {
                        // node.id = createNodeId();
                        node.disable = true
                        node.isLock = true
                        node.isPreziNode = true
                    })
                    // 设置groupID
                    const groupId = v4()
                    clonePreziNodeList.forEach((node) => {
                        node.groupId = groupId
                    })
                    // 设置links
                    if (type === 'cover') {
                        page.links[groupId] = { id: groupId, linkPageUuid: page$2.id, type: 'transition' }
                        if (!page.backgroundColor) {
                            page.backgroundColor = '#FFFFFF'
                        }
                        page.links[groupId] = { id: groupId, linkPageUuid: page$2.id, type: 'transition' }
                        page$2.parentPreziPageId = page.id
                    }
                    if (type === 'transition') {
                        page.links[groupId] = { id: groupId, linkPageUuid: page$2.id, type: 'main' }
                        page$2.parentPreziPageId = page.id
                    }

                    // 设置文本
                    clonePreziTextNode.value = replaceHTML(titleText, clonePreziTextNode.value)

                    // 设置定位
                    const position = positions[index]
                    clonePreziNodeList.forEach((node) => {
                        if (node.type !== 'text' || (clonePreziTextNode && node.id !== clonePreziTextNode?.id)) {
                            node.style.top = position.top + (node.style.top - firstNode.style.top)
                            node.style.left = position.left + (node.style.left - firstNode.style.left)
                        } else {
                            node.style.top = position.top
                            node.style.left = position.left
                        }
                    })

                    // 赋值
                    clonePreziNodeList.forEach((node) => {
                        page.nodes[node.id] = node
                    })
                })
            }
            {
                const { cover: coverPreziTheme } = preziTheme
                const preziNodePosition = coverPreziTheme.positions
                const positionLength = Object.keys(preziNodePosition).length
                transitionPageList = filteredList.filter((page) => page.type === 'transition').slice(0, positionLength)
            }
            pageHandler({
                preziTheme: preziTheme.cover, page: coverPage, children: transitionPageList, type: 'cover',
            })
            guaLog('transitionPageList', cloneDeep(transitionPageList))
            transitionPageList.forEach((transitionPage) => {
                const transition = { transitionPage, children: [] }
                const index = filteredList.findIndex((page) => page.id === transitionPage.id)
                const list = filteredList.slice(index + 1, filteredList.length)
                while (list[0]) {
                    const page = list[0]
                    if (page.type === 'main' || page.type === 'other' || page.type === undefined) {
                        transition.children.push(page)
                        list.shift()
                    } else {
                        break
                    }
                }
                transitionWithMain.push(transition)
            })
            guaLog('transitionWithMain', cloneDeep(transitionWithMain))
            // 过渡页
            transitionWithMain.forEach((item) => {
                const { transitionPage, children } = item
                pageHandler({
                    preziTheme: preziTheme.transition, page: transitionPage, children, type: 'transition',
                })
            })

            // 将原来的 html 元素恢复到它们原来的位置
            htmlElements.forEach(({ item, index }) => {
                filteredList.splice(index, 0, item)
            })

            // 更新 clonePageList
            clonePageList.length = 0
            clonePageList.push(...filteredList)
        })
    } catch (e) {
        console.log(e)
        return pageList
    }
}
