/**
 * 注意事项：
 * 1、对于所有使用 sizeParser 处理的数据，但是在一些情况下又不想处理，可以使用 String 类型，如：lineHeight: '1.2'、scale: ['1']
 * 2、
 */

import * as d3 from 'd3-color';
import { jsonClone } from '../../web-utils/index';
import { idPathGetPath } from './id-path';
import { getDvaApp } from 'umi';
import { connect } from 'umi';
// @connect(({pptxEditor}) => ({
//   pptxEditor,
// }))
export function combineStyle(
  tmpKey = '',
  template = {},
  theme = {},
  tmpMore = '',
) {
  const styles = [];
  const tmp = _.get(template, tmpKey);
  if (!tmp) {
    return {};
  }
  if (tmp[tmpMore]) {
    styles.push(combineStyle(`${tmpKey}.${tmpMore}`, template, theme));
  }
  if (tmp.customStyle) {
    styles.push(jsonClone(tmp.customStyle));
  }
  // 继承的同级样式
  if (tmp.extend) {
    (_.isArray(tmp.extend) ? tmp.extend : [tmp.extend]).forEach((d) => {
      styles.push(combineStyle(d, template, theme, tmpMore));
    });
  }
  if (tmp.style) {
    (_.isArray(tmp.style) ? tmp.style : [tmp.style]).forEach((d) => {
      styles.push(jsonClone(_.get(theme, d)));
    });
  }

  return _.reduceRight(
    styles,
    (base, sty) => (sty ? _.merge(base, sty) : base),
    {},
  );
}

export function combineOption(
  optPath,
  options = {},
  optMore = '',
  keys = ['style', 'attrs'],
) {
  const data = [];

  const item = _.isString(optPath) ? _.get(options, optPath) : optPath;
  if (!item) {
    return {};
  }
  if (item.extend) {
    (_.isArray(item.extend) ? item.extend : [item.extend]).forEach((d) => {
      data.push(combineOption(d, options, optMore, keys));
    });
  }
  if (item[optMore]) {
    data.push(
      combineOption(
        _.isString(optPath) ? `${optPath}.${optMore}` : optMore,
        options,
        optMore,
        keys,
      ),
    );
  }

  data.push(item);

  return _.reduce(
    data,
    (base, sty) => {
      if (!sty) {
        return base;
      }
      return buildOptions(base, keys && keys.length ? _.pick(sty, keys) : sty);
      // return _.merge(base, keys && keys.length ? _.pick(sty, keys) : sty);
    },
    {},
  );
}

export function deppCombineOption(optPath, options, optMore, keys) {
  const data = combineOption(optPath, options, optMore, keys);

  if (_.isObject(data.value)) {
    _.keys(data.value).forEach((k) => {
      if (data.value[k]) {
        data.value[k] = deppCombineOption(
          data.value[k],
          options,
          optMore,
          keys,
        );
      }
    });
  }

  return data;
}

export function buildOptions(...sources) {
  // console.log(sources);
  return _.mergeWith({}, ...sources, (obj, src, name) => {
    // eslint-disable-line consistent-return
    if (['extend', 'type'].indexOf(name) !== -1) {
      if (_.isArray(obj) && _.isArray(src)) {
        return [].concat(obj).concat(src);
      }
    } //这里应该是深度合并 不合并 'extend', 'type'
  });
}

// 构建、解析元数据
export function buildMetadata(...sources) {
  let data = buildOptions(...sources);
  return (options, isDeep = true, checkNorm = true) => {
    data = isDeep
      ? deppCombineOption(data, options, '', null)
      : combineOption(data, options, '', null);
    buildValueKeys(data, isDeep, checkNorm);

    return data;
  };
}

// 构建可顺序绘制的 value
export function buildValueKeys(data, deepBuild = true, checkNorm = true) {
  if (!data || !data.value || !_.isObject(data.value)) {
    return;
  }
  data.valueKeys = _.keys(data.value)
    .filter((d) => {
      const item = data.value[d];
      // 错误/覆盖数据
      if (!item) {
        return false;
      }
      // 非标准数据
      if (checkNorm && !item.norm) {
        return false;
      }
      return true;
    })
    .sort((a, b) => (data.value[a].sort || 0) - (data.value[b].sort || 0));
  if (deepBuild) {
    data.valueKeys.forEach((k) => buildValueKeys(data.value[k]));
  }
}

export function metadataGetSet(item, kPath) {
  const purePath = idPathGetPath(kPath);

  if (!_.isObject(_.get(item, purePath))) {
    _.set(item, purePath, metadataModel());
  }
  return _.get(item, purePath);
}

export function metadataModel() {
  return {
    style: {},
    attrs: {},
  };
}

export function tryLastIndex(dataList, index) {
  if (!dataList || !dataList.length) {
    return null;
  }
  let i = index;
  while (i >= dataList.length) {
    i--;
  }
  return dataList[i];
}

export function tryTureLastIndex(dataList, index) {
  if (!dataList || !dataList.length) {
    return null;
  }
  let i = index;
  while (i >= dataList.length) {
    i--;
  }
  while (!dataList[i] && i >= 0) {
    i--;
  }
  return i < 0 ? null : dataList[i];
}

export function try2LastIndex(dataList, indexList) {
  const [idx0, idx1] = indexList;
  if (!dataList || !dataList.length) {
    return null;
  }
  let item = tryLastIndex(dataList[idx0], idx1);
  if (item) {
    return item;
  }
  item = tryLastIndex(dataList, idx0);
  return item ? tryLastIndex(item, idx1) : null;
}

export function tryCycleIndex(dataList, index) {
  if (!dataList || !dataList.length) {
    return null;
  }
  return dataList[index % dataList.length];
}

export function sizeParser(num, unit = 'px') {
  return _.isNumber(num) ? `${num}${unit}` : num;
}

export function htmlPxToPt(html) {
  return String(html).replace(/(\d+)px/g, (match, p1) => {
    const size = +p1;
    if (!size) {
      return match;
    }
    // return `${_.toInteger((size * 4) / 3)}pt`;
    // return `${size}pt`;
    return `${_.toInteger((size * 3) / 4)}pt`;
  });
}

export function htmlPtToPx(html) {
  return String(html).replace(/(\d+)pt/g, (match, p1) => {
    const size = +p1;
    if (!size) {
      return match;
    }
    // return `${_.toInteger((size * 3) / 4)}px`;
    // return `${size}px`;
    return `${_.toInteger((size * 4) / 3)}px`;
  });
}

export function colorParser(path = '', options = {}) {
  // if (String(path).indexOf('#') === 0) {
  //   // #字开头的颜色值，避免被下面的循环误处理
  //   return path;
  // }
  const color = _.get(options, path);
  if (_.isString(color)) {
    return color;
  }
  // 有可能是超过了colors的数组长度，尝试循环colors取值
  const keysPath = String(path).split('.');
  const last1Index = +keysPath.pop();
  let any = _.get(options, keysPath.join('.'));

  if (_.isArray(any)) {
    if (any.length && last1Index >= 0) {
      // 包含对比色、主色的的层级，参见 theme.color.n
      return any[last1Index % any.length];
    }
  } else {
    const last2Index = +keysPath.pop();
    any = _.get(options, keysPath.join('.'));
    if (_.isArray(any) && any.length - 1 > 0 && last2Index >= 0) {
      // theme.color，去掉第1个元素，因为其是基色
      any = any.slice(1);
      const level = any[last2Index % any.length];
      if (level && level.length && last1Index >= 0) {
        return level[last1Index % level.length];
      }
    }
  }
  return path;
}

export function colorsParser(style, options = {}) {
  // 颜色类，获取对应的theme变量颜色值
  ['color', 'borderColor', 'backgroundColor', 'backgroundImage'].forEach(
    (name) => {
      style[name] = colorParser(style[name], options);
    },
  );
}

export function styleParser(style, options = {}, unit) {
  // 数值类：添加单位后缀
  [
    'fontSize',
    'flexBasis',
    'marginTop',
    'marginRight',
    'marginBottom',
    'marginLeft',
    'paddingTop',
    'paddingRight',
    'paddingBottom',
    'paddingLeft',
    'height',
    'width',
    'borderWidth',
    'borderRadius',
    'borderTopWidth',
    'borderRightWidth',
    'borderBottomWidth',
    'borderLeftWidth',
    'top',
    'right',
    'bottom',
    'left',
    'lineHeight',
  ].forEach((name) => {
    style[name] = sizeParser(style[name], unit);
    if (!style[name]) {
      // 解决 borderTopWidth=undefined 覆盖掉了 borderWidth 的问题
      delete style[name];
    }
  });
  // 颜色类，获取对应的theme变量颜色值
  colorsParser(style, options);

  // if (style.backgroundImage) {
  //   style.backgroundImage = `url(https://pub.jianzeppt.cn${style.backgroundImage})`;
  // }
  // 特殊：渐变对象
  ['background'].forEach((name) => {
    const data = style[name];
    if (!_.isObject(data)) {
      return;
    }
    const colors = data.colors.map((any) => colorParser(any, options));
    style[name] = `${data.type}(${data.direction}, ${colors.join(', ')})`;
  });
  // 特殊：阴影对象
  ['boxShadow'].forEach((name) => {
    const data = style[name];
    if (!_.isObject(data)) {
      return;
    }
    const values = [
      sizeParser(data.h, unit) || 0,
      sizeParser(data.v, unit) || 0,
      sizeParser(data.blur, unit) || 0,
      sizeParser(data.spread, unit) || 0,
      colorParser(data.color, options),
    ];
    if (data.inset) {
      values.push('inset');
    }
    style[name] = values.join(' ');
  });
  // 特殊：阴影对象
  if (_.isObject(style.transform)) {
    const { transform } = style;
    // TODO  这里为了防止 scale 等的值被加上 px，需要将其设置为 String 而不是 Number
    style.transform = _.reduce(
      _.keys(style.transform),
      (vals, name) => {
        const sizes = (
          _.isArray(transform[name]) ? transform[name] : [transform[name]]
        ).map((d) => sizeParser(d, unit));

        return vals.concat([`${name}(${sizes.join(', ')})`]);
      },
      [],
    ).join(' ');
  }

  return style;
}

export function colorToRgba(color) {
  const d = d3.color(color);
  if (!d) {
    return null;
  }
  if (typeof d.r !== 'number') {
    return null;
  }
  return [d.r, d.g, d.b, d.opacity];
}

// 参数参照 colorToRgba 的返回值
export function colorRgbaTo255(rgba) {
  if (!rgba || !_.isArray(rgba) || rgba.length < 3) {
    return null;
  }
  const opacity = rgba.length === 3 ? 1 : rgba[3];
  return [
    ...rgba.slice(0, 3),
    _.inRange(opacity, 0, 1.00001) ? _.toInteger(opacity * 255) : 255,
  ];
}

// 参数参照 colorRgbaTo255 的返回值
export function colorRgbaFrom255(rgba) {
  if (!rgba || !_.isArray(rgba) || rgba.length < 4) {
    return null;
  }
  return [
    ...rgba.slice(0, 3),
    _.inRange(rgba[3], 0, 255.00001) ? _.round(rgba[3] / 255, 2) : 1,
  ];
}

export function cssSize(size) {
  return +(size || '').replace(/[^\d|.]/gi, '');
}

const debounceEdtSaveMap = {};
let count = 0;
window.addEventListener('beforeunload', (event) => {
  if (count === 0) {
    return;
  }
  event.preventDefault();
  event.returnValue = '';
});

export function debounceEdtSave(self, saveKeys, delay = 3000) {
  if (!saveKeys || !saveKeys.length) {
    return;
  }
  const app = getDvaApp();
  const { pptxEditor } = app._store.getState();
  const id = pptxEditor?.editingPageId;
  const sub = () => {
    if (count > 0) {
      count--;
    }
  };
  const clear = (mapKey) => {
    sub();
    clearTimeout(debounceEdtSaveMap[mapKey]);
  };
  saveKeys.forEach((k) => {
    const mapKey = id + ':' + k;
    clear(mapKey);
    count++;
    debounceEdtSaveMap[mapKey] = setTimeout(() => {
      self.props.dispatch({
        type: 'pptxEditor/updatePageById',
        payload: { keys: [k], id },
      });
      sub();
    }, delay);
  });
}

const debouncePageSaveMap = {};

export function debouncePageSave(pageId, saveKeys, delay = 3000) {
  if (!pageId || !saveKeys || !saveKeys.length) {
    return Promise.resolve(false);
  }
  const str = saveKeys.sort().join(',');
  _.keys(debouncePageSaveMap).forEach((k) => {
    const [id, keys] = k.split('_.');
    if (!id || !keys) {
      return;
    }
    if (str.indexOf(keys) !== -1) {
      // 历史请求是本次请求的子请求，所以取消历史
      debouncePageSaveMap[k].clear(k);
    } else if (keys.indexOf(str) !== -1) {
      // 本次请求是历史请求的子请求，所以取消本次
      pageId = 0;
    }
  });
  if (!pageId) {
    return Promise.resolve(false);
  }
  return new Promise((resolve) => {
    const newKey = `${pageId}_.${str}`;
    const t = setTimeout(() => {
      debouncePageSaveMap[newKey].clear(newKey, true);
    }, delay);
    debouncePageSaveMap[newKey] = {
      clear: (key, success) => {
        clearTimeout(t);
        delete debouncePageSaveMap[key];
        resolve(!!success);
      },
    };
  });
}

export function getTransitionIdList(pptxEditor, pptxTemplate) {
  return (pptxEditor.allPageIds || [])
    .map((pid) => {
      const page = _.get(pptxEditor.byPageId, pid);
      if (!page) {
        return 0;
      }
      const template = _.get(pptxTemplate.byId, page.templateId);
      if (!template) {
        return 0;
      }
      return template.type === 'Transition' ? page.id : 0;
    })
    .filter((d) => !!d);
}

export function getDateDiff(dateStr) {
  // let publishTime = getDateTimeStamp(dateStr)/1000,
  const publishTime = dateStr;
  const timeNow = parseInt(new Date().getTime() / 1000, 10);
  // let d;
  const date = new Date(publishTime * 1000);
  const Y = date.getFullYear();
  let M = date.getMonth() + 1;
  let D = date.getDate();
  let H = date.getHours();
  let m = date.getMinutes();
  let s = date.getSeconds();
  // 小于10的在前面补0
  if (M < 10) {
    M = `0${M}`;
  }
  if (D < 10) {
    D = `0${D}`;
  }
  if (H < 10) {
    H = `0${H}`;
  }
  if (m < 10) {
    m = `0${m}`;
  }
  if (s < 10) {
    s = `0${s}`;
  }
  const d = timeNow - publishTime;
  const dDays = parseInt(d / 86400, 10);
  const dHours = parseInt(d / 3600, 10);
  const dMinutes = parseInt(d / 60, 10);
  const dSeconds = parseInt(d, 10);
  if (dDays > 0 && dDays < 30) {
    return `${dDays}天前`;
  }
  if (dDays <= 0 && dHours > 0) {
    return `${dHours}小时前`;
  }
  if (dHours <= 0 && dMinutes > 0) {
    return `${dMinutes}分钟前`;
  }
  if (dSeconds < 60) {
    if (dSeconds <= 0) {
      return '刚刚发表';
    }
    return `${dSeconds}秒前`;
  }
  if (dDays >= 3 && dDays < 30) {
    return `${M}-${D} ${H}:${m}`;
  }
  if (dDays >= 30) {
    return `${Y}-${M}-${D} ${H}:${m}`;
  }

  return null;
}

export const isDiscardAi = __DEV__ ?
(window.location.hostname === 'test.miaochuppt.com')
 :
(window.location.hostname === 'miaochuppt.com')


// 获取css某个主题色
export const getCssPrimaryColor = (name) => {
  const root = document.documentElement
  return getComputedStyle(root).getPropertyValue(name).trim()
}