export default ({ page = {}, theme = {} }) => {
    const { excludedThemeNodes = [], nodes: pageNodes = {} } = page
    const { excludedThemeNodes: themeExcludedThemeNodes = [], typeNodesList = [], nodes = {} } = theme || {}
    const target = typeNodesList.find((item) => item.type === page.type)
    const obj = { ...pageNodes }
    target?.uuidList?.forEach?.((id) => (obj[id] = nodes[id]))
    excludedThemeNodes?.forEach?.((id) => delete obj[id])
    themeExcludedThemeNodes || [].forEach?.((id) => delete obj[id])
    // 属性临时添加
    if (page.useTheme === false) {
        target?.uuidList?.forEach?.((id) => {
            delete obj[id]
        })
    }
    return obj
}
