const processPageZIndex = (page) => {
    if (page?.type === 'html' || !page?.nodes) return page
    const nodes = page.nodes
    Object.keys(nodes).forEach((key) => {
        let node = nodes[key]
        if (node?.style && (node.style.zIndex === 0 || node.style.zIndex === undefined)) {
            node.style.zIndex = 1
        }
    })
    return page
}

export default processPageZIndex
