// 分割标题
import replaceHTML from "@/common/replaceHTML";
import { updateHtmlFontSize } from "./updateHtmlFontSize";

export const splitTextByHTML = (node, splitText) => {
    const htmlStr = node.value
    const title = replaceHTML(splitText[0], htmlStr)
    const subTitle = replaceHTML(splitText[1], htmlStr)

    const updateTitle = updateHtmlFontSize(title, 30)
    const updateSubTitle = updateHtmlFontSize(subTitle, 60)
    return updateTitle + updateSubTitle
}
