const initState = {
    current: null,
    ppt: {},
    pages: {},
    onlyView: false,
    showRecommend: false,
    showRecommend2: false,
    showAnimation: false,
    openPPTAction: true,
    showHelp: false,
    currentFocusNodeId: null,
    currentFocusNodeUUID: null,
    checkedIdList: [],
    currentHoverNode: null,
    collaborationUsers: [],
    showComment: false,
    // editMode:"content",
    editMode: null,
    hintStep: -1,
    themes: {},
    originTemplateDetail: null,
    history: {
        id: undefined,
        nodeHistory: 0,
    },
    svgTool: {
        drawFlag: false,
    },
    pptGeneration: {
        isTranslate: false, // 表明是否可以开始翻译了
        type: '', // ai: 表明是ai生成的.
    },
    // 动画进度
    animationProgress: 0,
    // 动画顺序
    animation: {
        showProgressAnimation: false,
        showFullScreenAnimation: false,
        showSyllabusAnimation: false,
        showNonContentAnimation: false,
        showContentAnimation: false,
        showTemplateAnimation: false,
        isTypingEnded: false,
        errorCode: null,
        animationData: {
            // templateId: '',
            // pptTitle: ''
        },
    },
    popupWindow: null,
    chartsColor: [],
    animaker: {
        backgroundMusic: {},
    },
    resizeText: {},
    newEdit: {
        mode: 'mixed',
        focusId: null,
        hoverId: null,
        enableAnimation: false,
        scale: 1,
        guideVideoVisible: false,
        guideVideoVisible2: false,
        currentAiHelperPageId: null,
        currentSelectedHTML: '',
        currentReplaceHTMLMode: 'create', // create, insert
        series: {},
        firstModifyHTMLValue: false,
        showFormat: true,
        toolbarCanScroll: true,
    },
}
export default initState
