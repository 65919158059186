import isThemeNodeById from '@/pages/Edit/common/isThemeNodeById.'

export default ({ page, nodeId }) => {
    let nodeIdList
    if (nodeId instanceof Array) {
        nodeIdList = nodeId
    } else if (nodeId) {
        nodeIdList = [nodeId]
    }
    nodeIdList?.forEach?.((nodeId) => {
        if (isThemeNodeById(nodeId)) {
            if (page.excludedThemeNodes) {
                page.excludedThemeNodes.push(nodeId)
            } else {
                page.excludedThemeNodes = [nodeId]
            }
        } else {
            delete page.nodes[nodeId]
        }
    })
    const nodeMark = page.nodeMark
    const nodes = page.nodes
    if (page.nodeMark?.point) {
        page.nodeMark.point = page.nodeMark?.point.map((item) => {
            if (!item) return
            const valueNodes = nodes[item.valueNodes?.[0]?.id]
            const title = nodes[item?.title?.id]
            const plate = item?.plate?.map?.((id) => nodes[id])?.slice?.(0, 1)
            const time = nodes[item?.time?.id]
            const icon = nodes[item?.icon?.id]
            const bigNumber = nodes[item?.bigNumber?.id]
            const pointType = nodeMark.pointType || 'normal'
            if (pointType === 'normal') {
                if (!title && !valueNodes) {
                    return
                }
            }
            if (pointType === 'time') {
                if (!title && !valueNodes && !time) {
                    return
                }
            }
            if (pointType === 'bigNumber') {
                if (!title && !valueNodes && !bigNumber) {
                    return
                }
            }
            if (pointType === 'icon') {
                if (!title && !valueNodes && !icon) {
                    return
                }
            }
            if (pointType === 'plate') {
                if (!title && !valueNodes && !plate) {
                    return
                }
            }

            return item
        }).filter((i) => !!i)
    }
    if (page.nodeMark.plate) {
        page.nodeMark.plate = page.nodeMark?.plate.filter((id) => !!nodes[id])
    }
    if (page.nodeMark?.chart) {
        page.nodeMark.chart = page.nodeMark?.chart.filter((id) => !!nodes[id])
    }
    if (page.nodeMark?.table) {
        page.nodeMark.table = page.nodeMark?.table.filter((id) => !!nodes[id])
    }
    if (page.nodeMark?.video) {
        page.nodeMark.video = page.nodeMark?.video.filter((id) => !!nodes[id])
    }
}
