import INode from '@/pages/Edit/type/INode';

//文本自动调整
export enum ETextAdjustment {
  none = 'none', //不自动调整
  zoom = 'zoom', //溢出时缩放排版
  autoRect = 'auto-rect', //根据文字调整形状大小
}


export const getTextAdjustmentText = (value: ETextAdjustment) => {
  switch (value) {
    case ETextAdjustment.none: {
      return '不自动调整';
    }
    case ETextAdjustment.zoom: {
      return '溢出时缩放排版';
    }
    case ETextAdjustment.autoRect: {
      return '根据文字调整形状大小';
    }
    default: {
      return '根据文字调整形状大小';
    }
  }
};

//文本方向类型
export enum ETextDirection {
  horizontal = 'horizontal',  //横排
  verticalRL = 'vertical-rl', //竖排 从右到左
  verticalLR = 'vertical-lr', //竖排 从左到右
  angle90 = 'angle90',  //文字旋转90
  angle270 = 'angle270',  //文字旋转270
  heapUp = 'heap-up' //堆积
}

export const getTextDirectionText = (value: ETextDirection) => {
  switch (value) {
    case ETextDirection.verticalRL: {
      return '竖排';
    }
    case ETextDirection.verticalLR: {
      return '竖排';
    }
    case ETextDirection.horizontal: {
      return '横排';
    }
    case ETextDirection.angle90: {
      return '将所有文字旋转90°';
    }
    case ETextDirection.angle270: {
      return '将所有文字旋转270°';
    }
    case ETextDirection.heapUp: {
      return '堆积';
    }
    default: {
      return '横排';
    }
  }
};
export const getWordWrapText = (value: boolean | undefined) => {
  switch (value) {
    case undefined: {
      return '自动换行';
    }
    case true: {
      return '自动换行';
    }
    case false: {
      return '不换行';
    }
    default: {
      return '';
    }
  }
};


export interface IColorStop {
  color: string;
  offset?: number;
}

export interface ILinearGradient {
  angle?: number;
  colorStops: IColorStop[];
}

export interface IImageFill {
  src: string;
}

export enum EFillType {
  linearGradient = 'linear-gradient',
  imageFill = 'image-fill',
  colorFill = 'color-fill',
}

export interface ITextStroke {
  width: number;
  color: string;
}

export interface IFill {
  type: EFillType;
  linearGradient?: ILinearGradient;
  imageFill?: IImageFill;
  color: string;
}

export enum EAlignment {
  left = 'left',
  center = 'center',
  right = 'right',
  justify = 'justify',
  disperseJustify = 'disperse-justify',
}

export enum EBorderStyle {
  solid = 'solid',
  dashed = 'dashed',
}

export interface IBorder {
  borderStyle: EBorderStyle;
  borderWidth: number;
  borderColor: string;
  colorStops: IColorStop[];
}

//文本对齐方式
export enum EAlignText {
  top = 'top',
  end = 'end',
  center = 'center',
  topCenter = 'top-center',
  endCenter = 'end-center',
  centerCenter = 'center-center',
}

export const getAlignTextText = (value: EAlignText) => {
  switch (value) {
    case EAlignText.top: {
      return '顶端对齐';
    }
    case EAlignText.center: {
      return '中部对齐';
    }
    case EAlignText.end: {
      return '底部对齐';
    }
    case EAlignText.topCenter: {
      return '顶部居中';
    }
    case EAlignText.centerCenter: {
      return '中部居中';
    }
    case EAlignText.endCenter: {
      return '底部居中';
    }
    default: {
      return '顶端对齐';
    }
  }
};

export default interface ITextNode extends INode {
  textAdjustment: ETextAdjustment;
  textDirection?: ETextDirection;
  textScale?: number;
  placeholder?: string;
  alignment?: EAlignment;
  wordWrap?: Boolean;
  fill?: IFill;
  backgroundFill?: IFill;
  textStroke?: ITextStroke;
  border?: IBorder;
  opacity?: number;
  alignText?: EAlignText;
  chineseHabit?: boolean;
  englishWordBreak?: boolean;
}

export interface ITextNodeInstance {
  id: string;
  container: HTMLDivElement | null;
  isVertical: boolean; //是否是竖排文本
  placeholder?: string;
  textScale?: number; //文本放大
  textShadow: any; //文本阴影
  fontFamilySet?: Set<string>; //字体集
  baseRef: any; // BaseText ref
  html?: string;
  textDirection?: ETextDirection;
  textAdjustment?: ETextAdjustment;
  wordWrap?: Boolean;
  alignText?: EAlignText;
  chineseHabit?: boolean;
  englishWordBreak?: boolean;
  baseTextKey?:any
  alignment?: string // 文本对齐方式
}

// node.wordWrap




// 按中文习惯控制首位字符
// 允许西文在单词中间换行
// 全不选
// 未知
//
// 按中文习惯控制首位字符
// 允许西文在单词中间换行
// 全选
// word-break:break-all
//
// 中文选，英文不选
// word-break:break-word
//
// word-break: normal;
// line-break: anywhere;
// 中文不选 英文选
