global.__DEV__ = process.env.TEST === 'test' || process.env.NODE_ENV !== 'production';
global.__LOC__ = process.env.NODE_ENV !== 'production';
global.__TEST__ = process.env.TEST === 'test'
global.__PRO__ = !global.__DEV__
global.__VERSION__ = `0.0.59`

console.log('version：' + global.__VERSION__);
export default {
    APP_BASE_URL: __DEV__
        ? 'https://api-test.jianzeppt.cn'
        : 'https://api.jianzeppt.com',
};

console.log('isDevelopment:', __DEV__);

try {

}catch (e) {

}finally {

}
