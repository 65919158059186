export default {
  TEMPLATES__DETAIL_1: '选好模板，',
  TEMPLATES__DETAIL_2: 'AI帮你写内容',
  TEMPLATES__DETAIL_3: '生成下载',
  TEMPLATES__DETAIL_4: '不了直接下载',
  TEMPLATES__DETAIL_5: '立即下载',
  TEMPLATES__DETAIL_6: '立即编辑',
  TEMPLATES__DETAIL_7: '软件',
  TEMPLATES__DETAIL_8: '格式',
  TEMPLATES__DETAIL_9: '大小',
  TEMPLATES__DETAIL_10: '页数',
  TEMPLATES__DETAIL_11: '比例',
  TEMPLATES__DETAIL_12: '作者',
  TEMPLATES__DETAIL_13: '上传时间',
  TEMPLATES__DETAIL_14: '版权',
  TEMPLATES__DETAIL_15: '版权声明，盗版必究',
  TEMPLATES__DETAIL_16: '热门标签',
  TEMPLATES__DETAIL_17: '精品推荐',
  TEMPLATES__DETAIL_18: '猜你喜欢',

  TEMPLATES__DETAIL_19: '在海量精美模板中搜索',
  TEMPLATES__DETAIL_20: '嗨，我是AI秒出助手。你想对PPT做点什么？',
  TEMPLATES__DETAIL_21: '输入主题',
  TEMPLATES__DETAIL_22: '导入内容',
  TEMPLATES__DETAIL_23: '听起来不错，你想要写什么内容呢？',
  TEMPLATES__DETAIL_24: '？听起来不错，我想想怎么写…',
  TEMPLATES__DETAIL_25: '下面是给你的大纲，你认为怎么样呢？',
  TEMPLATES__DETAIL_26: '内容预览：',
  TEMPLATES__DETAIL_27: '换一换',
  TEMPLATES__DETAIL_28: '生成PPT',
  TEMPLATES__DETAIL_29: '保持原文内容',
  TEMPLATES__DETAIL_30: '动画预览',
  TEMPLATES__DETAIL_31: 'PPT生成中',
  TEMPLATES__DETAIL_32: '过渡页',
  TEMPLATES__DETAIL_33: 'PPT生成中请稍后…',
  TEMPLATES__DETAIL_34: '生成失败',
  TEMPLATES__DETAIL_35: '即将完成...',
  TEMPLATES__DETAIL_36: '努力撰写中...',
  TEMPLATES__DETAIL_37: '嗨，我是AI秒出助手。您的智能帮手！',
  TEMPLATES__DETAIL_38: '你想把模版换成什么颜色？',
  TEMPLATES__DETAIL_39: '我想把主题换成{res}色',
  TEMPLATES__DETAIL_40: '好的，模板主色已换成{res}色， 看看效果吧！',
  TEMPLATES__DETAIL_41: '不换，我要保留原模板颜色',
  TEMPLATES__DETAIL_42: '不换',
  TEMPLATES__DETAIL_43: '你可以选择以下方式来丰富你的PPT内容',
  TEMPLATES__DETAIL_44: '我还可以帮你丰富PPT内容，你可以选择以下方式：',
  TEMPLATES__DETAIL_45: '直接编辑',
  TEMPLATES__DETAIL_46: '保持原文下载',
  TEMPLATES__DETAIL_47: '撤销',
  TEMPLATES__DETAIL_48: '已撤销',
};
