import createId from '@/pages/Edit/common/createId'

export const maxLength = 50
export const createVersion = () => ({
    pre: undefined,
    next: undefined,
    data: undefined,
    id: createId(),
})

export class History {
    constructor() {
        this.firstVersion = undefined
        this.lastVersion = undefined
        this.currentVersion = undefined
        this.onChange = undefined
    }

    /**
   * 前一个版本
   */
    preVersion() {
        if (this.currentVersion?.pre) {
            this.currentVersion = this.currentVersion.pre
            this.onChange?.()
        }
    }

    /**
   * 后一个版本
   */
    nextVersion() {
        if (this.currentVersion?.next) {
            this.currentVersion = this.currentVersion.next
            this.onChange?.()
        }
    }

    /**
   * 推入
   */
    push(payload) {
        const version = createVersion()
        version.data = payload
        if (!this.lastVersion) {
            version.index = 0
            this.firstVersion = version
            this.lastVersion = version
            this.currentVersion = version
        } else {
            this.currentVersion.next = version
            version.pre = this.currentVersion
            version.index = version.pre.index + 1
            this.lastVersion = version
            this.currentVersion = version
            if (this.currentVersion.index - this.firstVersion.index > maxLength) {
                this.firstVersion = this.firstVersion.next
                this.firstVersion.pre = undefined
            }
        }
        this.onChange?.()
    }

    /**
   * 清空
   */

    clear() {
        this.lastVersion = undefined
        this.currentVersion = undefined
        this.onChange?.()
    }

    get canNext() {
        return !!this.currentVersion?.next
    }

    get canPre() {
        return !!this.currentVersion?.pre
    }
}

export default new History()
