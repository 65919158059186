export function defaultDataList(defaultData = {}) {
  return _.merge(
    {
      value: {
        1: { sort: 1, value: {} },
        2: { sort: 2, value: {} },
        3: { sort: 3, value: {} },
      },
    },
    defaultData,
  );
}
export function defaultDataPlate(defaultData = {}) {
  return _.merge(
    {
      style: {},
      value: {
        image: {},
      },
    },
    defaultData,
  );
}
export function defaultDataDescribe(defaultData = {}) {
  return _.merge(
    {
      style: {},
      value: {
        text: {},
      },
    },
    defaultData,
  );
}
export function defaultDataFooter(defaultData = {}) {
  return _.merge(
    {
      style: {},
      value: {},
    },
    defaultData,
  );
}
export function defaultDataPPt(defaultData = {}) {
  return _.merge(
    {
      id: +_.uniqueId(''),
      themeId: 1,
      customTheme: {},
      pages: [],
    },
    defaultData,
  );
}
export function defaultDataPage(defaultData = {}) {
  return _.merge(
    {
      id: +_.uniqueId(''),
      type: '',
      templateId: 1,
      style: {},
      header: {},
      plate: {},
      footer: {},
      charts: {
        value: {},
      },
      List: {
        value: {},
      },
      Index: {
        value: {},
      },
      Transition: {
        value: {},
      },
    },
    defaultData,
  );
}
