export default {
  TEMPLATES_1: 'Template',
  TEMPLATES_2: 'Image',
  TEMPLATES_3: 'Icon',
  TEMPLATES_4: 'Workspace',
  TEMPLATES_5: '{text} Premium Slides Templates, {curPptAddNum} New Today',
  TEMPLATES_6: 'Creating Your Own Slides with AI Assisted by ChatGPT',
  TEMPLATES_7: 'Top Searches:',
  TEMPLATES_8: 'Work Report',
  TEMPLATES_9: 'Business',
  TEMPLATES_10: 'Business Plans',
  TEMPLATES_11: 'Simplicity',
  TEMPLATES_12: 'Education',
  TEMPLATES_13: 'Portfolio',
  TEMPLATES_14: 'Creative',
  TEMPLATES_15: 'Color',
  TEMPLATES_16: 'Style',
  TEMPLATES_17: 'Scene',
  TEMPLATES_18: 'Search',
  TEMPLATES_19: 'Generate',
  TEMPLATES_20: 'Search among 10+ million exquisite templates',
  TEMPLATES_21: 'Popular Search Content',
  TEMPLATES_22: 'Directory',
  TEMPLATES_23: 'Subtitle',
  TEMPLATES_24: 'KeyPoint',
  TEMPLATES_25: 'Title',
  TEMPLATES_26: 'Add',
  TEMPLATES_27: 'Point Description',
  TEMPLATES_28: 'Chapter Title',
  TEMPLATES_29: 'Enter Content',
  TEMPLATES_30: 'Enter Closing',
  TEMPLATES_31: 'Enter Point Title',
  TEMPLATES_32: 'Enter Point Description',
  TEMPLATES_33: 'Free Type or Paste Text...',
  TEMPLATES_34: 'Enter Subtitle up to 15 Characters',
  TEMPLATES_35: 'Enter Title up to 15 Characters',
  TEMPLATES_36: 'Enter Subtitle',
  TEMPLATES_37: 'Enter Chapter Title',
  TEMPLATES_38: 'Enter Chapter Number, e.g., 01',
  TEMPLATES_39: 'Enter Directory Content',
  TEMPLATES_40: 'Directory Title',
  TEMPLATES_41: 'Enter Content',
  TEMPLATES_42: 'Enter Report Date or Other Info',
  TEMPLATES_43: 'Enter Reporter Info or Other Description',
  TEMPLATES_44: 'Enter Subtitle',
  TEMPLATES_45: 'Enter Point Description',

};
