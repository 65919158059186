import { createSlice } from '@reduxjs/toolkit'
import initState from '@/pages/Edit/redux/initState'

export default createSlice({
    name: 'animaker',
    initialState: initState.animaker,
    reducers: {
        setBackgroundMusic(state, { payload }) {
            state.backgroundMusic = payload
        },
    },
})
