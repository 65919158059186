export default {
  TEMPLATES__DETAIL_1: 'Choose a Template,',
  TEMPLATES__DETAIL_2: 'ChatGPT Writes for You',
  TEMPLATES__DETAIL_3: 'Generate',
  TEMPLATES__DETAIL_4: 'Skip and Download',
  TEMPLATES__DETAIL_5: 'Download',
  TEMPLATES__DETAIL_6: 'Edit',
  TEMPLATES__DETAIL_7: 'Software',
  TEMPLATES__DETAIL_8: 'Format',
  TEMPLATES__DETAIL_9: 'Size',
  TEMPLATES__DETAIL_10: 'Number of Pages',
  TEMPLATES__DETAIL_11: 'Ratio',
  TEMPLATES__DETAIL_12: 'Author',
  TEMPLATES__DETAIL_13: 'Upload Time',
  TEMPLATES__DETAIL_14: 'Copyright',
  TEMPLATES__DETAIL_15: 'Copyright Statement, Piracy Will Be Prosecuted',
  TEMPLATES__DETAIL_16: 'Popular Tags',
  TEMPLATES__DETAIL_17: 'Featured Recommendations',
  TEMPLATES__DETAIL_18: 'Recommended for You',


  TEMPLATES__DETAIL_19: 'Search through tons of beautiful templates',
  TEMPLATES__DETAIL_20: 'Hi, I am a little helper in seconds. What do you want to do with powerpoint？',
  TEMPLATES__DETAIL_21: 'Help me write the content',
  TEMPLATES__DETAIL_22: 'I have content',
  TEMPLATES__DETAIL_23: 'Sounds good. What do you want to write about？',
  TEMPLATES__DETAIL_24: '？That sounds good. I have got some ideas…',
  TEMPLATES__DETAIL_25: 'Here is your outline. What do you think？',
  TEMPLATES__DETAIL_26: 'You can edit the outline or continue as is：',
  TEMPLATES__DETAIL_27: 'change',
  TEMPLATES__DETAIL_28: 'Generate PPT',
  TEMPLATES__DETAIL_29: 'Keep the original content',
  TEMPLATES__DETAIL_30: 'Animation Preview',
  TEMPLATES__DETAIL_31: 'Generating PPT',
  TEMPLATES__DETAIL_32: 'Transition Page',
  TEMPLATES__DETAIL_33: 'Generating PPT, please wait...',
  TEMPLATES__DETAIL_34: 'Generation Failed',
  TEMPLATES__DETAIL_35: 'Almost Done...',
  TEMPLATES__DETAIL_36: 'Working Hard on Writing...',
  TEMPLATES__DETAIL_37: 'Hi, I am the AI Instant Assistant. Your smart helper!',
  TEMPLATES__DETAIL_38: 'What color would you like to change the template to?',
  TEMPLATES__DETAIL_39: 'I want to change the theme to {res} color',
  TEMPLATES__DETAIL_40: 'Okay, the main color of the template has been changed to {res}, check out the effect!',
  TEMPLATES__DETAIL_41: 'Don\'t change, I want to keep the original template color',
  TEMPLATES__DETAIL_42: 'No',
  TEMPLATES__DETAIL_43: 'I can help you enrich the PPT content, you can choose from the following options:',
  TEMPLATES__DETAIL_44: 'I can still help you enrich the PPT content, you can choose from the following options:',
  TEMPLATES__DETAIL_45: 'Keep Original Text for Editing',
  TEMPLATES__DETAIL_46: 'Keep Original Text for Download',
  TEMPLATES__DETAIL_47: 'Undo',
  TEMPLATES__DETAIL_48: 'Undone'
};
