// 日志类型
export const GENERATE_PPT_SYLLABUS = 'GENERATE_PPT_SYLLABUS'
export const GENERATE_PPT_SYLLABUS_TEMPLATES_MORE = 'GENERATE_PPT_SYLLABUS_TEMPLATES_MORE'
export const GENERATE_PPT_SYLLABUS_TIME_MORE = 'GENERATE_PPT_SYLLABUS_TIME_MORE'
//
export const GENERATE_PPT_SYLLABUS_TIME_MORE_120 = 'GENERATE_PPT_SYLLABUS_TIME_MORE_120'
export const GENERATE_PPT_SYLLABUS_ERROR = 'GENERATE_PPT_SYLLABUS_ERROR'
export const GENERATE_PPT_PAGELIST_ERROR = 'GENERATE_PPT_PAGELIST_ERROR'
export const GENERATE_SYLLABUS_TIME_MORE = 'GENERATE_SYLLABUS_TIME_MORE'
export const GENERATE_SYLLABUS_TIME_MORE_1 = 'GENERATE_SYLLABUS_TIME_MORE_1'
export const GENERATE_PPT_SYLLABUS_TIMEOUT_ERROR = 'GENERATE_PPT_SYLLABUS_TIMEOUT_ERROR'

// 生成非内容页的PPT失败
export const GENERATE_NONE_CONTENT_PPT = 'GENERATE_NONE_CONTENT_PPT'

// 一键生成PPT内容失败
export const ONE_GENERATE_CONTENT_PPT = 'ONE_GENERATE_CONTENT_PPT'

// 一键生成PPT切换模板失败
export const ONE_GENERATE_CHANGE_TEMPLATE = 'ONE_GENERATE_CHANGE_TEMPLATE'

// 联网查询失败
export const ONLINE_QUERY = 'ONLINE_QUERY'

// 运行时异常
export const RUNTIME_ERROR = 'RUNTIME_ERROR'

// 未捕获的promise异常
export const UNHANDLED_PROMISE_REJECT = 'UNHANDLED_PROMISE_REJECT'

// 导出失败
export const EXPORT_PPT_ERROR = 'EXPORT_PPT_ERROR'

// 动画导出失败
export const EXPORT_PPT_ANIMATION_ERROR = 'EXPORT_PPT_ANIMATION_ERROR'

// 对话流生成内容失败重试
export const GENERATE_PPT_HTML_CONTENT_RE = 'GENERATE_PPT_HTML_CONTENT_RE'
