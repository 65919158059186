export const compressAndResizeImage = (src, { p = 70 } = {}) => {
    if (!src || !src.includes('http')) return src
    const url = new URL(src)
    if (window.isSupportWebp) {
        url.search = `x-oss-process=image/resize,p_${p}/format,webp`
    } else {
        url.search = `x-oss-process=image/resize,p_${p}`
    }

    return url.toString()
}
