import { buildMetadata } from '../utils';

export const getMetadata = (props) => {
  const { options, page } = props;
  if (!_.get(options, 'Index.value') || !_.get(page, 'Index')) {
    return null;
  }
  const metadata = buildMetadata(options.Index, page.Index)(options);
  metadata.valueKeys = metadata.valueKeys.filter(
    (d) => !!_.get(metadata, `value.${d}.norm`),
  );
  return metadata;
};

const valueKey = (indexkey, metakey) => {
  let str = 'Index.value';
  if (!indexkey) {
    return str;
  }
  str = `${str}.${indexkey}`;
  if (metakey) {
    return str;
  }
  return `${str}.value`;
};

const baseKeys = () => ['header', 'description', 'author', 'logo', 'date'];

export const getDocdata = (metadata) => {
  if (!metadata) {
    return {};
  }
  const body = {};
  baseKeys().forEach((name) => {
    const itemdata = metadata.value[name];
    if (!itemdata) {
      return;
    }
    body[name] = {
      value: itemdata.value,
      key: valueKey(name),
      type: itemdata.type,
      attrs: itemdata.attrs,
      metakey: valueKey(name, 1),
      // 暂未用，先放着
      itemkey: name,
    };
  });
  return body;
};

export const d2mUpdate = (index, page) => {
  if (!index) {
    return [];
  }
  const saveKeys = baseKeys()
    .map((name) => {
      const data = index[name];
      if (!data) {
        return [];
      }
      // 可以通过判断 data.key 是否存在来识别是新增还是修改操作
      // 但是没必要，因为都是要通过 _.set 设置
      _.set(page, valueKey(name), data.value);
      return ['Index'];
    })
    .reduce((a, b) => a.concat(b), []);

  return _.uniq(saveKeys);
};
export const d2mDelete = (index, page) => {
  if (!index) {
    return [];
  }
  const saveKeys = _.keys(index)
    .map((name) => {
      _.set(page, valueKey(name, 1), null);
      return ['Index'];
    })
    .reduce((a, b) => a.concat(b), []);

  return _.uniq(saveKeys);
};

export const templateBase = () => ({
  name: '封面页类别模板base数据',
  Index: {
    value: {
      logo: {
        norm: 1,
        sort: 1,
        extend: ['image.1'],
        value: '',
      },
      header: {
        norm: 1,
        sort: 5,
        extend: ['text.h1'],
        value: '',
        attrs: {
          // placeholder: '添加封面标题',
        },
      },
      description: {
        norm: 1,
        sort: 7,
        extend: ['text.h2'],
        settings: {
          _Title: '封面副标题',
        },
        attrs: {
          // placeholder: '添加封面副标题',
        },
      },
      author: {
        norm: 1,
        sort: 8,
        extend: ['text.normal'],
        attrs: {
          // placeholder: '演讲人信息',
        },
      },
      date: {
        norm: 1,
        sort: 9,
        extend: ['text.normal'],
      },
    },
  },
});
