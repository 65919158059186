import { idPathGetPageId } from './id-path';

export const temp = {
  quill: null,
  // 导出页面专用，元素计算队列
  exportQueue: null,
};

export function exportItemAdd(idPath, self) {
  if (!temp.exportQueue) {
    return;
  }
  const pageId = idPathGetPageId(idPath);
  if (!pageId) {
    return;
  }
  temp.exportQueue[pageId] = temp.exportQueue[pageId] || {};
  temp.exportQueue[pageId][idPath] = self;
}

export function exportItemDelete(idPath) {
  if (!temp.exportQueue) {
    return;
  }
  const pageId = idPathGetPageId(idPath);
  if (!temp.exportQueue[pageId]) {
    return;
  }
  delete temp.exportQueue[pageId][idPath];
}
