import { processMainPage } from './processMainPage'
import { processCatalogPage } from './processCatalogPage'
import { serialize } from '../serialize'
import { getTextContentByHTML } from '@/utils/text'
import { createListItem } from './utils'

export const processNodeDataToHTML = ({
    pageType,
    nodeType,
    nodeDataItem,
    pointType,
    prefix = '',
}) => {
    const type = nodeType
    const dataItem = nodeDataItem

    if (Array.isArray(dataItem) && dataItem.length) {
        if (nodeType === 'table') {
            // return createTableHTML(dataItem);
        }

        if (pageType === 'main') {
            return processMainPage(dataItem, pointType)
        }

        if (pageType === 'catalog') {
            return processCatalogPage(dataItem)
        }

        return serialize({
            type,
            children: dataItem.map((item) => createListItem(getTextContentByHTML(item.value))),
        })
    }

    const value = dataItem?.value
    if (value) {
        return serialize({
            type,
            children: [{ text: prefix + getTextContentByHTML(value) }],
        })
    }

    return ''
}
