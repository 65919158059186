import CKEditorInspector from '@ckeditor/ckeditor5-inspector'

let ckeditor
let flag = false

export const ckeditorGetter = () => {
    // debugger
    if (ckeditor) {
    // 销毁已实例化的编辑器实例
        return ckeditor
    }
    if (flag) {
        return
    }
    flag = true
    import('./MultirootEditor').then((module) => {
        module.default
            .create()
            .then((editor) => {
                ckeditor = editor
                window.ckeditor = editor
                // if (__LOC__) {
                //     CKEditorInspector.attach(ckeditor)
                // }
            })
            .catch((err) => {
                console.log('err', err)
                throw new Error(err.stack)
            })
    })

    return ckeditor
}
