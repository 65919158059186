export default {
  IMAGEMODAL_01: '全部',
  IMAGEMODAL_02: '商务贸易',
  IMAGEMODAL_03: '商业办公',
  IMAGEMODAL_04: '科学技术',
  IMAGEMODAL_05: '互联网',
  IMAGEMODAL_06: '人物情感',
  IMAGEMODAL_07: '教育文化',
  IMAGEMODAL_08: '节日节假',
  IMAGEMODAL_09: '背景素材',
  IMAGEMODAL_10: '建筑空间',
  IMAGEMODAL_11: '自然风景',
  IMAGEMODAL_12: '旅游度假',
  IMAGEMODAL_13: '餐饮美食',
  IMAGEMODAL_14: '美容养生',
  IMAGEMODAL_15: '室内家居',
  IMAGEMODAL_16: '生活方式',
  IMAGEMODAL_17: '健康医疗',
  IMAGEMODAL_18: '汽车交通',
  IMAGEMODAL_19: '其他',
  IMAGEMODAL_20: '历史上传',
  IMAGEMODAL_21: 'PNG免抠图',
  IMAGEMODAL_22: '修改主题',
  IMAGEMODAL_23: '智能配色',
  IMAGEMODAL_24: '统一字体',
  IMAGEMODAL_25: '应用效果中...',
  IMAGEMODAL_26: '是否应用美化效果',
  IMAGEMODAL_27: '您当前预览的效果尚未生效，需要应用美化效果吗',
  IMAGEMODAL_28: '应用并退出',
  IMAGEMODAL_29: '确定退出',
  'IMAGEMODAL_30': '暖色系',
  'IMAGEMODAL_31': '落日黄昏',
  'IMAGEMODAL_32': '蜜橘夏',
  'IMAGEMODAL_33': '橙味苏打',
  'IMAGEMODAL_34': '玫瑰花茶',
  'IMAGEMODAL_35': '炙热沙滩',
  'IMAGEMODAL_36': '香槟玫瑰',
  'IMAGEMODAL_37': '冷色系',
  'IMAGEMODAL_38': '科技蓝',
  'IMAGEMODAL_39': '经典蓝调',
  'IMAGEMODAL_40': '文艺森绿',
  'IMAGEMODAL_41': '缤纷绿植',
  'IMAGEMODAL_42': '夏日海边',
  'IMAGEMODAL_43': '葡萄果酱',
  'IMAGEMODAL_44': '中间色',
  'IMAGEMODAL_45': '古色古香',
  'IMAGEMODAL_46': '灰绿雪松',
  'IMAGEMODAL_47': '绿色脉络',
  'IMAGEMODAL_48': '烟尘滚滚',
  'IMAGEMODAL_49': '醇香咖啡',
  'IMAGEMODAL_50': '沧海烟云',
  'IMAGEMODAL_51': '我的配色',
  'IMAGEMODAL_52': '应用效果',
  'IMAGEMODAL_53': '支持ppt、pptx等格式文件',
  'IMAGEMODAL_54': '纯色填充',
  'IMAGEMODAL_55': '无填充',
  'IMAGEMODAL_56': '图片填充',
  'IMAGEMODAL_57': '无边框',
  'IMAGEMODAL_58': '纯色边框',
  IMAGEMODAL_59: '全部',
  IMAGEMODAL_60: '照片',

};
