import { createSlice } from '@reduxjs/toolkit';
import initState from '@/pages/Edit/redux/initState';

export default createSlice({
  name: 'onlyView',
  initialState: initState.current,
  reducers: {
    set(state, { payload }) {
      return payload;
    },
  },
});
