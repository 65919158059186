import localLanguage from './localConst';
import pay from './pay/zh-CN';
import user from './user/zh-CN';
import templates from '@/locales/templates/zh-CN';
import footer from '@/locales/footer/zh-CN';
import templateDetail from '@/locales/templateDetail/zh-CN';
import workspace from '@/locales/workspace/zh-CN';
import editContent from './edit-content/zh-CN';
import templateChange from './template-change/zh-CN';
import templateChangeMulti from './template-change-multi/zh-CN';
import echart from './echart/zh-CN';
import pptxIndex from './pptxIndex/zh-CN';
import errorModal from './components/ErrorModal/zh-CN';
import fileUpload from './components/FileUpload/zh-CN';
import imageModal from './components/ImageModal/zh-CN';
import personalCenter from './personalCenter/zh-CN';
import edit from './components/Edit/zh-CN';
import customUser from './customUser/zh-CN'

const obj = {
  [localLanguage.MIAO_CHU]: '秒出PPT',
  [localLanguage.ZAI_XIAN_ZHING_NENG]: '秒出PPT',
  [localLanguage.CONG_XIN]: '从心PPT',
  [localLanguage.BIG_SLOGAN]: '10秒做出PPT',
  [localLanguage.START_EDITING]: '开始编辑',
  [localLanguage.VIEW_TEMPLATE]: '浏览模板',
  [localLanguage.DES]: '10秒做出PPT',
  [localLanguage.LOCAL_UPLOAD]: '本地上传',
  [localLanguage.SECTION1]: 'AI一键帮你生成内容',
  [localLanguage.SUB_SECTION1]: '输入内容 10秒完成你的专属PPT',

  [localLanguage.SECTION2]: '多分类的精品设计素材 可供在线编辑',
  [localLanguage.SUB_SECTION2]: '3万+高质量、可商用模板',

  [localLanguage.SECTION3]: '随时随地开启协作模式',
  [localLanguage.SUB_SECTION3]: '一键分享链接，多人共同编辑，在线实时评论互动',

  [localLanguage.SECTION4]: '0门槛编辑器 让您轻松设计',
  [localLanguage.SUB_SECTION4]: '多款实用功能，助力一站式设计',

  [localLanguage.POINT1]: '海量免费模板',
  [localLanguage.SUB_POINT1]: '3万+的免费设计模板，轻松定制你的设计。',

  [localLanguage.POINT2]: '精美数据图表',
  [localLanguage.SUB_POINT2]: '创建精美的数据图表和信息图示，让信息更具影响力。',

  [localLanguage.POINT3]: '一键共享',
  [localLanguage.SUB_POINT3]: '链接分享设计，邀请好友加入写作，实现同步编辑、评论互动。',

  [localLanguage.POINT4]: '拖拽式编辑',
  [localLanguage.SUB_POINT4]: '使用人人都能直接上手的零门槛编辑器，分分钟开始设计创作。',

  [localLanguage.END_TEXT1]: '精选海量优质PPT模板',
  [localLanguage.END_TEXT2]: '智能模板一键切换',
  [localLanguage.MORE_BUTTON]: '更多模板',

  [localLanguage.ABOUT_US]: '关于我们',
  [localLanguage.USER_AGREE]: '用户协议',
  [localLanguage.ANNOUNCE]: '版权声明',

  [localLanguage.SUCAI]: '素材',
  [localLanguage.MUBAN]: '模板',
  [localLanguage.CREATE_CONTENT]: '生成内容',

};


export default Object.assign({},
  obj,
  pay,
  user,
  templates,
  footer,
  templateDetail,
  workspace,
  editContent,
  templateChange,
  templateChangeMulti,
  echart,
  pptxIndex,
  errorModal,
  fileUpload,
  imageModal,
  personalCenter,
  edit,
  customUser
);

