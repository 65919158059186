// 处理有错误数据的pageData

import { cloneDeep } from 'lodash'
import { checkChartOption, defaultLineChartOption } from '@/utils/charts'
import { INodeType } from '../type/INode'

export const processErrorDataPage = (page) => {
    try {
        const p = page
        Object.keys(p.nodes).forEach((key) => {
            const node = p.nodes[key]
            if (node.type === INodeType.charts) {
                if (!checkChartOption(node.value)) {
                    node.value = defaultLineChartOption()
                }
            }
        })
        return p
    } catch (error) {
        return page
    }
}
