import modelExtend from 'dva-model-extend'
import store from 'store'
import { model } from '@/utils/model'
import { lstoreGet, jsonClone } from '../web-utils'
import {
    axios_login_anonymous, getNewUserRemainingDetail, getUserInfo, getUserRemainingDetail, login_anonymous,
} from '@/service/services'

export default modelExtend(model, {
    namespace: 'user',
    state: {
    // 左侧边栏是否展开
        collapsed: !!lstoreGet('collapsed'),
        // location.match
        match: {},
        user: {
            loading: false,
            error: '',
            value: store.get('user'),
        },
        permission: {
            loading: false,
            error: null,
            byId: { test: {} },
            byKey: { test: {} },
            allIds: [],
            allKeys: [],
        },
        showCreateLimit: false,
        showUpqradeAccount: false,
    },
    subscriptions: {
        setup({ dispatch, history }) {
            dispatch({ type: 'fetchUser' })
        },
    },
    effects: {
        * fetchUser({ payload }, { call, put, select }) {
            try {
                const user = yield call(getUserInfo)
                user.token = user.accessToken
                let userId = user?.id || null
                try {
                    window.hj?.('identify', userId, {})
                } catch (e) {

                }

                store.set('user', user)
                yield put({
                    type: 'stateByAny',
                    payload: { user: { value: user } },
                })
                return user
            } catch (error) {
            }
        },
        * fetchAnonymousUser({ payload }, { call, put, select }) {
            try {
                const data = yield call(axios_login_anonymous, {}, { referrer: document.referrer })
                const user = data.data.data
                user.token = user.accessToken
                store.set('user', user)
                store.set('fakeUser', true)

                let userId = user?.id || null
                try {
                    window.hj?.('identify', userId, {})
                } catch (e) {

                }
                yield put({
                    type: 'stateByAny',
                    payload: { user: { value: user } },
                })
                return user
            } catch (error) {
            }
        },

        * fetchInfo({ payload }, { call, put, select }) {
            try {
                const userRemainingDetail = yield call(getNewUserRemainingDetail)
                yield put({
                    type: 'stateByAny',
                    payload: { userRemainingDetail },
                })
            } catch (error) {
            }
        },

        * logout({ payload }, { call, put, select }) {
            try {
                store.remove('user')
                yield put({
                    type: 'stateByAny',
                    payload: { user: { value: undefined } },
                })
                const user = yield call(login_anonymous)
                user.token = user.accessToken
                store.set('user', user)
                store.set('fakeUser', true)
                yield put({
                    type: 'stateByAny',
                    payload: { user: { value: user } },
                })
            } catch (error) {
            }
        },
        * login_anonymous({ payload }, { call, put, select }) {
            try {
                const user = yield call(login_anonymous)
                user.token = user.accessToken
                store.set('user', user)
                store.set('fakeUser', true)
                yield put({
                    type: 'stateByAny',
                    payload: { user: { value: user } },
                })
            } catch (error) {
            }
        },
    },
    reducers: {
        stateByAny(state, { payload }) {
            return { ...state, ...payload }
        },
        stateAny(state, { payload }) {
            return { ...state, ...payload }
        },
    },
})
