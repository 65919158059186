import thunk from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import reducers from './reducers'
import initState from './initState'

export const createStore = () => {
    return configureStore({
        reducer: reducers,
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState: initState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    })
}

export default createStore()
