export default function fileDownload(fileUrl, fileName, onPercent, onFinish) {
  // 已知至少兼容：Chrome、IE10+
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', fileUrl, true);
    xhr.responseType = 'blob';

    xhr.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          const percent = _.toInteger((100 * evt.loaded) / evt.total);
          onPercent?.(percent, fileName, fileUrl);
        }
      }, false);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(xhr.response);
      }
    };
    xhr.send();
  }).then((blob) => {
    if (window.navigator.msSaveOrOpenBlob || typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(new Blob([blob], { type: 'application/force-download' }), fileName);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_self';
      link.download = fileName;
      const evt = document.createEvent('MouseEvents');
      evt.initEvent('click', true, false);
      link.dispatchEvent(evt);
      window.URL.revokeObjectURL(link.href);
    }
    if (onFinish) {
      onFinish(fileName, fileUrl);
    }
  });
}
