import { createSlice } from '@reduxjs/toolkit';
import initState from '@/pages/Edit/redux/initState';



export default createSlice({
  name: 'svgTool',
  initialState: initState.svgTool,
  reducers: {
    set(state, { payload }) {
      return payload;
    },
    replace(state, { payload }) {
      return { ...state, ...payload };
    },
  },
});
