export default {
  IMAGEMODAL_01: 'IMAGEMODAL_01',
  IMAGEMODAL_02: 'IMAGEMODAL_02',
  IMAGEMODAL_03: 'IMAGEMODAL_03',
  IMAGEMODAL_04: 'IMAGEMODAL_04',
  IMAGEMODAL_05: 'IMAGEMODAL_05',
  IMAGEMODAL_06: 'IMAGEMODAL_06',
  IMAGEMODAL_07: 'IMAGEMODAL_07',
  IMAGEMODAL_08: 'IMAGEMODAL_08',
  IMAGEMODAL_09: 'IMAGEMODAL_09',
  IMAGEMODAL_10: 'IMAGEMODAL_10',
  IMAGEMODAL_11: 'IMAGEMODAL_11',
  IMAGEMODAL_12: 'IMAGEMODAL_12',
  IMAGEMODAL_13: 'IMAGEMODAL_13',
  IMAGEMODAL_14: 'IMAGEMODAL_14',
  IMAGEMODAL_15: 'IMAGEMODAL_15',
  IMAGEMODAL_16: 'IMAGEMODAL_16',
  IMAGEMODAL_17: 'IMAGEMODAL_17',
  IMAGEMODAL_18: 'IMAGEMODAL_18',
  IMAGEMODAL_19: 'IMAGEMODAL_19',
  IMAGEMODAL_20: 'IMAGEMODAL_20',
  IMAGEMODAL_21: 'IMAGEMODAL_21',
  IMAGEMODAL_22: 'IMAGEMODAL_22',
  IMAGEMODAL_23: 'IMAGEMODAL_23',
  IMAGEMODAL_24: 'IMAGEMODAL_24',
  IMAGEMODAL_25: 'IMAGEMODAL_25',
  IMAGEMODAL_26: 'IMAGEMODAL_26',
  IMAGEMODAL_27: 'IMAGEMODAL_27',
  IMAGEMODAL_28: 'IMAGEMODAL_28',
  IMAGEMODAL_29: 'IMAGEMODAL_29',
  IMAGEMODAL_30: 'IMAGEMODAL_30',
  IMAGEMODAL_31: 'IMAGEMODAL_31',
  IMAGEMODAL_32: 'IMAGEMODAL_32',
  IMAGEMODAL_33: 'IMAGEMODAL_33',
  IMAGEMODAL_34: 'IMAGEMODAL_34',
  IMAGEMODAL_35: 'IMAGEMODAL_35',
  IMAGEMODAL_36: 'IMAGEMODAL_36',
  IMAGEMODAL_37: 'IMAGEMODAL_37',
  IMAGEMODAL_38: 'IMAGEMODAL_38',
  IMAGEMODAL_39: 'IMAGEMODAL_39',
  IMAGEMODAL_40: 'IMAGEMODAL_40',
  IMAGEMODAL_41: 'IMAGEMODAL_41',
  IMAGEMODAL_42: 'IMAGEMODAL_42',
  IMAGEMODAL_43: 'IMAGEMODAL_43',
  IMAGEMODAL_44: 'IMAGEMODAL_44',
  IMAGEMODAL_45: 'IMAGEMODAL_45',
  IMAGEMODAL_46: 'IMAGEMODAL_46',
  IMAGEMODAL_47: 'IMAGEMODAL_47',
  IMAGEMODAL_48: 'IMAGEMODAL_48',
  IMAGEMODAL_49: 'IMAGEMODAL_49',
  IMAGEMODAL_50: 'IMAGEMODAL_50',
  IMAGEMODAL_51: 'IMAGEMODAL_51',
  IMAGEMODAL_52: 'IMAGEMODAL_52',
  IMAGEMODAL_53: 'IMAGEMODAL_53',
  IMAGEMODAL_54: 'IMAGEMODAL_54',
  IMAGEMODAL_55: 'IMAGEMODAL_55',
  IMAGEMODAL_56: 'IMAGEMODAL_56',
  IMAGEMODAL_57: 'IMAGEMODAL_57',
  IMAGEMODAL_58: 'IMAGEMODAL_58',
  IMAGEMODAL_59: 'IMAGEMODAL_59',
  IMAGEMODAL_60: 'IMAGEMODAL_60',
};
