import { findLast } from 'lodash'

export const REPLACE_BASE_STYLE = 'REPLACE_BASE_STYLE' // 文本->模板  模板默认样式
export const REPLACE_STEP_BY_TEMPLATE = 'REPLACE_STEP_BY_TEMPLATE' // 文本->模板    逐字替换
export const REPLACE_HTML_BY_TEMPLATE = 'REPLACE_HTML_BY_TEMPLATE' // HTML->模板    使用模板样式赋予HTML

const getBaseStyle = (html) => {
    let originStyle = {
        p: undefined,
        span: undefined,
    }
    const dom = new DOMParser().parseFromString(html, 'text/html')
    const p = dom.body.querySelector('p')
    const span = dom.body.querySelector('span')
    if (p) {
        originStyle.p = p.getAttribute('style')
    }
    if (span) {
        originStyle.span = span.getAttribute('style')
    }
    return originStyle
}

function containsTarget(str) {
    const regex = /[\u4e00-\u9fa5a-zA-Z0-9.,!?;:]+/
    return regex.test(str)
}

const getBaseStructure = (html) => {
    const dom = new DOMParser().parseFromString(html, 'text/html')
    let pList = dom.body.querySelectorAll('p')
    let p = findLast(Array.from(pList), (p) => containsTarget(p.textContent))
    let parent
    if (!p) {
        p = parent = document.createElement('p')
        const span = dom.body.querySelector('span')
        if (span) {
            parent.append(span)
        }
    } else {
        parent = p
    }
    let list = [parent.querySelector('span'), parent.querySelector('i'), parent.querySelector('s'), parent.querySelector('strong'), parent.querySelector('u')]

    p.innerHTML = ''
    let first
    let pre = p
    list.forEach((node, index) => {
        if (node && !first) {
            first = node
        }
        if (node) {
            node.innerHTML = ''
            pre.append(node)
            pre = node
        }
    })

    return p.outerHTML
}
const REPLACE_BASE_STYLE_Handler = (value, html) => {
    const baseHTML = getBaseStructure(html)

    const baseHTMLDOM = new DOMParser().parseFromString(baseHTML, 'text/html')
    let child = baseHTMLDOM.body.querySelector('p')
    while (child) {
        if (!child.firstChild) {
            break
        }
        child = child.firstChild
    }
    child.textContent = value
    return baseHTMLDOM.body.innerHTML
}
const REPLACE_STEP_BY_TEMPLATE_Handler = (value, html) => {
    let index = 0
    let lastTextNode
    const dom = new DOMParser().parseFromString(clearSpaceBetweenTab(html), 'text/html')
    const handler = (element, isFirst) => {
        if (!element) {
            return
        }
        element?.childNodes.forEach((child) => {
            // 最外层不能有空格
            if (isFirst && child?.nodeType === 3 && (child?.textContent === ' ' || child?.textContent === ' \n')) {
                child.nodeValue = ''
            }
            if (child?.nodeType === 3 && child?.textContent !== '\n' && child?.textContent !== ' \n') {
                if (child.nodeValue) {
                    if (index >= value.length) {
                        child.nodeValue = ''
                    } else if (child.nodeValue.length) {
                        child.nodeValue = value.slice(index, index + child.nodeValue.length)
                        index += child.nodeValue.length
                    }
                }
                lastTextNode = child
            }

            handler(child)
        })
    }
    handler(dom.body, true)
    if (lastTextNode) {
        lastTextNode.nodeValue += value.slice(index, value.length)
    }
    if (index === 0) {
        const span = dom.body.querySelector('span')
        if (span) {
            let deepElement = span
            while (deepElement) {
                if (deepElement.children[0]) {
                    deepElement = deepElement.children[0]
                } else {
                    break
                }
            }
            deepElement.textContent = value
        } else {
            return REPLACE_BASE_STYLE_Handler(value, html)
        }
    }
    return dom.body.innerHTML
}
const REPLACE_HTML_BY_TEMPLATE_Handler = (value, html) => {
    const baseStyle = getBaseStyle(html)
    const dom = new DOMParser().parseFromString(value, 'text/html')
    const pList = dom.body.querySelectorAll('p')
    if (pList.length === 0) {
        return REPLACE_BASE_STYLE_Handler(dom.body.textContent, html)
    }
    [...pList].forEach((p) => {
        if (baseStyle.p) {
            p.setAttribute('style', baseStyle.p)
        }
        const text = p.textContent
        p.innerHTML = ''
        const span = document.createElement('span')
        p.append(span)
        if (baseStyle.span) {
            span.setAttribute('style', baseStyle.span)
        }
        span.textContent = text
    })
    return dom.body.innerHTML
}
const handler = (value, html, options = {}) => {
    const { type } = options
    if (!html && value) {
        return value.indexOf('<p') === -1 ? `<p>${value}</p>` : value
    }
    if (!value) {
        return getBaseStructure(html)
    }
    if (html.indexOf('<ul') !== -1 || html.indexOf('<ol') !== -1) {
        return REPLACE_BASE_STYLE_Handler(value, html)
    }

    switch (type) {
        case REPLACE_BASE_STYLE: {
            return REPLACE_BASE_STYLE_Handler(value, html)
        }
        case REPLACE_STEP_BY_TEMPLATE: {
            return REPLACE_STEP_BY_TEMPLATE_Handler(value, html)
        }
        case REPLACE_HTML_BY_TEMPLATE: {
            return REPLACE_HTML_BY_TEMPLATE_Handler(value, html)
        }
        default: {
            return REPLACE_BASE_STYLE_Handler(value, html)
        }
    }
}
const clearSpaceBetweenTab = (html) => {
    return html.replace(/>\s+</g, '><')
}
export default handler
