export default {
  'EDIT_CONTENT_1': 'EDIT_CONTENT_1',
  'EDIT_CONTENT_2': 'EDIT_CONTENT_2',
  'EDIT_CONTENT_3': 'EDIT_CONTENT_3',
  'EDIT_CONTENT_4': 'EDIT_CONTENT_4',
  'EDIT_CONTENT_5': 'EDIT_CONTENT_5',
  'EDIT_CONTENT_6': 'EDIT_CONTENT_6',
  'EDIT_CONTENT_7': 'EDIT_CONTENT_7',
  'EDIT_CONTENT_8': 'EDIT_CONTENT_8',
  'EDIT_CONTENT_9': 'EDIT_CONTENT_9',
  'EDIT_CONTENT_10': 'EDIT_CONTENT_10',
  'EDIT_CONTENT_11': 'EDIT_CONTENT_11',
  'EDIT_CONTENT_12': 'EDIT_CONTENT_12',
  'EDIT_CONTENT_13': 'EDIT_CONTENT_13',
  'EDIT_CONTENT_14': 'EDIT_CONTENT_14',
  'EDIT_CONTENT_15': 'EDIT_CONTENT_15',
  'EDIT_CONTENT_16': 'EDIT_CONTENT_16',
  'EDIT_CONTENT_17': 'EDIT_CONTENT_17',
  'EDIT_CONTENT_18': 'EDIT_CONTENT_18',
  'EDIT_CONTENT_19': 'EDIT_CONTENT_19',
  'EDIT_CONTENT_20': 'EDIT_CONTENT_20',
  'EDIT_CONTENT_21': 'EDIT_CONTENT_21',
  'EDIT_CONTENT_22': 'EDIT_CONTENT_22',
  'EDIT_CONTENT_23': 'EDIT_CONTENT_23',
  'EDIT_CONTENT_24': 'EDIT_CONTENT_24',
  'EDIT_CONTENT_25': 'EDIT_CONTENT_25',
  'EDIT_CONTENT_26': 'EDIT_CONTENT_26',
  'EDIT_CONTENT_27': 'EDIT_CONTENT_27',
  'EDIT_CONTENT_28': 'EDIT_CONTENT_28',
  'EDIT_CONTENT_29': 'EDIT_CONTENT_29',
  'EDIT_CONTENT_30': 'EDIT_CONTENT_30',
  'EDIT_CONTENT_31': 'EDIT_CONTENT_31',
  'EDIT_CONTENT_32': 'EDIT_CONTENT_32',
  'EDIT_CONTENT_33': 'EDIT_CONTENT_33',
  'EDIT_CONTENT_34': 'EDIT_CONTENT_34',
  'EDIT_CONTENT_35': 'EDIT_CONTENT_35',
  'EDIT_CONTENT_36': 'EDIT_CONTENT_36',
  'EDIT_CONTENT_37': 'EDIT_CONTENT_37',
  'EDIT_CONTENT_38': 'EDIT_CONTENT_38',
  'EDIT_CONTENT_39': 'EDIT_CONTENT_39',
  'EDIT_CONTENT_40': 'EDIT_CONTENT_40',
  'EDIT_CONTENT_41': 'EDIT_CONTENT_41',
  'EDIT_CONTENT_42': 'EDIT_CONTENT_42',
  'EDIT_CONTENT_43': 'EDIT_CONTENT_43',
  'EDIT_CONTENT_44': 'EDIT_CONTENT_44',
  'EDIT_CONTENT_45': 'EDIT_CONTENT_45',
  'EDIT_CONTENT_46': 'EDIT_CONTENT_46',
  'EDIT_CONTENT_47': 'EDIT_CONTENT_47',
  'EDIT_CONTENT_48': 'EDIT_CONTENT_48',
  'EDIT_CONTENT_49': 'EDIT_CONTENT_49',
  'EDIT_CONTENT_50': 'EDIT_CONTENT_50',
  'EDIT_CONTENT_51': 'EDIT_CONTENT_51',
  'EDIT_CONTENT_52': 'EDIT_CONTENT_52',
  'EDIT_CONTENT_53': 'EDIT_CONTENT_53',
  'EDIT_CONTENT_54': 'EDIT_CONTENT_54',
  'EDIT_CONTENT_55': 'EDIT_CONTENT_55',
  'EDIT_CONTENT_56': 'EDIT_CONTENT_56',
  'EDIT_CONTENT_57': 'EDIT_CONTENT_57',
  'EDIT_CONTENT_58': 'EDIT_CONTENT_58',
  'EDIT_CONTENT_59': 'EDIT_CONTENT_59',
  'EDIT_CONTENT_60': 'EDIT_CONTENT_60',
  'EDIT_CONTENT_61': 'EDIT_CONTENT_61',
  'EDIT_CONTENT_62': 'EDIT_CONTENT_62',
  'EDIT_CONTENT_63': 'EDIT_CONTENT_63',
  'EDIT_CONTENT_64': 'EDIT_CONTENT_64',
  'EDIT_CONTENT_65': 'EDIT_CONTENT_65',
  'EDIT_CONTENT_66': 'EDIT_CONTENT_66',
  'EDIT_CONTENT_67': 'EDIT_CONTENT_67',
  'EDIT_CONTENT_68': 'EDIT_CONTENT_68',
  'EDIT_CONTENT_69': 'EDIT_CONTENT_69',
  'EDIT_CONTENT_70': 'EDIT_CONTENT_70',
  'EDIT_CONTENT_71': 'EDIT_CONTENT_71',
  'EDIT_CONTENT_72': 'EDIT_CONTENT_72',
  'EDIT_CONTENT_73': 'EDIT_CONTENT_73',
  'EDIT_CONTENT_74': 'EDIT_CONTENT_74',
  'EDIT_CONTENT_75': 'EDIT_CONTENT_75',
  'EDIT_CONTENT_76': 'EDIT_CONTENT_76',
  'EDIT_CONTENT_77': 'EDIT_CONTENT_77',
  'EDIT_CONTENT_78': 'EDIT_CONTENT_78',
  'EDIT_CONTENT_79': 'EDIT_CONTENT_79',
  'EDIT_CONTENT_80': 'EDIT_CONTENT_80',
  'EDIT_CONTENT_81': 'EDIT_CONTENT_81',
  'EDIT_CONTENT_82': 'EDIT_CONTENT_82',
  'EDIT_CONTENT_83': 'EDIT_CONTENT_83',
  'EDIT_CONTENT_84': 'EDIT_CONTENT_84',
  'EDIT_CONTENT_85': 'EDIT_CONTENT_85',
  'EDIT_CONTENT_86': 'EDIT_CONTENT_86',
  'EDIT_CONTENT_87': 'EDIT_CONTENT_87',
  'EDIT_CONTENT_88': 'EDIT_CONTENT_88',
  'EDIT_CONTENT_89': 'EDIT_CONTENT_89',
  'EDIT_CONTENT_90': 'EDIT_CONTENT_90',
  'EDIT_CONTENT_91': 'EDIT_CONTENT_91',
  'EDIT_CONTENT_92': 'EDIT_CONTENT_92',
  'EDIT_CONTENT_93': 'EDIT_CONTENT_93',
  'EDIT_CONTENT_94': 'EDIT_CONTENT_94',
  'EDIT_CONTENT_95': 'EDIT_CONTENT_95',
  'EDIT_CONTENT_96': 'EDIT_CONTENT_96',
  'EDIT_CONTENT_97': 'EDIT_CONTENT_97',
  'EDIT_CONTENT_98': 'EDIT_CONTENT_98',
  'EDIT_CONTENT_99': 'EDIT_CONTENT_99',
  'EDIT_CONTENT_100': 'EDIT_CONTENT_100',
  'EDIT_CONTENT_101': 'EDIT_CONTENT_101',
  'EDIT_CONTENT_102': 'EDIT_CONTENT_102',
  'EDIT_CONTENT_103': 'EDIT_CONTENT_103',
  'EDIT_CONTENT_104': 'EDIT_CONTENT_104',
  'EDIT_CONTENT_105': 'EDIT_CONTENT_105',
  'EDIT_CONTENT_106': 'EDIT_CONTENT_106',
  'EDIT_CONTENT_107': 'EDIT_CONTENT_107',
  'EDIT_CONTENT_108': 'EDIT_CONTENT_108',
  'EDIT_CONTENT_109': 'EDIT_CONTENT_109',
  'EDIT_CONTENT_110': 'EDIT_CONTENT_110',
  'EDIT_CONTENT_111': 'EDIT_CONTENT_111',
  'EDIT_CONTENT_112': 'EDIT_CONTENT_112',
  'EDIT_CONTENT_113': 'EDIT_CONTENT_113',
  'EDIT_CONTENT_114': 'EDIT_CONTENT_114',
  'EDIT_CONTENT_115': 'EDIT_CONTENT_115',
  'EDIT_CONTENT_116': 'EDIT_CONTENT_116',
  'EDIT_CONTENT_117': 'EDIT_CONTENT_117',
  'EDIT_CONTENT_118': 'EDIT_CONTENT_118',
  'EDIT_CONTENT_119': 'EDIT_CONTENT_119',
  'EDIT_CONTENT_120': 'EDIT_CONTENT_120',
  'EDIT_CONTENT_121': 'EDIT_CONTENT_121',
  'EDIT_CONTENT_122': 'EDIT_CONTENT_122',
  'EDIT_CONTENT_123': 'EDIT_CONTENT_123',
  'EDIT_CONTENT_124': 'EDIT_CONTENT_124',
  'EDIT_CONTENT_125': 'EDIT_CONTENT_125',
  'EDIT_CONTENT_126': 'EDIT_CONTENT_126',
  'EDIT_CONTENT_127': 'EDIT_CONTENT_127',
  'EDIT_CONTENT_128': 'EDIT_CONTENT_128',
  'EDIT_CONTENT_129': 'EDIT_CONTENT_129',
  'EDIT_CONTENT_130': 'EDIT_CONTENT_130',
  'EDIT_CONTENT_132': 'EDIT_CONTENT_132',
  'EDIT_CONTENT_131': 'EDIT_CONTENT_131',
  'EDIT_CONTENT_133': 'EDIT_CONTENT_133',
  'EDIT_CONTENT_134': 'EDIT_CONTENT_134',
  'EDIT_CONTENT_135': 'EDIT_CONTENT_135',
  'EDIT_CONTENT_136': 'EDIT_CONTENT_136',
  'EDIT_CONTENT_137': 'EDIT_CONTENT_137',
  'EDIT_CONTENT_138': 'EDIT_CONTENT_138',
  'EDIT_CONTENT_139': 'EDIT_CONTENT_139',
  'EDIT_CONTENT_140': 'EDIT_CONTENT_140',
  'EDIT_CONTENT_141': 'EDIT_CONTENT_141',
  'EDIT_CONTENT_142': 'EDIT_CONTENT_142',
  'EDIT_CONTENT_143': 'EDIT_CONTENT_143',
  'EDIT_CONTENT_144': 'EDIT_CONTENT_144',
  'EDIT_CONTENT_145': 'EDIT_CONTENT_145',
  'EDIT_CONTENT_146': 'EDIT_CONTENT_146',
  'EDIT_CONTENT_147': 'EDIT_CONTENT_147',
  'EDIT_CONTENT_148': 'EDIT_CONTENT_148',
  'EDIT_CONTENT_149': 'EDIT_CONTENT_149',
  'EDIT_CONTENT_150': 'EDIT_CONTENT_150',
  'EDIT_CONTENT_151': 'EDIT_CONTENT_151',
  'EDIT_CONTENT_152': 'EDIT_CONTENT_152',
  'EDIT_CONTENT_153': 'EDIT_CONTENT_153',
  'EDIT_CONTENT_154': 'EDIT_CONTENT_154',
  'EDIT_CONTENT_155': 'EDIT_CONTENT_155',
  'EDIT_CONTENT_156': 'EDIT_CONTENT_156',
  'EDIT_CONTENT_157': 'EDIT_CONTENT_157',
  'EDIT_CONTENT_158': 'EDIT_CONTENT_158',
  'EDIT_CONTENT_159': 'EDIT_CONTENT_159',
  'EDIT_CONTENT_160': 'EDIT_CONTENT_160',
  'EDIT_CONTENT_161': 'EDIT_CONTENT_161',
  'EDIT_CONTENT_162': 'EDIT_CONTENT_162',
  'EDIT_CONTENT_163': 'EDIT_CONTENT_163',
  'EDIT_CONTENT_164': 'EDIT_CONTENT_164',
  'EDIT_CONTENT_165': 'EDIT_CONTENT_165',
  'EDIT_CONTENT_166': 'EDIT_CONTENT_166',
  'EDIT_CONTENT_167': 'EDIT_CONTENT_167',
  'EDIT_CONTENT_168': 'EDIT_CONTENT_168',
  'EDIT_CONTENT_169': 'EDIT_CONTENT_169',
  'EDIT_CONTENT_170': 'EDIT_CONTENT_170',
  'EDIT_CONTENT_171': 'EDIT_CONTENT_171',
  'EDIT_CONTENT_172': 'EDIT_CONTENT_172',
  'EDIT_CONTENT_173': 'EDIT_CONTENT_173',
  'EDIT_CONTENT_174': 'EDIT_CONTENT_174',
  'EDIT_CONTENT_175': 'EDIT_CONTENT_175',
  'EDIT_CONTENT_176': 'EDIT_CONTENT_176',
  'EDIT_CONTENT_177': 'EDIT_CONTENT_177',
  'EDIT_CONTENT_178': 'EDIT_CONTENT_178',
  'EDIT_CONTENT_179': 'EDIT_CONTENT_179',
  'EDIT_CONTENT_180': 'EDIT_CONTENT_180',
  'EDIT_CONTENT_181': 'EDIT_CONTENT_181',
  'EDIT_CONTENT_182': 'EDIT_CONTENT_182',
  'EDIT_CONTENT_183': 'EDIT_CONTENT_183',
  'EDIT_CONTENT_184': 'EDIT_CONTENT_184',
  'EDIT_CONTENT_185': 'EDIT_CONTENT_185',
  'EDIT_CONTENT_186': 'EDIT_CONTENT_186',
  'EDIT_CONTENT_187': 'EDIT_CONTENT_187',
  'EDIT_CONTENT_188': 'EDIT_CONTENT_188',
  'EDIT_CONTENT_189': 'EDIT_CONTENT_189',
  'EDIT_CONTENT_190': 'EDIT_CONTENT_190',
  EDIT_CONTENT_191:"EDIT_CONTENT_191",
  'EDIT_CONTENT_192': 'EDIT_CONTENT_192',
};
