import modelExtend from 'dva-model-extend';
import { model } from '@/utils/model';
import { jsonClone } from '../web-utils/index';
export default modelExtend(model, {
  namespace: 'pptxActive',
  state: {
    key: '',
  },
  subscriptions: {},
  effects: {},
  reducers: {
    stateByAny(state, { payload }) {
      return { ...state, ...payload };
    },
    stateAny(state, { payload }) {
      return { ...state, ...payload };
    },
    stateClear(state) {
      _.assign(state, jsonClone(state));
      return _.assign({}, state);
    },
  },
});
