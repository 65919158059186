import localConst from './localConst';
import pay from './pay/en-US';
import user from './user/en-US';
import templates from './templates/en-US';
import footer from './footer/en-US';
import templateDetail from './templateDetail/en-US';
import workspace from './workspace/en-US';
import editContent from './edit-content/en-US';
import templateChange from './template-change/en-US';
import templateChangeMulti from './template-change-multi/en-US';
import echart from './echart/en-US';
import localLanguage from '@/locales/localConst'
import pptxIndex from './pptxIndex/en-US';
import errorModal from './components/ErrorModal/en-US';
import fileUpload from './components/FileUpload/en-US';
import imageModal from './components/ImageModal/en-US';
import personalCenter from './personalCenter/en-US';
import edit from './components/Edit/en-US';
import customUser from './customUser/en-US'

let obj = {
  [localConst.MIAO_CHU]: 'SlideInstantly',
  [localConst.ZAI_XIAN_ZHING_NENG]: 'SlideInstantly',
  [localConst.CONG_XIN]: 'CongXin',
  [localConst.DES]: 'Powerpoint in 10 seconds',
  [localConst.BIG_TITLE]: 'SecSlide',
  [localConst.BIG_SLOGAN]: 'Create a Slide in 10 Sec',
  [localConst.START_EDITING]: 'Start Editing',
  [localConst.VIEW_TEMPLATE]: 'View Templates',
  [localLanguage.LOCAL_UPLOAD]: 'Local upload',

  [localConst.SECTION1]: 'ChatGPT Generates Content with 1 Click',
  [localConst.SUB_SECTION1]: 'Input Theme and Create Your Exclusive Slide in 10 Sec',

  [localConst.SECTION2]: 'Multi-Class Design Materials for Online Editing',
  [localConst.SUB_SECTION2]: '30K+ High-Quality and Commercially Available Templates',

  [localConst.SECTION3]: 'Collaborate Anytime and Anywhere',
  [localConst.SUB_SECTION3]: 'Share Links with 1 Click and Edit Together in Real-Time',

  [localConst.SECTION4]: 'Zero-Threshold Editor for Easy Design',
  [localConst.SUB_SECTION4]: 'Multiple Practical Functions for One-Stop Design',

  [localConst.POINT1]: 'Massive Free Templates',
  [localConst.SUB_POINT1]: '30K+ Free Design Templates for Easy Customization',

  [localConst.POINT2]: 'Exquisite Data Charts',
  [localConst.SUB_POINT2]: 'Create Beautiful Data Charts and Infographics',

  [localConst.POINT3]: 'One-Click Sharing',
  [localConst.SUB_POINT3]: 'Share Designs with Links, Invite Friends to Collaborate',

  [localConst.POINT4]: 'Drag and Drop Editing',
  [localConst.SUB_POINT4]: 'Use a Zero-Threshold Editor for Quick Design',

  [localConst.END_TEXT1]: 'Selected High-Quality Slide Templates',
  [localConst.END_TEXT2]: 'One-Click Switch between Exquisite Templates',
  [localConst.MORE_BUTTON]: 'More',

  [localConst.ABOUT_US]: 'About Us',
  [localConst.USER_AGREE]: 'User Agreement',
  [localConst.ANNOUNCE]: 'Copyright Statement',

  [localConst.SUCAI]: 'Assets',
  [localConst.MUBAN]: 'Templates',
  [localLanguage.CREATE_CONTENT]: 'Create Content',

};
export default Object.assign({},
  obj,
  pay,
  user,
  templates,
  footer,
  templateDetail,
  workspace,
  editContent,
  templateChange,
  templateChangeMulti,
  echart,
  pptxIndex,
  errorModal,
  fileUpload,
  imageModal,
  personalCenter,
  edit,
  customUser
);

