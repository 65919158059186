export default {
    PAY_1: 'VIP会员特权',
    PAY_2: 'PPT下载限制 ',
    PAY_3: 'AI生成页数',
    PAY_4: '单个PPT页数限制',
    PAY_5: '工作台同时编辑文件数量',
    PAY_6: '回收站保留',
    PAY_7: '文件下载记录',
    PAY_8: '文件上传空间',
    PAY_9: '协同设计可邀',
    PAY_10: 'SVIP专属精美模板',
    PAY_11: '优先客服支持',
    PAY_12: 'PPT插件',
    PAY_13: '后续高级功能永久免费使用',
    PAY_14: 'VIP/SVIP权益对比',
    PAY_15: '限时1折，即将涨价',
    PAY_16: '限时2折！',
    PAY_17: '体验首选',
    PAY_18: '终身SVIP',
    PAY_19: '年SVIP',
    PAY_20: '月SVIP',
    PAY_21: '原价',
    PAY_22: '应付金额',
    PAY_23: '已优惠',
    PAY_24: '支付即视为你同意',
    PAY_25: '如有疑问',
    PAY_26: '联系客服',
    PAY_27: 'SVIP会员特权',
    PAY_28: '次',
    PAY_29: '月',
    PAY_30: '页',
    PAY_31: '个',
    PAY_32: '天',
    PAY_33: '人',
    PAY_34: '限时5折！',
    PAY_35: '终身VIP',
    PAY_36: '年VIP',
    PAY_37: '月VIP',
    PAY_38: 'SVIP会员',
    PAY_39: 'VIP会员',
    PAY_40: '《会员协议》',
    PAY_41: '条例',
    PAY_42: '元',
    PAY_43: '3年VIP',
    PAY_44: '3年SVIP',
    PAY_45: '3年VIP',
    PAY_46: '智能办公时代，要么善用AI，要么被AI取代',
    PAY_47: '升级SVIP会员',
    PAY_48: '终身SVIP专享优惠券，已减',
    PAY_49: '距今日活动结束剩余：',
    PAY_50: '限时特惠，即将下线',
    PAY_51: '超值特惠',
}
