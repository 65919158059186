export default {
  TEMPLATES_1: 'PPT模板',
  TEMPLATES_2: '图片素材',
  TEMPLATES_3: '精选图标',
  TEMPLATES_4: '进入工作台',
  TEMPLATES_5: '{text}个精品PPT模板 今日更新{curPptAddNum}个',
  TEMPLATES_6: 'AI帮你写 创建属于自己的PPT',
  TEMPLATES_7: '热门搜索:',
  TEMPLATES_8: '工作总结',
  TEMPLATES_9: '商务',
  TEMPLATES_10: '商业计划书',
  TEMPLATES_11: '简约',
  TEMPLATES_12: '培训课件',
  TEMPLATES_13: '个人简历',
  TEMPLATES_14: '创意',
  TEMPLATES_15: '颜色',
  TEMPLATES_16: '风格',
  TEMPLATES_17: '场景',
  TEMPLATES_18: '搜索模板',
  TEMPLATES_19: '自动生成',
  TEMPLATES_20: '在{res}万+精美模板中搜索',
  TEMPLATES_21: '热门搜索内容',
  TEMPLATES_22: '目录项',
  TEMPLATES_23: '副标题',
  TEMPLATES_24: '要点',
  TEMPLATES_25: '标题',
  TEMPLATES_26: '添加',
  TEMPLATES_27: '要点描述',
  TEMPLATES_28: '章节标题',
  TEMPLATES_29: '请输入内容',
  TEMPLATES_30: '输入结束语',
  TEMPLATES_31: '输入要点标题',
  TEMPLATES_32: '输入要点描述',
  TEMPLATES_33: '自由输入或粘贴文字…',
  TEMPLATES_34: '输入15字以内的幻灯片副标题',
  TEMPLATES_35: '输入15字以内的幻灯片标题',
  TEMPLATES_36: '输入副标题',
  TEMPLATES_37: '输入章节标题',
  TEMPLATES_38: '输入章节编号，例如01',
  TEMPLATES_39: '输入目录内容',
  TEMPLATES_40: '目录标题',
  TEMPLATES_41: '输入内容',
  TEMPLATES_42: '输入汇报日期或其他信息',
  TEMPLATES_43: '输入汇报人信息或其他描述',
  TEMPLATES_44: '请输入副标题',
  TEMPLATES_45: '输入要点描述',
};
