// dev测试

const accountMap = {
    testVip: {
        id: 823630, mobile: '', accessToken: 'cqllKDzyawaP1E4oEBaQcn2zfNlyN/M2YWmU7ueKVmyK92FdvIkcIK0wXq6XgYxKUNmsiCGTw6VhH6zT97Pzog==', jwtToken: null, userName: '用户823630', icon: 'https://oss.jianzeppt.com/headerIcon/icon_28_1.svg', mail: null, email: null, roleId: -1, isFirstLogin: false, downloadBeans: null, realName: '', industry: null, company: '', invitationName: '', invitation: '-1', vipTime: 1752614178000, editableVipTime: 1752614178000, isAgree: null, isWXLogin: true, isPhoneLogin: false, isEmailLogin: false, is_half_price: 0, isAb: 1, canUpgrade: null, vipType: 22, isOldAutoUpgrade: false, group: null, isSingle: null, admin: true, token: 'cqllKDzyawaP1E4oEBaQcn2zfNlyN/M2YWmU7ueKVmyK92FdvIkcIK0wXq6XgYxKUNmsiCGTw6VhH6zT97Pzog==',
    },
}

export const JzDebug = (json) => {
    // 切换账号功能
}
