export default {

  ECHART_1: 'ECHART_1',
  ECHART_2: 'ECHART_2',
  ECHART_3: 'ECHART_3',
  ECHART_4: 'ECHART_4',
  ECHART_5: 'ECHART_5',
  ECHART_6: 'ECHART_6',
  ECHART_7: 'ECHART_7',
  ECHART_8: 'ECHART_8',
  ECHART_9: 'ECHART_9',
  ECHART_10: 'ECHART_10',
  ECHART_11: 'ECHART_11',
  ECHART_12: 'ECHART_12',
  ECHART_13: 'ECHART_13',
  ECHART_14: 'ECHART_14',
  ECHART_15: 'ECHART_15',
  ECHART_16: 'ECHART_16',
  ECHART_17: 'ECHART_17',
  ECHART_18: 'ECHART_18',
  ECHART_19: 'ECHART_19',
  ECHART_20: 'ECHART_20',
  ECHART_21: 'ECHART_21',
  ECHART_22: 'ECHART_22',
  ECHART_23: 'ECHART_23',
  ECHART_24: 'ECHART_24',
  ECHART_25: 'ECHART_25',
  ECHART_26: 'ECHART_26',
  ECHART_27: 'ECHART_27',
  ECHART_28: 'ECHART_28',
  ECHART_29: 'ECHART_29',
  ECHART_30: 'ECHART_30',
  ECHART_31: 'ECHART_31',
  ECHART_32: 'ECHART_32',
  ECHART_33: 'ECHART_33',
  ECHART_34: 'ECHART_34',
  ECHART_35: 'ECHART_35',
  ECHART_36: 'ECHART_36',
  ECHART_37: 'ECHART_37',
  ECHART_38: 'ECHART_38',
  ECHART_39: 'ECHART_39',
  ECHART_40: 'ECHART_40',
  ECHART_41: 'ECHART_41',
  ECHART_42: 'ECHART_42',
  ECHART_43: 'ECHART_43',
  ECHART_44: 'ECHART_44',
  ECHART_47: 'ECHART_47',
  ECHART_48: 'ECHART_48',
  ECHART_49: 'ECHART_49',
  ECHART_50: 'ECHART_50',
  ECHART_51: 'ECHART_51',
  ECHART_52: 'ECHART_52',
  ECHART_53: 'ECHART_53',
  ECHART_54: 'ECHART_54',
  ECHART_55: 'ECHART_55',
  ECHART_56: 'ECHART_56',
  ECHART_57: 'ECHART_57',
  ECHART_58: 'ECHART_58',
  ECHART_59: 'ECHART_59',
  ECHART_60: 'ECHART_60',
  ECHART_61: 'ECHART_61',
  ECHART_62: 'ECHART_62',
  ECHART_63: 'ECHART_63',
};
