import { formatMessage} from "umi";
import localConst from "@/locales/localConst";


// 默认图表样式
export const defaultLineChartOption = () => {
    return {
        toolbox: {
            feature: {
                saveAsImage: {
                    name: '图表',
                },
            },
        },
        title: {
            show: false,
        },
        tooltip: {},
        legend: {
            show: false,
        },
        xAxis: {
            axisTick: {
                show: false,  // 隐藏小刻度
            },
            axisLabel: {
                interval: 0,
                textStyle: {
                    color: '#333'  // 设置x轴标签颜色为黑色
                },
            },
            axisLine: {
                lineStyle: {
                    color: '#e6e6e6'  // 设置x轴线条颜色为红色
                }
            },
            data: [2017, 2018, 2019, 2020, 2021, 2022],
        },
        yAxis: {
            splitLine: {
                show: true,  // 用于显示或隐藏这些线，通常默认是true
                lineStyle: {
                    color: '#e6e6e6',  // 设置平行线的颜色
                    type: 'solid',  // 可选的类型有 'solid', 'dashed', 'dotted'
                    width: 1  // 设置线的宽度
                }
            },
            axisLabel: {
                color: '#333'
            }
        },
        series: [
            {
                name: formatMessage({ id: localConst.ECHART_53 }),
                type: 'line',
                symbol: 'none',
                itemStyle: {
                    color: '#11BBA6'
                },
                barWidth: '35%',
                data: [2300, 1250, 1300, 1700, 500, 2400],
            },
        ],
    }
}
