import isThemeNodeById from '@/pages/Edit/common/isThemeNodeById.';
import getNodeMarkKeyById from '@/pages/Edit/common/getNodeMarkKeyById';
import createNodeId from '@/pages/Edit/common/createNodeId';

export default (page, node) => {
  let nodes;
  if (node instanceof Array) {
    nodes = node;
  } else if (node) {
    nodes = [node]
  }
  nodes?.forEach?.((node)=>{
    page.init = true;
    if (isThemeNodeById(node?.id)) {
      node = JSON.parse(JSON.stringify(node));
      const path = getNodeMarkKeyById(page.nodeMark, node?.id);
      const themeNodeId = node.id;
      const id = createNodeId();
      _.set(page.nodeMark, path, id);
      node.id = id;
      node.themeNodeId = themeNodeId;
      if (page.excludedThemeNodes) {
        page.excludedThemeNodes.push(themeNodeId);
      } else {
        page.excludedThemeNodes = [themeNodeId];
      }
    }
    page.nodes[node.id] = node;
  })
}
