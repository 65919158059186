function extractBoldTexts(htmlString) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const boldElements = doc.querySelectorAll('strong')
    return Array.from(boldElements).map((bold) => bold.innerHTML).filter((text) => text)
}
function applyBoldToTextNodes(node, boldTexts) {
    // 重复调用时，有可能重复添加strong标签，因此如果文本被加粗了，则退出
    if (node.parentNode && node.parentNode.nodeName === 'STRONG') {
        return
    }

    if (node.nodeType === Node.TEXT_NODE) {
        let textContent = node.textContent
        boldTexts.forEach((text) => {
            textContent = textContent.replace(new RegExp(text, 'g'), `<strong>${text}</strong>`)
        })
        node.replaceWith(...parseHTMLString(textContent))
    } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach((child) => applyBoldToTextNodes(child, boldTexts))
    }
}

function parseHTMLString(htmlString) {
    const template = document.createElement('template')
    template.innerHTML = htmlString
    return template.content.childNodes
}

export function applyBoldFromSourceToTarget(targetHtml, sourceHtml) {
    const boldTexts = extractBoldTexts(sourceHtml)
    const parser = new DOMParser()
    const doc = parser.parseFromString(targetHtml, 'text/html')

    applyBoldToTextNodes(doc.body, boldTexts)

    return doc.body.innerHTML
}

applyBoldFromSourceToTarget('<p>张三去玩了</p>', '<strong>张三</strong>去玩了')
