export default {
  PERSONAL_01: '绑定成功',
  PERSONAL_02: '绑定成功，绑定后即可用该手机登陆所有秒出PPT产品，包括小程序等。',
  PERSONAL_03: '手机号绑定',
  PERSONAL_04: '请输入手机号',
  PERSONAL_05: '短信验证码',
  PERSONAL_06: '发送验证码',
  PERSONAL_07: '立即绑定',
  PERSONAL_08: '关键词',
  PERSONAL_09: '生成类型',
  PERSONAL_10: '消耗量/页',
  PERSONAL_11: '时间',
  PERSONAL_12: 'PPT名称',
  PERSONAL_13: '操作',
  PERSONAL_14: '下载'
};
