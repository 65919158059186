export default {
  ECHART_1: '柱状图',
  ECHART_2: '折线图',
  ECHART_3: '饼图',
  ECHART_4: '编辑图表',
  ECHART_5: '插入图表',
  ECHART_6: '属性设置',
  ECHART_7: '标题',
  ECHART_8: '显示标题',
  ECHART_9: '图例',
  ECHART_10: '图例位置',
  ECHART_11: '导入数据',
  ECHART_12: '导入数据',
  ECHART_13: '数值显示',
  ECHART_14: '插入左列',
  ECHART_15: '插入右列',
  ECHART_16: '删除列',
  ECHART_17: '编辑表格',
  ECHART_18: '编辑表格数据',
  ECHART_19: '标题内容',
  ECHART_20: '无',
  ECHART_21: '上',
  ECHART_22: '右',
  ECHART_23: '下',
  ECHART_24: '左',
  ECHART_25: '右上',
  ECHART_26: '手动调整',
  ECHART_27: '推荐',
  ECHART_28: 'AI对话',
  ECHART_29: '确定',
  ECHART_30: '取消',
  ECHART_31: '外部',
  ECHART_32: '内部',
  ECHART_33: '嗨，我是AI秒出助手。你想对「图表」 做点什么？',
  ECHART_34: '你想对图表的显示效果做些什么改动',
  ECHART_35: '增加变化率曲线',
  ECHART_36: '左轴最小值改成1000，把具体数字显示出来',
  ECHART_37: '显示图例，并把图例放到图表下方',
  ECHART_38: '你可以输入类似以下的请求：',
  ECHART_39: '显示图例，并把图例放到图表下方',
  ECHART_40: '你可以输入类似以下的请求：',
  ECHART_41: '修改可能较慢，建议一次给出全部修改需求...',
  ECHART_42: '修改样式中...',
  ECHART_43: '修改前',
  ECHART_44: '修改后：',
  ECHART_47: '向右移动1列',
  ECHART_48: '向左移动1列',
  ECHART_49: '删除列',
  ECHART_50: '数据',
  ECHART_51: '图表',
  ECHART_52: '标题内容:',
  ECHART_53: '销售额（万元）',
  ECHART_54: '年份',
  ECHART_55: '删除行',
  ECHART_56: '下移该行',
  ECHART_57: '上移该行',
  ECHART_58: '下方插入行',
  ECHART_59: '上方插入行',
  ECHART_60: '删除列',
  ECHART_61: '默认数据',
  ECHART_62: '项目名称{res}',
};
