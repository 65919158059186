import { createSlice } from '@reduxjs/toolkit';
import initState from '../initState';

export default createSlice({
  name: 'showRecommend',
  initialState: initState.showRecommend,
  reducers: {
    set(state, { payload }) {
      return payload;
    },
  },
});
