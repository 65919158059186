import { buildMetadata } from '../utils';

export const positionKeys = () => ['body-top', 'body-bottom'];

export const getMetadata = (props) => {
  const { options, page, position } = props;
  if (
    !_.get(options, 'describe.value') ||
    !_.get(page, `describe.${position}`)
  ) {
    return null;
  }
  const metadata = buildMetadata(
    options.describe[position],
    page.describe[position],
  )(options);
  metadata.valueKeys = metadata.valueKeys.filter(
    (d) => !!_.get(metadata, `value.${d}.norm`),
  );
  return metadata;
};

const defaultPosition = positionKeys()[0];
const valueTextKey = (position = defaultPosition) =>
  `describe.${position}.value.text.value`;

export const getDocdata = (metadata, position) => {
  const item = _.get(metadata, 'value.text');
  return item
    ? {
        key: valueTextKey(position),
        position,
        attr: item.attrs,
        value: item.value,
      }
    : {};
};
/**
 * 更新来自「内容页」的 value 到 page
 * @method d2mUpdate
 * @param  {[type]}  describe [参考 getDocdata 的返回值]
 * @param  {[type]}  page     [description]
 * @return {[type]}           [空数组表名无更新（因此不需要触发api）]
 */
export const d2mUpdate = (dataList, page) => {
  const [describe] = dataList || [];
  if (!describe) {
    return [];
  }
  const key = describe.key || valueTextKey(describe.position);
  if (_.get(page, key) === describe.value) {
    return [];
  }
  _.set(page, key, describe.value);
  return ['describe'];
};
/**
 * 删除 describe
 * @method d2mDelete
 * @param  {[type]}  describe [参考 getDocdata 的返回值]
 * @param  {[type]}  page     [description]
 * @return {[type]}           [空数组表名无更新（因此不需要触发api）]
 */
export const d2mDelete = (dataList, page) => {
  const [describe] = dataList || [];
  if (!describe) {
    return [];
  }
  _.set(page, `describe.${describe.position || defaultPosition}`, null);
  return ['describe'];
};
