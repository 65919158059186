export default {
  EDIT_01: '1分钟视频教学',
  EDIT_02: '模式切换',
  EDIT_03: '智能辅助模式：像写Word一样做PPT',
  EDIT_04: '传统编辑模式：沿用传统的PPT/WPS软件布局',
  EDIT_05: '下一步',
  EDIT_06: '内容区',
  EDIT_07: '输入内容，即可自动排版',
  EDIT_08: 'AI小助手，帮你智能修改内容',
  EDIT_09: '上一步',
  EDIT_10: '设计区',
  EDIT_11: '预览设计效果，一键切换各种精美版式',
  EDIT_12: '自由添加形状、文字、图表、动画',
  EDIT_13: '我知道了',
  EDIT_14: '一分钟视频教学',
}
