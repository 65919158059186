import { buildMetadata, combineOption, try2LastIndex } from '../utils';

export const getMetadata = (props) => {
  const { options, page } = props;
  if (!_.get(options, 'charts.valueModel') || !_.get(page, 'charts.value')) {
    return null;
  }
  const metadata = buildMetadata(options.charts, page.charts)(
    options,
    false,
    false,
  );
  const count = metadata.valueKeys.length;
  // metadata.style.gridTemplateAreas = metadata.defaultTemplateAreas && metadata.defaultTemplateAreas[count];

  metadata.valueKeys.forEach((key, index) => {
    metadata.value[key] = buildMetadata(
      options.charts.valueModel,
      try2LastIndex(options.charts.valueLength, [count, index]),
      _.get(options.charts.valueIndex, index),
      _.get(page.charts.value, key),
      // 标记grid area
      { style: { gridArea: String.fromCharCode(97 + index) } },
      { echartsOption: metadata.echarts?.[index] },
    )(options);
    metadata.value[key].valueKeys = metadata.value[key].valueKeys.filter(
      (d) => !!_.get(metadata, `value.${key}.value.${d}.norm`),
    );
    //测试type改成Echarts
    // metadata.value['1'].value.image.type=["Echarts"]
  });

  metadata.grid = combineOption(metadata.grid, options, '', null);

  return metadata;
};

const valueKey = (platekey, itemkey, metakey) => {
  let str = 'charts.value';
  if (!platekey) {
    return str;
  }
  str = `${str}.${platekey}`;
  if (!itemkey) {
    return str;
  }
  str = `${str}.value.${itemkey}`;
  if (metakey) {
    return str;
  }
  return `${str}.value`;
};

export const addplateItem = (page, dft) => {
  const plateMap = _.get(page, valueKey());
  const newId =
    ((plateMap ? _.max(_.keys(plateMap).map((d) => +d || 1)) : 1) || 1) + 1;
  const sort = (_.max(_.keys(plateMap).map((d) => +plateMap[d].sort)) || 1) + 1;

  _.set(page, valueKey(newId), _.merge({ sort }, dft));

  return String(newId);
};

export const getDocdata = (metadata, page) => {
  if (!metadata) {
    return [];
  }
  const docdata = [];
  const value = _.get(page, 'charts.value');
  const valueKeys = _.keys(value);
  // valueKeys.forEach((key) => {
  //   const itemdata = value[key];
  metadata.valueKeys.forEach((key) => {
    const itemdata = metadata.value[key];
    const data = [];
    [
      ['header', { type: ['Text', 'Header', '3'] }],
      ['description', { type: ['Text'] }],
      ['charts', { type: ['Charts'] }],
    ].forEach(([name, dft]) => {
      let item = _.get(itemdata, `value.${name}`);
      if (!item) {
        item = dft;
      }
      data.push({
        value: item.value,
        key: valueKey(key, name),
        type: item.type,
        attrs: item.attrs,
        show: _.get(item, 'style.display') !== 'none',
        metakey: valueKey(key, name, 1),
        // 删除时使用
        platekey: key,
        // 暂未用，先放着
        itemkey: name,
      });
    });
    docdata.push(data);
  });

  return docdata;
};
/**
 * 更新来自「内容页」的 value 到 page
 * @method d2mUpdate
 * @param  {[type]}  charts [参考 getDocdata 的返回值]
 * @param  {[type]}  page [description]
 * @return {[type]}       [空数组表名无更新（因此不需要触发api）]
 */
export const d2mUpdate = (charts, page) => {
  if (!charts) {
    return [];
  }
  const key = charts.key;
  if (_.get(page, key) === charts.value) {
    return [];
  }
  _.set(page, charts.value);
  return ['charts'];
};
/**
 * 删除 plate
 * @method d2mDelete
 * @param  {[type]}  charts [参考 getDocdata 的返回值的 第二维度数据]
 * @param  {[type]}  page [description]
 * @return {[type]}       [空数组表名无更新（因此不需要触发api）]
 */
// export const d2mDelete = (charts, page) => {
//   if (!charts || !charts.length) {
//     return [];
//   }
//   const saveKeys = charts
//     .map((data) => {
//       const item = _.get(page, valueKey());
//       if (!item) {
//         // 不存在 plate
//         return [];
//       }
//       return (_.isArray(data) ? data : [data])
//         .map((d) => {
//           if (!item[d.chartskey]) {
//             // 无标记数据或不存在的被标记的数据，无法删除，略过
//             return [];
//           }
//           delete item[d.chartskey];
//           return ['plate'];
//         })
//         .reduce((a, b) => a.concat(b), []);
//     })
//     .reduce((a, b) => a.concat(b), []);
//
//   return _.uniq(saveKeys);
// };
