import { processNodeDataToHTML } from './processNode'

const coverStructList = [
    {
        key: 'title',
        type: 'heading-one',
    },
    {
        key: 'des',
        type: 'paragraph',
    },
    {
        key: 'subtitle',
        type: 'paragraph',
    },
    {
        key: 'author',
        type: 'paragraph',
    },
    {
        key: 'user',
        type: 'paragraph',
    },
    {
        key: 'date',
        type: 'paragraph',
    },
]

const catalogStructList = [
    {
        key: 'list',
        type: 'numbered-list',
    },
    {
        key: 'user',
        type: 'paragraph',
    },
]

const transitionStructList = [
    {
        key: 'title',
        type: 'heading-two',
    },
    {
        key: 'user',
        type: 'paragraph',
    },
    {
        key: 'des',
        type: 'paragraph',
    },
    {
        key: 'subtitle',
        type: 'paragraph',
    },
]

const mainStructList = [
    {
        key: 'title',
        type: 'heading-three',
    },
    {
        key: 'des',
        type: 'paragraph',
    },
    {
        key: 'main',
        type: 'paragraph',
    },
    {
        key: 'subtitle',
        type: 'paragraph',
    },
    {
        key: 'subTitle',
        type: 'paragraph',
    },
    {
        key: 'list',
        type: 'paragraph',
    },
    {
        key: 'author',
        type: 'paragraph',
    },
    {
        key: 'user',
        type: 'paragraph',
    },
    {
        key: 'date',
        type: 'paragraph',
    },
    {
        key: 'table',
        type: 'table',
    },
]

const endStructList = [
    {
        key: 'title',
        type: 'heading-two',
    },
]

export const transformNodeValue = ({
    pageType = 'cover', data, chapterNum, mainNum,
}) => {
    const mapTypeToStruct = {
        cover: coverStructList,
        catalog: catalogStructList,
        transition: transitionStructList,
        main: mainStructList,
        end: endStructList,
    }
    // title
    // partNum
    // user
    // subTitle
    const struct = mapTypeToStruct[pageType]
    if (struct) {
        const result = struct.map((item) => {
            const { key, type } = item
            const dataItem = data[key]
            if (!dataItem) return ''
            if (type === 'table') {
                // guaLog('table', dataItem)
            }
            let prefix = ''
            if (pageType === 'transition' && key === 'title' && chapterNum) {
                prefix = `${chapterNum}. `
            }
            if (pageType === 'main' && key === 'title' && chapterNum && mainNum) {
                prefix = `${chapterNum}.${mainNum} `
            }
            // 如果是数组，则展示为列表
            const html = processNodeDataToHTML({
                pageType,
                nodeType: type,
                nodeDataItem: dataItem,
                pointType: data.pointType,
                prefix,
            })
            return html
        })
        return result.join('')
    }
    return ''
}
