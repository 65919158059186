import {
    CHARTS_RENDER_ERROR, DOWN_ERROR, DOWN_PDF_ERROR, GENERATE_PPT_API_ERROR,
    GENERATE_SYLLABUS_ERROR,
    IMAGE_LOAD_AD_BLOCK_ERROR,
    IMAGE_LOAD_ERROR, MAIN_SUBTITLE_DELETE_ERROR, PDF_DOWN_ERROR, RECOMMEND_STRUCTURE_VALUE_ERROR,
    RENDER_ERROR, TEMPLATE_CHANGE_ERROR, TRANSITION_DELETE_ERROR,
    TYPING_ERROR,
    LOAD_PAGE_ERROR,
} from '@/common/log/type/errorType'
import {
    GENERATE_PPT_SYLLABUS,
    GENERATE_PPT_SYLLABUS_ERROR,
    GENERATE_PPT_SYLLABUS_TEMPLATES_MORE,
    GENERATE_PPT_SYLLABUS_TIME_MORE,
    GENERATE_SYLLABUS_TIME_MORE,
    GENERATE_NONE_CONTENT_PPT,
    GENERATE_SYLLABUS_TIME_MORE_1,
    ONE_GENERATE_CHANGE_TEMPLATE,
    ONE_GENERATE_CONTENT_PPT,
    ONLINE_QUERY,
    RUNTIME_ERROR,
    UNHANDLED_PROMISE_REJECT,
    GENERATE_PPT_SYLLABUS_TIME_MORE_120, GENERATE_PPT_PAGELIST_ERROR, GENERATE_PPT_SYLLABUS_TIMEOUT_ERROR, EXPORT_PPT_ERROR, EXPORT_PPT_ANIMATION_ERROR,
    GENERATE_PPT_HTML_CONTENT_RE,
} from '@/common/log/type/logType'

export default (type) => {
    switch (type) {
        case PDF_DOWN_ERROR: {
            return '下载PDF错误'
        }
        case GENERATE_SYLLABUS_ERROR: {
            return '大纲生成失败'
        }
        case MAIN_SUBTITLE_DELETE_ERROR: {
            return '内容页副标题删除错误'
        }
        case DOWN_ERROR: {
            return '下载错误'
        }
        case DOWN_PDF_ERROR: {
            return '下载PDF错误'
        }
        case TRANSITION_DELETE_ERROR: {
            return '过渡页删除错误'
        }
        case CHARTS_RENDER_ERROR: {
            return '图表渲染错误'
        }
        case RENDER_ERROR: {
            return '渲染错误'
        }
        case TYPING_ERROR: {
            return '打印超时'
        }
        case IMAGE_LOAD_ERROR: {
            return '图片加载错误'
        }
        case GENERATE_PPT_SYLLABUS_TEMPLATES_MORE: {
            return '生成版式连续重复版式>=3'
        }
        case GENERATE_PPT_SYLLABUS_TIME_MORE: {
            return '大纲生成PPT>180秒'
        }
        case GENERATE_PPT_SYLLABUS_TIME_MORE_120: {
            return '大纲生成>120秒'
        }
        case GENERATE_PPT_SYLLABUS_ERROR: {
            return '大纲生成PPT失败'
        }
        case GENERATE_PPT_SYLLABUS_TIMEOUT_ERROR: {
            return '大纲生成PPT超时'
        }
        case GENERATE_SYLLABUS_TIME_MORE: {
            return '生成大纲>25秒'
        }
        case GENERATE_PPT_SYLLABUS: {
            return 'PPT大纲生成阶段数据'
        }
        case GENERATE_SYLLABUS_TIME_MORE_1: {
            return '一键生成报错'
        }
        case GENERATE_NONE_CONTENT_PPT: {
            return '生成非内容页PPT(假数据)失败'
        }
        case ONE_GENERATE_CONTENT_PPT: {
            return '一键生成PPT内容失败'
        }

        case ONE_GENERATE_CHANGE_TEMPLATE: {
            return '一键生成PPT切换模板失败'
        }
        case ONLINE_QUERY: {
            return '联网查询失败'
        }
        case RUNTIME_ERROR: {
            return 'JS运行时异常'
        }
        case UNHANDLED_PROMISE_REJECT: {
            return '未捕获promise异常'
        }
        case RECOMMEND_STRUCTURE_VALUE_ERROR: {
            return '推荐版式修改了内容'
        }
        case TEMPLATE_CHANGE_ERROR: {
            return '更换版式错误'
        }
        case GENERATE_PPT_PAGELIST_ERROR: {
            return 'PPT生成失败（pageList）'
        }
        case GENERATE_PPT_API_ERROR: {
            return '生成页接口错误'
        }
        case EXPORT_PPT_ERROR: {
            return '导出失败'
        }
        case EXPORT_PPT_ANIMATION_ERROR: {
            return '动画导出失败'
        }
        case IMAGE_LOAD_AD_BLOCK_ERROR: {
            return '图片被广告插件拦截'
        }
        case LOAD_PAGE_ERROR: {
            return '页面数据错误'
        }

        case GENERATE_PPT_HTML_CONTENT_RE: {
            return '对话流生成内容失败重试'
        }

        default: {
            return type
        }
    }
}
