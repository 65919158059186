export default {
  'TEMPLATES__DETAIL_1': 'TEMPLATES__DETAIL_1',
  'TEMPLATES__DETAIL_2': 'TEMPLATES__DETAIL_2',
  'TEMPLATES__DETAIL_3': 'TEMPLATES__DETAIL_3',
  'TEMPLATES__DETAIL_4': 'TEMPLATES__DETAIL_4',
  'TEMPLATES__DETAIL_5': 'TEMPLATES__DETAIL_5',
  'TEMPLATES__DETAIL_6': 'TEMPLATES__DETAIL_6',
  'TEMPLATES__DETAIL_7': 'TEMPLATES__DETAIL_7',
  'TEMPLATES__DETAIL_8': 'TEMPLATES__DETAIL_8',
  'TEMPLATES__DETAIL_9': 'TEMPLATES__DETAIL_9',
  'TEMPLATES__DETAIL_10': 'TEMPLATES__DETAIL_10',
  'TEMPLATES__DETAIL_11': 'TEMPLATES__DETAIL_11',
  'TEMPLATES__DETAIL_12': 'TEMPLATES__DETAIL_12',
  'TEMPLATES__DETAIL_13': 'TEMPLATES__DETAIL_13',
  'TEMPLATES__DETAIL_14': 'TEMPLATES__DETAIL_14',
  'TEMPLATES__DETAIL_15': 'TEMPLATES__DETAIL_15',
  'TEMPLATES__DETAIL_16': 'TEMPLATES__DETAIL_16',
  'TEMPLATES__DETAIL_17': 'TEMPLATES__DETAIL_17',
  'TEMPLATES__DETAIL_18': 'TEMPLATES__DETAIL_18',
  'TEMPLATES__DETAIL_19': 'TEMPLATES__DETAIL_19',
  'TEMPLATES__DETAIL_20': 'TEMPLATES__DETAIL_20',
  'TEMPLATES__DETAIL_21': 'TEMPLATES__DETAIL_21',
  'TEMPLATES__DETAIL_22': 'TEMPLATES__DETAIL_22',
  'TEMPLATES__DETAIL_23': 'TEMPLATES__DETAIL_23',
  'TEMPLATES__DETAIL_24': 'TEMPLATES__DETAIL_24',
  'TEMPLATES__DETAIL_25': 'TEMPLATES__DETAIL_25',
  'TEMPLATES__DETAIL_26': 'TEMPLATES__DETAIL_26',
  'TEMPLATES__DETAIL_27': 'TEMPLATES__DETAIL_27',
  'TEMPLATES__DETAIL_28': 'TEMPLATES__DETAIL_28',
  'TEMPLATES__DETAIL_29': 'TEMPLATES__DETAIL_29',
  'TEMPLATES__DETAIL_30': 'TEMPLATES__DETAIL_30',
  'TEMPLATES__DETAIL_31': 'TEMPLATES__DETAIL_31',
  'TEMPLATES__DETAIL_32': 'TEMPLATES__DETAIL_32',
  'TEMPLATES__DETAIL_33': 'TEMPLATES__DETAIL_33',
  'TEMPLATES__DETAIL_34': 'TEMPLATES__DETAIL_34',
  'TEMPLATES__DETAIL_35': 'TEMPLATES__DETAIL_35',
  'TEMPLATES__DETAIL_36': 'TEMPLATES__DETAIL_36',
  'TEMPLATES__DETAIL_37': 'TEMPLATES__DETAIL_37',
  'TEMPLATES__DETAIL_38': 'TEMPLATES__DETAIL_38',
  'TEMPLATES__DETAIL_39': 'TEMPLATES__DETAIL_39',
  'TEMPLATES__DETAIL_40': 'TEMPLATES__DETAIL_40',
  'TEMPLATES__DETAIL_41': 'TEMPLATES__DETAIL_41',
  'TEMPLATES__DETAIL_42': 'TEMPLATES__DETAIL_42',
  'TEMPLATES__DETAIL_43': 'TEMPLATES__DETAIL_43',
  'TEMPLATES__DETAIL_44': 'TEMPLATES__DETAIL_44',
  'TEMPLATES__DETAIL_45': 'TEMPLATES__DETAIL_45',
  'TEMPLATES__DETAIL_46': 'TEMPLATES__DETAIL_46',
  'TEMPLATES__DETAIL_47': 'TEMPLATES__DETAIL_47',
  'TEMPLATES__DETAIL_48': 'TEMPLATES__DETAIL_48',
};
