import { INodeType } from '@/pages/Edit/type/INode'
import isMainTypePage from '@/pages/Edit/common/page/isMainTypePage'

export default ({ page, node }) => {
    if (!page.nodeMark) {
        page.nodeMark = {}
    }
    if (isMainTypePage(page)) {
        if (node.type === INodeType.charts) {
            if (!page?.nodeMark?.charts) {
                page.nodeMark.charts = []
            }
            page.nodeMark.charts.push(node.id)
        }
        if (node.type === INodeType.image) {
            if (!page.nodeMark.plate) {
                page.nodeMark.plate = []
            }
            page.nodeMark.plate.push(node.id)
        }
        if (node.type === INodeType.table) {
            if (!page.nodeMark.table) {
                page.nodeMark.table = []
            }
            page.nodeMark.table.push(node.id)
        }
        if (node.type === INodeType.video) {
            if (!page.nodeMark.video) {
                page.nodeMark.video = []
            }
            page.nodeMark.video.push(node.id)
        }
        if (node.type === INodeType.mermaid) {
            if (!page.nodeMark.flowchart) {
                page.nodeMark.flowchart = []
            }
            page.nodeMark.flowchart.push(node.id)
        }
    }
    if (page) {
        page.nodes[node.id] = node
    }
}
