export default {
    PAY_1: 'PAY_1',
    PAY_2: 'PAY_2',
    PAY_3: 'PAY_3',
    PAY_4: 'PAY_4',
    PAY_5: 'PAY_5',
    PAY_6: 'PAY_6',
    PAY_7: 'PAY_7',
    PAY_8: 'PAY_8',
    PAY_9: 'PAY_9',
    PAY_10: 'PAY_10',
    PAY_11: 'PAY_11',
    PAY_12: 'PAY_12',
    PAY_13: 'PAY_13',
    PAY_14: 'PAY_14',
    PAY_15: 'PAY_15',
    PAY_16: 'PAY_16',
    PAY_17: 'PAY_17',
    PAY_18: 'PAY_18',
    PAY_19: 'PAY_19',
    PAY_20: 'PAY_20',
    PAY_21: 'PAY_21',
    PAY_22: 'PAY_22',
    PAY_23: 'PAY_23',
    PAY_24: 'PAY_24',
    PAY_25: 'PAY_25',
    PAY_26: 'PAY_26',
    PAY_27: 'PAY_27',
    PAY_28: 'PAY_28',
    PAY_29: 'PAY_29',
    PAY_30: 'PAY_30',
    PAY_31: 'PAY_31',
    PAY_32: 'PAY_32',
    PAY_33: 'PAY_33',
    PAY_34: 'PAY_34',
    PAY_35: 'PAY_35',
    PAY_36: 'PAY_36',
    PAY_37: 'PAY_37',
    PAY_38: 'PAY_38',
    PAY_39: 'PAY_39',
    PAY_40: 'PAY_40',
    PAY_41: 'PAY_41',
    PAY_42: 'PAY_42',
    PAY_43: 'PAY_43',
    PAY_44: 'PAY_44',
    PAY_45: 'PAY_45',
    PAY_46: 'PAY_46',
    PAY_47: 'PAY_47',
    PAY_48: 'PAY_48',
    PAY_49: 'PAY_49',
    PAY_50: 'PAY_50',
    PAY_51: 'PAY_51',
}
