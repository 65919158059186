export const updateHtmlFontSize = (htmlStr, fontSize) => {
    let div = document.createElement('div')
    div.innerHTML = htmlStr

    let fontSizeUpdated = false
    // 缩小font-size
    let updatedHTML = div.innerHTML
    let regex = /font-size:\s?([\d.]+)px/gi
    updatedHTML = updatedHTML.replace(regex, (match, p1) => {
        let newSize = fontSize
        fontSizeUpdated = true
        return `font-size: ${newSize}px`
    })
    div.innerHTML = updatedHTML

    if (!fontSizeUpdated) {
        div.children[0].style.fontSize = `${fontSize}px`
    }

    return div.innerHTML
}

updateHtmlFontSize('<div style="font-size: 12px">hello</div>', 14)
