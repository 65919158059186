export default {
  EDIT_01: '1-min Video Tutorial',
  EDIT_02: 'Mode Switch',
  EDIT_03: "Smart Assistant Mode: Create PPTs like you're writing in Word'",
  EDIT_04: 'Traditional Editing Mode: Stick to the traditional PPT/WPS software layout',
  EDIT_05: 'Next Step',
  EDIT_06: 'Content Area',
  EDIT_07: 'Enter content for automatic formatting',
  EDIT_08: 'AI Assistant to help you intelligently modify content',
  EDIT_09: 'Previous Step',
  EDIT_10: 'Design Area',
  EDIT_11: 'Preview design effects, switch between various exquisite layouts with one click',
  EDIT_12: 'Freely add shapes, text, charts, animations',
  EDIT_13: 'I Understand',
  EDIT_14: '1-min Video Tutorial'
};
