import chroma from 'chroma-js'
import produce from 'immer'

const createSvgElement = (svgString) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(svgString, 'image/svg+xml')

    return doc
}

export const getSvgOrPathAttr = (svgElement, type, attr) => {
    const temp = svgElement.querySelector(type)
    const res = temp ? temp.getAttribute(attr) : null
    return res
}

// 先去掉阴影功能
export const AddShadowAndGradientForPage = (page, theme) => {
    return page
}

export const AddShadowAndGradientForPages = (pages, theme) => {
    return produce((pages), (p) => {
        Object.keys(p).forEach((key) => {
            const page = p[key]
            AddShadowAndGradientForPage(page, theme)
        })
    })
}

export const AddShadowAndGradientForPageList = (pageList, theme) => {
    const res = []
    pageList.forEach((item, index) => {
        if (item.type === 'html') {
            res.push(item)
        } else {
            res.push(
                AddShadowAndGradientForPage(item, theme),
            )
        }
    })
    return res
}
