import { buildMetadata, combineOption, try2LastIndex } from '../utils';

export const getMetadata = (props) => {
  const { options, page } = props;
  if (!_.get(options, 'List.valueModel') || !_.get(page, 'List.value')) {
    return null;
  }
  const metadata = buildMetadata(options.List, page.List)(
    options,
    false,
    false,
  );
  const count = metadata.valueKeys.length;
  metadata.valueKeys.forEach((key, index) => {
    metadata.value[key] = buildMetadata(
      options.List.valueModel,
      try2LastIndex(options.List.valueLength, [count, index]),
      _.get(options.List.valueIndex, index),
      _.get(page.List.value, key),
    )(options);
    metadata.value[key].valueKeys = metadata.value[key].valueKeys.filter(
      (d) => !!_.get(metadata, `value.${key}.value.${d}.norm`),
    );
  });

  metadata.grid = combineOption(metadata.grid, options, '', null);

  return metadata;
};

const valueKey = (listkey, itemkey, metakey) => {
  let str = 'List.value';
  if (!listkey) {
    return str;
  }
  str = `${str}.${listkey}`;
  if (!itemkey) {
    return str;
  }
  str = `${str}.value.${itemkey}`;
  if (metakey) {
    return str;
  }
  return `${str}.value`;
};

export const addListItem = (page, dft) => {
  const listMap = _.get(page, valueKey());
  const newId =
    ((listMap ? _.max(_.keys(listMap).map((d) => +d || 1)) : 1) || 1) + 1;
  const sort = (_.max(_.keys(listMap).map((d) => +listMap[d].sort)) || 1) + 1;

  _.set(page, valueKey(newId), _.merge({ sort }, dft));

  return String(newId);
};

export const getDocdata = (metadata) => {
  if (!metadata) {
    return [];
  }

  const docdata = [];
  metadata.valueKeys.forEach((key) => {
    const itemdata = metadata.value[key];
    const data = [];
    [
      ['header', { type: ['Text', 'Header', '3'] }],
      ['description', { type: ['Text'] }],
      ['image', { type: ['Image'] }],
    ].forEach(([name, dft]) => {
      let item = _.get(itemdata, `value.${name}`);
      if (!item) {
        item = dft;
      }
      data.push({
        value: item.value,
        key: valueKey(key, name),
        type: item.type,
        attrs: item.attrs,
        show: _.get(item, 'style.display') !== 'none',
        metakey: valueKey(key, name, 1),
        // 删除时使用
        listkey: key,
        // 暂未用，先放着
        itemkey: name,
      });
    });
    docdata.push(data);
  });

  return docdata;
};
/**
 * 更新来自「内容页」的 value 到 page
 * @method d2mUpdate
 * @param  {[type]}  list [参考 getDocdata 的返回值]
 * @param  {[type]}  page [description]
 * @return {[type]}       [空数组表名无更新（因此不需要触发api）]
 */
export const d2mUpdate = (list, page) => {
  if (!list || !list.length) {
    return [];
  }
  const saveKeys = list
    .map((data) => {
      if (!data || !data.length) {
        return [];
      }
      const updates = data.filter((d) => !!d.key);
      if (updates.length) {
        // update 操作，上方的 filter 可能过滤掉了错误（不含 key）的数据
        const count = updates.filter((item) => {
          let update = false;
          if (_.get(page, item.key) !== item.value) {
            _.set(page, item.key, item.value);
            update = true;
          }
          const itemdata = _.get(page, item.metakey);
          if (itemdata) {
            if (item.show && _.get(itemdata, 'style.display') === 'none') {
              delete itemdata.style.display;
              update = true;
            } else if (
              !item.show &&
              _.get(itemdata, 'style.display') !== 'none'
            ) {
              _.set(itemdata, 'style.display', 'none');
              update = true;
            }
          }
          return update;
        }).length;
        if (!count) {
          return [];
        }
      } else {
        // 新建 list 项目
        const value = {
          // 约定：使用数组第1个元素初始化 header 标签
          header: { value: _.get(data, '0.value') },
          // 约定：使用数组第2个元素初始化 description 标签
          description: { value: _.get(data, '1.value') },
          // // 约定：使用数组第3个元素初始化 image 标签
          image: { value: _.get(data, '2.value') },
        };
        const KEYS = ['header', 'description', 'image'];
        data.slice(0, 3).forEach((item, i) => {
          if (!item.show) {
            value[KEYS[i]].style = { display: 'none' };
          }
        });
        addListItem(page, { value });
      }
      return ['List'];
    })
    .reduce((a, b) => a.concat(b), []);

  return _.uniq(saveKeys);
};
/**
 * 删除 list
 * @method d2mDelete
 * @param  {[type]}  list [参考 getDocdata 的返回值的 第二维度数据]
 * @param  {[type]}  page [description]
 * @return {[type]}       [空数组表名无更新（因此不需要触发api）]
 */
export const d2mDelete = (list, page) => {
  if (!list || !list.length) {
    return [];
  }
  const saveKeys = list
    .map((data) => {
      const item = _.get(page, valueKey());
      if (!item) {
        // 不存在 list
        return [];
      }
      return (_.isArray(data) ? data : [data])
        .map((d) => {
          if (!item[d.listkey]) {
            // 无标记数据或不存在的被标记的数据，无法删除，略过
            return [];
          }
          delete item[d.listkey];
          return ['List'];
        })
        .reduce((a, b) => a.concat(b), []);
    })
    .reduce((a, b) => a.concat(b), []);

  return _.uniq(saveKeys);
};

// List 模板通用结构
export const templateBase = () => ({
  name: '列表类模板base数据',
  page: {
    List: {
      style: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridTemplateRows: 'min-content auto auto min-content',
        gridTemplateAreas: '"H H" "D P" "L P" "F F"',
      },
    },
  },
  List: {
    grid: {
      style: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      },
    },
    text: {
      header: {
        style: {
          flexGrow: '0',
          flexShrink: '0',
          flexBasis: 'auto',
          width: '100%',
          textAlign: 'center',
          // maxHeight: 28,
        },
        extend: ['padding.small', 'text.h3'],
        small: {
          extend: ['text.h3.small'],
        },
      },
      description: {
        style: {
          flexGrow: '0',
          flexShrink: '0',
          flexBasis: 'auto',
          width: '100%',
          textAlign: 'center',
        },
        extend: ['padding.small', 'text.normal'],
        small: {
          extend: ['text.normal.small'],
        },
        attrs: {
          // placeholder: '添加描述文本',
        },
        settings: {
          _Title: '描述文本样式',
        },
      },
    },
    valueExample: {
      sort: 100,
      extend: ['List.valueModel'],
    },
    valueLength: [
      [{ style: { flexBasis: '50%' } }],
      [{ style: { flexBasis: '50%' } }],
      [{ style: { flexBasis: '50%' } }],
      [
        { style: { flexBasis: '33.3%' } },
        // 自动“继承自 valueLength[3][0] ”
        // {},
        // {},
      ],
      [{ style: { flexBasis: '25%' } }],
      [{ style: { flexBasis: '33.3%' } }],
      [{ style: { flexBasis: '33.3%' } }],
      [{ style: { flexBasis: '25%' } }],
      [{ style: { flexBasis: '25%' } }],
    ],
    valueModel: {
      style: {
        position: 'relative',
      },
      extend: ['padding.normal'],
      value: {
        header: {
          norm: 1,
          sort: 15,
          extend: ['List.text.header'],
          operations: {
            // 用于列表中，所有item统一添加该样式，并且应用范围与 uniteFormats 相同，即整体文本添加样式
            listFormats: ['fontSize'],
          },
        },
        description: {
          norm: 1,
          sort: 18,
          extend: ['List.text.description'],
          operations: {
            // 用于列表中，所有item统一添加该样式，并且应用范围与 uniteFormats 相同，即整体文本添加样式
            listFormats: ['fontSize'],
          },
        },
        image: {
          extend: ['image.1'],
        },
        // 特殊KEY，最后一个项目不会绘制该元素，通常用于绘制项目之间的箭头等
        'separate-middle': {},
      },
    },
  },
  example: {
    templateId: 0,
    header: {
      value: {
        header: {
          value: '用户痛点',
        },
      },
    },
    footer: {},
    plate: {
      value: {
        // 1: {
        //   sort: 1,
        //   value: {
        //     image: {
        //       value: '/image/tmp/list-image-1.jpg',
        //     },
        //   },
        // },
        // 2: {
        //   sort: 2,
        //   value: {
        //     image: {
        //       value: '/image/tmp/list-image-2.jpg',
        //     },
        //   },
        // },
        // 3: {
        //   sort: 3,
        //   value: {
        //     image: {
        //       value: '/image/tmp/list-image-3.jpg',
        //     },
        //   },
        // },
      },
    },
    describe: {
      'body-top': {
        value: {
          text: {
            value: [
              '一项新的Prezi调查显示，70%的在职美国人同意，演讲技巧对他们的工作成功至关重要。有20％的受访者表示，',
              '他们几乎会采取一切措施避免发表演讲，包括假装生病或请同事发表演讲，即使这意味着在工作场所“失去尊重”。',
            ].join(''),
          },
        },
      },
    },
    List: {
      value: {
        1: {
          sort: 1,
          value: {
            header: { value: '逻辑' },
            description: {
              value: '内容资料找了一堆，怎么去做PPT，逻辑还是难以厘清',
            },
          },
        },
        2: {
          sort: 2,
          value: {
            header: { value: '排版' },
            description: {
              value: '并不是设计师出身，怎么排版布局，实在设计经验不足',
            },
          },
        },
        3: {
          sort: 3,
          value: {
            header: { value: '模板' },
            description: {
              value: '下载的PPT模板内容参差不齐，经常需要混用不同的模板内容',
            },
          },
        },
      },
    },
    charts: {
      value: {
        1: {
          sort: 1,
          value: {
            charts: {
              value: {
                series: [
                  {
                    data: [150, 230, 224, 218, 135, 147, 260],
                    type: 'line',
                  },
                ],
              },
            },
          },
        },
        2: {
          sort: 1,
          value: {
            charts: {
              value: {
                series: [
                  {
                    data: [150, 230, 224, 218, 135, 147, 260],
                    type: 'bar',
                  },
                  {
                    data: [150, 230, 224, 218, 135, 147, 260],
                    type: 'line',
                  },
                ],
              },
            },
          },
        },
      },
    },
  },
});
