export default {
  FileUpload_01: 'Free Input',
  FileUpload_02: 'AI ChatGPT has reached its usage limit, please upgrade',
  FileUpload_03: 'Upgrade',
  FileUpload_04: 'Add or paste content',
  FileUpload_05: 'Add or paste your content here, it can be a simple topic, bullet points, an outline, or an entire document',
  FileUpload_06: 'Supports adding or pasting content up to 8000 words (no format requirements)',
  FileUpload_07: 'Note: It will intelligently abbreviate, break down key points, and consume AI usage',
  FileUpload_08: 'Generation Failed',
  FileUpload_09: 'Please read the following instructions carefully and upload the file as required',
  FileUpload_10: 'Non-formatted Upload',
  FileUpload_11: 'Supports uploading Word documents up to 8000 words (no format requirements). However, the content will be intelligently abbreviated and key points broken down after uploading',
  FileUpload_12: 'Formatted Upload',
  FileUpload_13: 'Please first',
  FileUpload_14: 'Download Word Import Instructions.docx',
  FileUpload_15: 'Fill in as required and then upload. Content from formatted uploads will not be intelligently abbreviated',
  FileUpload_16: 'Download Markdown Import Instructions.md',
  FileUpload_17: 'Fill in as required and then upload',
  FileUpload_18: 'Download Freemind Import Instructions.mm',
  FileUpload_19: 'Drag and drop files to this area or',
  FileUpload_20: 'Uploading',
  FileUpload_21: 'My Uploads',
  FileUpload_22: 'Fill in as required and then upload',
  FileUpload_23: 'Select file to upload',
  FileUpload_24: 'No content',
  FileUpload_25: 'Please upload a Word file with the extension .docx or .doc',
  FileUpload_26: 'Please upload a PPT file with the extension .pptx or .ppt',
  FileUpload_27: 'Please upload a file with the extension .md',
  FileUpload_28: 'Please upload a file with the extension .mm',
  FileUpload_29: 'Format Select',
  FileUpload_30: 'Support paste of custom content, including text and images',
  FileUpload_31: 'Upload Word',
  FileUpload_32: 'Support doc, docx, and similar file formats',
  FileUpload_33: 'Support Markdown format files',
  FileUpload_34: 'Mind Map - Freemind',
  FileUpload_35: 'Support Freemind format files',
  'FileUpload_36': 'Drag Files Here or',
  FileUpload_37: 'Upload',
  'FileUpload_38': 'Supports mm format files'

};
